import React, {useState} from "react"
import {useLocation, useHistory} from "react-router-dom"
import InterviewQuestion from "./InterviewQuestion"
import {ToastContainer} from "react-toastify"

const InterviewQuestions = () => {
    const location = useLocation()
    const history = useHistory()
    const data = location.state
    let trackProgress = sessionStorage.getItem("trackProgress")
    const [questionCounter, setQuestionCounter] = useState(() => (Number(trackProgress) ? Number(trackProgress) : 0))

    const [vr, setVR] = useState(data.video_resume)

    const func = (d) => {
        data.video_resume = d
    }

    return (
        <div className="interview-start m-auto col-md-8 col-lg-7 center-content">
            <ToastContainer/>
            {questionCounter > data?.Position?.Question.length - 1 || data?.Position === null ? (
                <>
                    {history.push("/interview/complete", data)}
                    {sessionStorage.removeItem("trackProgress")}
                </>
            ) : data?.Position?.Question[questionCounter]?.Answer?.length === 0 ? (
                <>
                    {sessionStorage.setItem("trackProgress", questionCounter)}
                    <InterviewQuestion
                        setVR={func}
                        attempts={data.Position.Question[questionCounter].attempts_allowed}
                        progress={
                            data.Position.Question.length &&
                            data.Position.Question[questionCounter].seq &&
                            `${
                                (parseInt(data.Position.Question[questionCounter].seq - 1, 10) / data.Position.Question.length) * 100
                            }%`
                        }
                        isLoading={false}
                        isUploadFailed={false}
                        progressColor={data?.Company?.theme_code ? data?.Company?.theme_code : "rgb(0,186,82)"}
                        title={`${data.Position.title} at ${data.Company.name}`}
                        companyLogo={data.Company.logo}
                        subtitle={`Question ${data.Position.Question[questionCounter].seq} of ${data.Position.Question.length}`}
                        question={data.Position.Question[questionCounter].title}
                        questionToken={""}
                        questionDescription={data.Position.Question[questionCounter].description}
                        time={data.Position.Question[questionCounter].time_allowed}
                        type={data.Position.Question[questionCounter].type}
                        onSubmit={() => {
                        }}
                        onSetDeviceInfo={() => {
                        }}
                        videoURL={
                            data.Position.Question[questionCounter].video ? data.Position.Question[questionCounter].video : ""
                        }
                        imgURL={data.Position.Question[questionCounter].image ? data.Position.Question[questionCounter].image : ""}
                        onAddMessageToToaster={() => {
                        }}
                        setQuestionCounter={setQuestionCounter}
                        questionCounter={questionCounter}
                        question_id={data.Position.Question[questionCounter].id}
                        interview_id={data.id}
                        video_resume={vr}
                        interviewResume={data.interviewResume}
                    />
                </>
            ) : (
                setQuestionCounter((prevState) => prevState + 1)
            )}
        </div>
    )
}

export default InterviewQuestions
