import React, { useRef, useState, useEffect } from "react"
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import { ReactComponent as VideoGuide } from "../../../images/svg/video-guide.svg"
import { ReactComponent as StopwatchIcon } from "../../../images/svg/stopwatch.svg"
import { ReactComponent as VideoIcon } from "../../../assets/images/video-camera.svg"
import RecordRTC from "recordrtc"
import { fancyTimeFormat } from "../../../helpers/dateTime"

const InterviewPracticeQuestion = () => {
  const video = useRef(null)
  const [blob, setBlob] = useState(null)
  const duration = useRef(null)
  const stop = useRef(null)
  const start = useRef(null)
  const reset = useRef(null)
  let recorder, durationCounter
  let timeleft = 60

  useEffect(() => {
    stop.current.style.display = "none"
    reset.current.style.display = "none"
  }, [])
  let initialDuration = fancyTimeFormat(timeleft)
  const handleRecording = async () => {
    captureCamera((camera) => {
      video.current.muted = false
      video.current.volume = 0
      video.current.srcObject = camera
      recorder = RecordRTC(camera, {
        type: "video",
      })
      recorder.setRecordingDuration(60000).onRecordingStopped(stopRecordingCallback)
      recorder.startRecording()
      recorder.camera = camera

      durationCounter = setInterval(() => {
        if (timeleft <= 0) {
          clearInterval(durationCounter)
          if (duration.current) {
            duration.current.innerText = "Time Over"
            reset.current.style.display = "block"
            handleStop()
          }
        } else {
          if (duration.current) duration.current.innerText = fancyTimeFormat(timeleft)
        }
        timeleft -= 1
      }, 1000)
    })
    stop.current.style.display = "block"
    start.current.style.display = "none"
  }

  const captureCamera = (callback) => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then(function (camera) {
        callback(camera)
      })
      .catch(function (error) {
        console.log("Unable to capture your camera. Please check console logs.")
        console.error(error)
      })
  }

  const stopRecordingCallback = () => {
    video.current.srcObject = null
    video.current.muted = false
    video.current.volume = 1
    video.current.src = URL.createObjectURL(recorder.getBlob())
    video.current.setAttribute("controls", "true")
    console.log(recorder.getBlob().size, "blob size")
    setBlob(recorder.getBlob())
    recorder.camera.stop()
    recorder.destroy()
    recorder = null
  }

  const handleStop = () => {
    recorder?.stopRecording(() => stopRecordingCallback())
    video.current.setAttribute("controls", "true")
    stop.current.style.display = "none"
    reset.current.style.display = "block"
  }
  return (
    <div className="interview-start m-auto col-md-8 col-lg-7 center-content">
      <VideoResumeCard
        helpCenter
        progressColor={"red"}
        //progress={progress}
        title={"bold dusk"}
        imgClass="rounded-circle"
        //imageUrl={}
        subtitle={"Practice Question"}
      >
        <div className="ml-4 mr-4 w-100">
          <p className="mt-3 body-title font-weight-600">Practice Question: What are your top three skills?</p>
        </div>
        <div className="d-flex justify-content-center">
          <video
            width={650}
            height={490}
            ref={video}
            controls={false}
            disablepictureinpicture
            autoPlay={true}
            controlslist="nodownload"
            playsInline={true}
            style={{ position: "relative", backgroundColor: "black" }}
          ></video>
          <VideoGuide
            style={{
              position: "absolute",
              width: "500px",
              minWidth: "500px",
              minHeight: "380px",
              height: "380px",
              marginTop: "50px",
            }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4" style={{ width: "650px" }}>
          <div className="d-flex align-items-center">
            <StopwatchIcon className="text-primary" width="25" height="25" />
            <p className="mx-2 mb-0 font-weight-normal">
              <small>You have {60} seconds</small>
            </p>
          </div>
          <div className="d-flex align-items-center">
            <p className="mb-0 mr-2">
              <small className="font-weight-500">Duration</small>
            </p>
            <p className="mb-0 font-weight-normal">
              <small ref={duration}>{initialDuration}</small>
            </p>
          </div>
        </div>
        <div className="d-flex mt-3 justify-content-center pb-10">
          <button
            type="button"
            class="btn btn-primary font-weight-400 px-3 btn-sm mx-2"
            ref={start}
            onClick={handleRecording}
          >
            <VideoIcon /> Start Recording
          </button>
          <button
            type="button"
            class="btn btn-primary font-weight-400 px-3 btn-sm mx-2"
            ref={stop}
            onClick={handleStop}
          >
            <VideoIcon /> Stop Recording
          </button>
          <button
            className="btn btn-outline-dark btn-sm btn-width mb-3 mb-md-0 ml-3"
            style={{ width: "160px" }}
            ref={reset}
            onClick={() => window.history.back()}
          >
            Return
          </button>
        </div>
        <div className=" d-flex justify-content-center mt-4">
          <p className="mb-0">Your response will not be uploaded or shown to the employer</p>
        </div>
      </VideoResumeCard>
    </div>
  )
}

export default InterviewPracticeQuestion
