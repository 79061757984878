// 3rd party
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

// lib
import "./style.scss"
import Button from "../Button/Button"

const Modal = ({
  open,
  title,
  isLarge,
  closeText,
  children,
  cancelText,
  submitText,
  bodyClass,
  headerClass,
  hideCloseBtn,
  hideBottomBorder,
  onCancel,
  onSubmit,
}) => {
  return (
    <>
      <div className={classnames("modal fade", { "show d-block": open })}>
        <div
          className={classnames("modal-dialog modal-dialog-centered modal-dialog-scrollable", { "modal-lg": isLarge })}
          role="document"
        >
          <div className="modal-content">
            <div className={classnames("modal-header", { "hide-modal-border": hideBottomBorder }, headerClass)}>
              {title && (
                <h5 className="modal-title" id="exampleModalLabel">
                  {title}
                </h5>
              )}
              {!hideCloseBtn && (
                <Button
                  name="close_btn"
                  variation="link paragraph font-weight-normal text-dark close"
                  value={closeText || <span aria-hidden="true">&times;</span>}
                  onSubmit={onCancel}
                />
              )}
            </div>
            <div className={classnames("modal-body", bodyClass)}>{children}</div>
            {(cancelText || submitText) && (
              <div className="modal-footer">
                {cancelText && (
                  <Button name="cancel_btn" variation="secondary btn-sm" value={cancelText} onSubmit={onCancel} />
                )}
                {submitText && (
                  <Button name="submit_btn" variation="primary btn-sm" value={submitText} onSubmit={onSubmit} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {open && <div className="modal-backdrop fade show" />}
    </>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  closeText: PropTypes.string,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  bodyClass: PropTypes.string,
  headerClass: PropTypes.string,
  isLarge: PropTypes.bool,
  hideCloseBtn: PropTypes.bool,
  hideBottomBorder: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

Modal.defaultProps = {
  title: "",
  isLarge: false,
  closeText: "",
  cancelText: "",
  submitText: "",
  bodyClass: "",
  headerClass: "",
  hideCloseBtn: false,
  hideBottomBorder: false,
  onSubmit: () => undefined,
  onCancel: () => undefined,
}

export default Modal
