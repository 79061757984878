// 3rd party
import React, {useState, useEffect} from "react"
import {useHistory, Link, useLocation} from "react-router-dom"
import {
    isIOS,
    isSafari,
    deviceType,
    browserName,
    browserVersion,
    mobileModel as deviceModel,
    osName as platform,
    osVersion as platformVersion,
} from "react-device-detect"

import Button from "../../common/Button/Button"
//import { windowNavigator } from "../../../helpers";
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import {ReactComponent as WebcamIcon} from "../../../assets/images/webcam-black.svg"
import {ReactComponent as BrowserIcon} from "../../../assets/images/browser.svg"
import {ReactComponent as MicroPhoneIcon} from "../../../assets/images/microphone.svg"
import {ReactComponent as LaptopIcon} from "../../../assets/images/laptop.svg"
import {ReactComponent as ConnectivityIcon} from "../../../assets/images/connectivity.svg"
import {ReactComponent as CrossIcon} from "../../../assets/images/cross.svg"
import {ReactComponent as TickIcon} from "../../../assets/images/tick-icon.svg"
import Recorder from "../VideoRecord/Recorder"

const SetupDevices = ({onAddMessageToToaster}) => {
    const location = useLocation()
    const data = location.state
    const [testDevice, setTestDevice] = useState(false)
    const [recording, setRecording] = useState(false)
    const [camera, setCamera] = useState("")
    const [audio, setAudio] = useState("")
    const [allowAudio, setAllowAudio] = useState(false)
    const [allowCamera, setAllowCamera] = useState(false)

    const disableCamera = isIOS || isSafari
    const getBrowserName = () => `${browserName} ${browserVersion}`

    const handleDeviceUpdate = (device) => {
        if (device && device.audio) {
            // setCamera(device.camera[device.camera.length - 1])
            setAllowAudio(true)
            setAudio(device.audio)
        }
        if (device && device.camera) {
            setAllowCamera(true)
            setCamera(device.camera)
            // setAudio(device.audio[device.audio.length - 1])
        }

    }

    const handleTestDevice = () => {
        setTestDevice(true)
    }

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    return (
        <VideoResumeCard
            helpCenter
            title={data.Position.title}
            companyName={data.Company.name}
            progressColor={data?.Company?.theme_code ? data?.Company?.theme_code : "rgb(0,186,82)"}
            imgClass="rounded-circle"
            imageUrl={data.Company.logo}
            subtitle={"Introduction"}
        >
            <div className="row mt-4 grid-divider pr-md-4">
                <div className="col-sm-12 col-md-6 col-lg-6 my-3 pl-md-5 pl-3 pr-md-4">
                    <div className="mb-4">
                        <h4 className="font-weight-600" style={{fontSize: "1.38888rem"}}>
                            Webcam & Microphone Setup{" "}
                        </h4>

                        <p className="mb-2 body-title dark-color">Make sure you allow access to Camera & Microphone when
                            asked.</p>
                    </div>
                    <div className="mt-2">
                        <p className="mb-4 body-title dark-color font-weight-600" style={{fontSize: "1rem"}}>
                            Device Information
                        </p>
                        <div className="pt-2 d-flex justify-content-between align-items-center mb-4">
                            <div className="d-flex ml-1 mb-2 align-items-center">
                                <WebcamIcon height="28" width="28"/>{" "}
                                <div className="ml-3 d-block float-right font-weight-normal">
                                    <p className="mb-0 font-weight-600">Webcam</p>
                                    <p className="mb-0">{camera ? <>{camera}</> : <>Not Connected</>}</p>
                                </div>
                            </div>
                            <p className="ml-2 mb-2 float-right dark-grey-color font-weight-600">
                                {camera ? (
                                    <>
                                        <TickIcon className="ml-2"/>
                                    </>
                                ) : (
                                    <>
                                        <CrossIcon className="ml-2"/>
                                    </>
                                )}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between mb-4 align-items-center">
                            <div className="mb-2 ml-1 d-flex align-items-center">
                                <MicroPhoneIcon height="30" width="25"/>{" "}
                                <div className="ml-3 d-block float-right font-weight-normal">
                                    <p className="mb-0 font-weight-600">Microphone</p>
                                    <p className="mb-0">{audio ? <>{audio}</> : <>Not Connected</>}</p>
                                </div>
                            </div>
                            <p className="mb-2 ml-2 float-right dark-grey-color font-weight-600">
                                {audio ? (
                                    <>
                                        <TickIcon className="ml-2"/>
                                    </>
                                ) : (
                                    <>
                                        <CrossIcon className="ml-2"/>
                                    </>
                                )}
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="ml-1 mb-2 d-flex align-items-center">
                                <BrowserIcon height="25" width="25"/>
                                <div className="ml-3 d-block float-right font-weight-normal">
                                    <p className="mb-0 font-weight-600">Browser</p>
                                    <p className="mb-0">{getBrowserName()}</p>
                                </div>
                            </div>
                            <p className="ml-1 mb-2 float-right dark-grey-color font-weight-600 text-capitalize">
                                {disableCamera ? <CrossIcon className="ml-2"/> : <TickIcon className="ml-2"/>}
                            </p>
                        </div>
                        <br/>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div className="mb-2 ml-1 d-flex align-items-center">
                                <ConnectivityIcon height="25" width="25"/>
                                <div className="ml-3 d-block float-right font-weight-normal">
                                    <p className="mb-0 font-weight-600">Connectivity</p>
                                    <p className="mb-0">{window.navigator.onLine ? <>Connected</> : <>Not
                                        Connected</>}</p>
                                </div>
                            </div>
                            <p className="ml-2 float-right dark-grey-color font-weight-600">
                                {window.navigator.onLine ? (
                                    <>
                                        <TickIcon className="ml-2"/>
                                    </>
                                ) : (
                                    <>
                                        <CrossIcon className="ml-2"/>
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 my-1 pr-md-5 mt-4">
                    <div className="d-flex flex-column justify-content-center">
                        <div className="flex-column center-content rounded-lg col-12 ml-1 mr-2 video-record w-100">
                            {testDevice && (
                                <div
                                    className="mb-3 center-content video-record w-100 flex-column"
                                    style={{marginLeft: !disableCamera ? "-100px" : "0px"}}
                                >
                                    {!disableCamera ? (
                                        <Recorder
                                            isPractice
                                            setUpDevice
                                            time={0.2}
                                            type="video"
                                            recording={recording}
                                            onSetRecording={(rec) => {
                                                setRecording(rec)
                                            }}
                                            onGetCamera={handleDeviceUpdate}

                                            onAddMessageToToaster={onAddMessageToToaster}
                                            onStopRecording={() => undefined}
                                        />
                                    ) : (
                                        <div className="ml-0">
                                            <div>
                                                <LaptopIcon className=" d-flex m-auto mt-4"/>
                                            </div>
                                            <div>
                                                <p className="mb-0 text-center my-4 font-weight-600 body-title">
                                                    Your Browser does not support WebRTC Webcam Recording!
                                                </p>
                                            </div>
                                            <div>
                                                <p className="text-primary mb-0 text-center">
                                                    <strong>DigitalHire recommends that you use a different browser such
                                                        as</strong>
                                                </p>
                                            </div>
                                            <div>
                                                <p className="mb-0 text-center">Chrome, Firefox, Edge or Opera.</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {!testDevice && <LaptopIcon className=" d-flex m-auto"/>}
                        <div className="d-flex justify-content-center">
                            {!testDevice && (
                                <Button
                                    variation="primary btn-sm ml-2 mt-4"
                                    name="test-device-and-continue"
                                    value="Test Camera & Microphone"
                                    onSubmit={handleTestDevice}
                                />
                            )}
                            {!disableCamera && testDevice && allowAudio && allowCamera && (
                                <Link to={{pathname: "/interview/questions", state: data}}>
                                    <Button
                                        variation="primary btn-sm ml-2"
                                        name="test-device-and-continue"
                                        value="Continue"
                                        //onSubmit={() => history.push("/interview/questions")}
                                    />
                                </Link>
                            )}
                        </div>
                        {!testDevice && (
                            <div>
                                <p className="d-flex mb-0 justify-content-center text-center my-1 mt-4">
                                    Make sure you Allow access to Camera & Microphone when asked.
                                </p>
                                <p className="d-flex mb-0 justify-content-center text-center mt-3">
                                    During camera testing, a 5 second video will be recorded. This will not be saved.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </VideoResumeCard>
    )
}

export default SetupDevices
