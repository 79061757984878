import React, {useState} from "react";
import "./GettingStarted.css"
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import gs1 from "../../images/getting-started/gs-1.png";
import gs2 from "../../images/getting-started/gs-2.png";
import gs3 from "../../images/getting-started/gs-3.png";
import gs4 from "../../images/getting-started/gs-4.png";
import gs5 from "../../images/getting-started/gs-5.png";
import gs6 from "../../images/getting-started/gs-6.png";
import gs7 from "../../images/getting-started/gs-7.png";
import gs8 from "../../images/getting-started/gs-8.png";
import gs9 from "../../images/getting-started/gs-9.png";

const GettingStarted = (props) => {

    const [currentItem, setCurrentItem] = useState(0)

    return (

        <div className="authincation h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    {/*<h4 className="text-center mb-4 ">Getting Started</h4>*/}

                    <AliceCarousel autoPlay
                                   autoPlayInterval="3000"
                                   onSlideChanged={(e) => {

                                       setCurrentItem(e.item)
                                   }}
                                   renderPrevButton={() => {


                                       if (currentItem !== 0) {
                                           // setCurrentItem(currentItem - 1)
                                           return <button className="btn btn-primary float-left">Previous</button>
                                       }
                                   }}
                                   renderNextButton={() => {

                                       if (currentItem !== 8) {
                                           // setCurrentItem(currentItem + 1)
                                           return <button className="btn btn-primary float-right">Next</button>
                                       }
                                   }}
                                   disableDotsControls={true}
                                   keyboardNavigation={true}
                    >
                        <img src={gs1} className="sliderimg"/>
                        <img src={gs2} className="sliderimg"/>
                        <img src={gs3} className="sliderimg"/>
                        <img src={gs4} className="sliderimg"/>
                        <img src={gs5} className="sliderimg"/>
                        <img src={gs6} className="sliderimg"/>
                        <img src={gs7} className="sliderimg"/>
                        <img src={gs8} className="sliderimg"/>
                        <img src={gs9} className="sliderimg"/>
                    </AliceCarousel>

                </div>
            </div>
        </div>
    );
};


export default GettingStarted;
