import React, { Fragment, useState } from "react"
import Intro from "./Intro"
import RecVid from "./RecVid"

const VideoResume = () => {
  const [stepper, setStepper] = useState(0)
  const handleNextStep = () => {
    let tempCount = stepper
    setStepper(tempCount + 1)
  }
  const handleBackStep = () => {
    let tempCount = stepper
    setStepper(tempCount - 1)
  }
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12 col-lg-8 video-resume-mx-width px-0 px-md-3 m-auto">
          <div className="card">
            <div className="card-body">
              {stepper == 0 && <Intro nextStep={handleNextStep} />}
              {stepper == 1 && <RecVid backStep={handleBackStep} />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default VideoResume
