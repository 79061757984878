import {
    SET_AUTH_API_REQUEST,
    SET_AUTH_API_SUCCESS,
    SET_AUTH_API_FAILURE,
    SET_SIGNUP_API_REQUEST,
    SET_SIGNUP_API_SUCCESS,
    SET_SIGNUP_API_FAILURE,
    SET_SIGNUP_VERIFY_API_REQUEST,
    SET_SIGNUP_VERIFY_API_SUCCESS,
    SET_SIGNUP_VERIFY_API_FAILURE, USER_RESEND_PIN_REQUEST, USER_RESEND_PIN_COMPLETED, USER_RESEND_PIN_FAILED,
} from '../constants/AuthConstants';

const initalState = {
    AuthData: {},
    AuthDataFail: false,
    AuthDataLoading: false,

    SignUpData: {},
    SignUpFail: false,
    SignUpLoading: false,

    VerifyData: {},
    VerifyFail: false,
    VerifyLoading: false

}

const AuthReducers = (state = initalState, action) => {
    switch (action.type) {
        case SET_AUTH_API_SUCCESS:
            return {...state, AuthData: action.payload, AuthDataLoading: false};
        case SET_AUTH_API_REQUEST:
            return {...state, AuthDataLoading: true};
        case SET_AUTH_API_FAILURE:
            return {...state, AuthDataFail: true, AuthDataLoading: false};
        case SET_SIGNUP_API_SUCCESS:
            return {...state, SignUpData: action.payload, AuthData: action.payload, SignUpLoading: false};
        case SET_SIGNUP_API_REQUEST:
            return {...state, SignUpLoading: true};
        case SET_SIGNUP_API_FAILURE:
            return {...state, SignUpFail: true, SignUpLoading: false};
        case SET_SIGNUP_VERIFY_API_SUCCESS:
            return {...state, VerifyData: action.payload, AuthData: {verified: true}, VerifyLoading: false};
        case SET_SIGNUP_VERIFY_API_REQUEST:
        case USER_RESEND_PIN_REQUEST:
            return {...state, VerifyLoading: true};
        case USER_RESEND_PIN_COMPLETED:
        case USER_RESEND_PIN_FAILED:
            return {...state, VerifyLoading: false}
        case SET_SIGNUP_VERIFY_API_FAILURE:
            return {...state, VerifyFail: true, VerifyLoading: false};
        default:
            return state;
    }
}

export default AuthReducers;
