/// Menu
import MetisMenu from "metismenujs"
import React, {Component, useContext, useEffect} from "react"
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar"
/// Link
import {Link} from "react-router-dom"
import useScrollPosition from "use-scroll-position"
import {ThemeContext} from "../../../context/ThemeContext"
import profile from "../../../images/Untitled-1.jpg"
import "./sidebar.css"

class MM extends Component {
    componentDidMount() {
        this.$el = this.el
        this.mm = new MetisMenu(this.$el)
    }

    componentWillUnmount() {
        //  this.mm("dispose");
        // console.log(this.mm);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

const SideBar = () => {
    const {iconHover, sidebarposition, headerposition, sidebarLayout} = useContext(ThemeContext)
    useEffect(() => {
        var btn = document.querySelector(".nav-control")
        var aaa = document.querySelector("#main-wrapper")

        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle")
        }

        btn.addEventListener("click", toggleFunc)

    }, [])
    let scrollPosition = useScrollPosition()
    /// Path
    let path = window.location.pathname
    path = path.split("/")
    path = path[path.length - 1]
    /// Active menu
    let deshBoard = ["dashboard", "", "my-wallets", "transactions", "coin-details", "portofolio", "market-capital"],
        vidResume = ["vidresume"],
        gettingStarted = ["getting-started"],
        app = [
            "profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "post-details",
            "ecom-product-detail",
        ],
        email = ["email-compose", "email-inbox", "email-read"],
        shop = [
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "ecom-product-detail",
        ],
        charts = ["chart-rechart", "chart-flot", "chart-chartjs", "chart-chartist", "chart-sparkline", "chart-apexchart"],
        bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
        ],
        plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-noui-slider",
            "map-jqvmap",
            "uc-lightgallery",
        ],
        widget = ["widget-basic"],
        forms = ["form-element", "form-wizard", "form-editor-summernote", "form-pickers", "form-validation-jquery"],
        table = ["table-bootstrap-basic", "table-datatable-basic"],
        pages = [
            "page-register",
            "page-login",
            "page-lock-screen",
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
        ],
        error = ["page-error-400", "page-error-403", "page-error-404", "page-error-500", "page-error-503"]
    return (
        <div
            className={`deznav ${iconHover} ${
                sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static"
                    ? scrollPosition > 120
                        ? "fixed"
                        : ""
                    : ""
            }`}
        >
            <PerfectScrollbar className="deznav-scroll">
                {/* <div className="main-profile">
          <img src={userData?.image || profile} alt="" className="rounded-circle" />
          <h5 className="mb-0 fs-20 text-black ">
            {userData?.first_name && userData?.last_name ? `${userData.first_name} ${userData.last_name}` : ""}
          </h5>
        </div> */}
                <MM className="metismenu metismenu2" id="menu">
                    <li className={`${deshBoard.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="has-arrow-cancel ai-icon" to="/">
                            <i className="bx bx-home-circle"></i>
                            <span className="nav-text">Dashboard</span>
                        </Link>
                        {/* <ul >
              <li><Link className={`${path === "" ? "mm-active" : ""}`} to="/">  Dashboard </Link></li>
            </ul> */}
                    </li>
                    <li className={`${app.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="has-arrow-cancel ai-icon" to="/profile">
                            <i className="bx bx-user-circle"></i>
                            <span className="nav-text">Profile</span>
                        </Link>
                        {/* <ul >
              <li><Link className={`${path === "profile" ? "mm-active" : ""}`} to="/profile">Profile</Link></li>
            </ul> */}
                    </li>
                    <li className={`${vidResume.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="has-arrow-cancel ai-icon" to="/videoresume">
                            <i className="bx bx-video"></i>
                            <span className="nav-text">Video Resume</span>
                        </Link>
                        {/* <ul >
              <li><Link className={`${path === "profile" ? "mm-active" : ""}`} to="/profile">Profile</Link></li>
            </ul> */}
                    </li>
                    <li className={`${gettingStarted.includes(path) ? "mm-active mm-activ2" : "hover-class"}`}>
                        <Link className="has-arrow-cancel ai-icon" to="/getting-started">
                            <i className='bx bx-chalkboard'></i>
                            <span className="nav-text">How To Guide</span>
                        </Link>
                        {/* <ul >
              <li><Link className={`${path === "profile" ? "mm-active" : ""}`} to="/profile">Profile</Link></li>
            </ul> */}
                    </li>
                </MM>
                <div className="copyright">
                    <p>
                        2024 © <strong>DigitalHire</strong>
                    </p>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

export default SideBar
