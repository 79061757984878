import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import AuthReducers from '../reducers/AuthReducers';
import ProfileReducers from '../reducers/ProfileReducers'
import thunk from 'redux-thunk';


const reducer = combineReducers({
    AuthReducers,
    ProfileReducers
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
