import React, { useEffect } from "react"

import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getProfileData } from "../../../redux/actions/ProfileActions"
import avatar from "../../../images/svg/default.svg"
import { logOutAuthData } from "../../../redux/actions/AuthActions"
import { getUserDataSelector } from "../../../redux/selectors/ProfileSelector"

const Header = (props) => {
  const { userData, logOutAuthDataAction, getProfileDataAction } = props

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      getProfileDataAction()
    }
  }, [])

  const handleLogOut = () => {
    logOutAuthDataAction()
  }

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right main-notification">
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  // href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img src={userData?.image || avatar} width={20} />
                  <div className="header-info">
                    <span>
                      {userData?.first_name && userData?.last_name
                        ? `${userData.first_name} ${userData.last_name}`
                        : ""}
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-2">
                  <Link to="/" className="dropdown-item ai-icon">
                    <div onClick={handleLogOut}>
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ml-2">Logout </span>
                    </div>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userData: getUserDataSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileDataAction: () => dispatch(getProfileData()),
    logOutAuthDataAction: () => dispatch(logOutAuthData()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
