// 3rd party
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const TextArea = ({
  rows,
  cols,
  label,
  name,
  value,
  error,
  subText,
  wordLimit,
  className,
  placeholder,
  labelClassName,
  onChange,
  required,
}) => {
  const handleChange = (e) => {
    if (wordLimit && e.target.value.trim().split(" ").length > wordLimit) return
    onChange(e)
  }

  return (
    <div className="form-group">
      {label && (
        <label className={labelClassName} htmlFor={name}>
          {label}
          {subText && <span className="text-muted ml-1">{subText}</span>}
        </label>
      )}
      <textarea
        rows={rows}
        cols={cols}
        className={classnames("form-control", className, {
          "is-invalid": error,
        })}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        required={required}
      />
      {!!wordLimit && (
        <p className="mb-0 mt-2 text-right">
          {(value && value.trim().split(" ").length) || 0}/{wordLimit} Words
        </p>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

TextArea.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  wordLimit: PropTypes.number,
  error: PropTypes.string,
  subText: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

TextArea.defaultProps = {
  rows: 3,
  cols: 0,
  label: "",
  error: "",
  subText: "",
  wordLimit: 0,
  className: "",
  placeholder: "",
  labelClassName: "",
  required: false,
}

export default TextArea
