import React from "react"
/// React router dom
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom"
/// Css
import "./index.css"
/// Dashboard
import Home from "./components/Dashboard/Home"
/// Pages
import Registration from "./pages/Registration"
import ForgotPassword from "./pages/ForgotPassword"
import Login from "./pages/Login"
import Verify from "./pages/Verify"
import Error404 from "./pages/Error404"
// import Setting from "./layouts/Setting";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile"
import VideoResume from "./components/VideoResume/VideoResume"
import InterviewStart from "./components/InterviewStart/InterviewStart"
import InterviewSetupDevices from "./components/SetupDevices/SetupDevices"
import InterviewQuestions from "./components/InterviewQuestion/InterviewQuestions"
import InterviewFinish from "./components/InterviewFinish/InterviewFinish"
import GoogleButton from "./components/GoogleButton/GoogleButton"
//Routes
import PrivateRoute from "./PrivateRoute"
import OpenRoute from "./OpenRoute"
import PracticeQuestion from "./components/InterviewQuestion/InterviewPracticeQuestion"
import InterviewConsent from "./components/InterviewConsent/InterviewConsent";
import InterviewConclusion from "./components/InterviewConclusion/InterviewConclusion";
import AddInterview from "./components/AddInterview/AddInterview";
import GettingStarted from "./pages/GettingStarted";

const Markup = () => {
    return (
        <Router>
            <Switch>
                <OpenRoute exact path="/" component={Login}/>
                <OpenRoute exact path="/register" component={Registration}/>
                <OpenRoute exact path="/verify" component={Verify}/>
                <OpenRoute exact path="/forgot-password" component={ForgotPassword}/>
                <PrivateRoute exact path="/dashboard" component={Home}/>
                <PrivateRoute exact path="/profile" component={AppProfile}/>
                <PrivateRoute exact path="/videoresume" component={VideoResume}/>
                <PrivateRoute exact path="/interview/start" component={InterviewStart}/>
                <PrivateRoute exact path="/interview/setupdevices" component={InterviewSetupDevices}/>
                <PrivateRoute exact path="/interview/questions" component={InterviewQuestions}/>
                <PrivateRoute exact path="/interview/complete" component={InterviewFinish}/>
                <PrivateRoute exact path="/interview/practice" component={PracticeQuestion}/>
                <PrivateRoute exact path="/interview/consent" component={InterviewConsent}/>
                <PrivateRoute exact path="/interview/conclusion" component={InterviewConclusion}/>
                <PrivateRoute exact path="/getting-started" component={GettingStarted}/>
                <Route exact path="/interview/:id" component={AddInterview}/>
                <OpenRoute exact path="/oauth/:token" component={GoogleButton}/>

                <Route exact path="/logout" component={Error404}/>
                <Route component={Error404}/>
            </Switch>
            {/* <Setting /> */}
        </Router>
    )
}

export default Markup
