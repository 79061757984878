import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'react-toastify/dist/ReactToastify.css'

import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";

// if (process.env.NODE_ENV === 'production') {
//     console.log = () => {
//     }
//     console.error = () => {
//     }
//     console.debug = () => {
//     }
// }

ReactDOM.render(
    <React.StrictMode>
        <SimpleReactLightbox>
            <App/>
        </SimpleReactLightbox>
    </React.StrictMode>,
    document.getElementById("root")
);
reportWebVitals();
