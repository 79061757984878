export const getUserDataSelector = (state) => {
    if (state?.ProfileReducers?.profileData) return state.ProfileReducers.profileData
    return false
}
export const getAllInterviewsSelector = (state) => {
    if (state?.ProfileReducers?.allInterviews?.data) return state.ProfileReducers.allInterviews.data
    return false
}
export const getAllInterviewsCountSelector = (state) => {
    if (state?.ProfileReducers?.allInterviews?.totalItems) return state.ProfileReducers.allInterviews.totalItems
    return false
}
export const getAllInterviewsPagesSelector = (state) => {
    if (state?.ProfileReducers?.allInterviews?.totalPages) return state.ProfileReducers.allInterviews.totalPages
    return false
}
export const getAllInterviewsCurrentPageSelector = (state) => {
    if (state?.ProfileReducers?.allInterviews?.currentPage) return state.ProfileReducers.allInterviews.currentPage
    return false
}
export const getAllInterviewsLoadingSelector = (state) => {
    if (state?.ProfileReducers?.allInterviewsLoading) return state.ProfileReducers.allInterviewsLoading
    return false
}
export const getAllInterviewsStatsLoadingSelector = (state) => {
    if (state?.ProfileReducers?.allInterviewsStatsLoading) return state.ProfileReducers.allInterviewsStatsLoading
    return false
}
export const getInterviewLoadingSelector = (state) => {
    if (state?.ProfileReducers?.interviewLoading) return state.ProfileReducers.interviewLoading
    return false
}
export const getVideoUrlSelector = (state) => {
    if (state?.ProfileReducers?.videoUrl) return state.ProfileReducers.videoUrl
    return undefined
}
export const getVideoThumbnailUrlSelector = (state) => {
    if (state?.ProfileReducers?.videoThumbnailUrl) return state.ProfileReducers.videoThumbnailUrl
    return undefined
}
export const getPdfUrlSelector = (state) => {
    if (state?.ProfileReducers?.pdfUrl) return state.ProfileReducers.pdfUrl
    return undefined
}
export const getProfileLoadingSelector = (state) => {
    if (state?.ProfileReducers?.profileLoading) return state.ProfileReducers.profileLoading
    return false
}
export const getInterviewPendingSelector = (state) => {
    if (state?.ProfileReducers?.interviewPending) return state.ProfileReducers.interviewPending
    return false
}
export const getInterviewCompletedSelector = (state) => {
    if (state?.ProfileReducers?.interviewCompleted) return state.ProfileReducers.interviewCompleted
    return false
}
export const getTimeStampSelector = (state) => {
    if (state?.ProfileReducers?.video_uploaded_at) return state.ProfileReducers.video_uploaded_at
    return false
}
