import {
    SET_PROFILE_PASSWORD_API_REQUEST,
    SET_PROFILE_PASSWORD_API_SUCCESS,
    SET_PROFILE_PASSWORD_API_FAILURE,
    SET_DEACTIVATE_ACCOUNT_API_REQUEST,
    SET_DEACTIVATE_ACCOUNT_API_SUCCESS,
    SET_DEACTIVATE_ACCOUNT_API_FAILURE,
    POST_AWS_MEDIA_API_REQUEST,
    POST_AWS_MEDIA_API_SUCCESS,
    POST_AWS_MEDIA_API_FAILURE,
    GET_PROFILE_DETAILS_API_REQUEST,
    GET_PROFILE_DETAILS_API_SUCCESS,
    GET_PROFILE_DETAILS_API_FAILURE,
    POST_ADD_INTERVIEW_API_REQUEST,
    POST_ADD_INTERVIEW_API_SUCCESS,
    POST_ADD_INTERVIEW_API_FAILURE,
    GET_ALL_INTERVIEWS_API_REQUEST,
    GET_ALL_INTERVIEWS_API_SUCCESS,
    GET_ALL_INTERVIEWS_API_FAILURE,
    UPDATE_PROFILE_DETAILS_API_REQUEST,
    UPDATE_PROFILE_DETAILS_API_SUCCESS,
    UPDATE_PROFILE_DETAILS_API_FAILURE,
    SET_PROFILE_VIDEORESUME_API_REQUEST,
    SET_PROFILE_VIDEORESUME_API_SUCCESS,
    SET_PROFILE_VIDEORESUME_API_FAILURE,
    GET_PROFILE_VIDEORESUME_API_FAILURE,
    GET_PROFILE_VIDEORESUME_API_REQUEST,
    GET_PROFILE_VIDEORESUME_API_SUCCESS,
    SET_PROFILE_PDFRESUME_API_FAILURE,
    SET_PROFILE_PDFRESUME_API_REQUEST,
    SET_PROFILE_PDFRESUME_API_SUCCESS,
    //GET_PROFILE_PDFRESUME_API_REQUEST,
    GET_PROFILE_PDFRESUME_API_SUCCESS,
    //GET_PROFILE_PDFRESUME_API_FAILURE,
    SET_INTERVIEW_VIDEO_RESUME_API_REQUEST,
    SET_INTERVIEW_VIDEO_RESUME_API_SUCCESS,
    SET_INTERVIEW_VIDEO_RESUME_API_FAILURE,
    GET_ALL_INTERVIEWS_STATS_API_SUCCESS,
    GET_ALL_INTERVIEWS_STATS_API_FAILURE,
    GET_ALL_INTERVIEWS_STATS_API_REQUEST,
    MARK_INTERVIEW_CONSENT_API_SUCCESS,
    MARK_INTERVIEW_CONSENT_API_FAILURE,
    UPDATE_INTERVIEW_VIDEO_RESUME,
} from "../constants/ProfileConstants"
import {
    addNewInterviewService,
    getAllInterviewsService,
    getProfileDataService,
    setChangePasswordService,
    setDeactivateAccountService,
    updateProfileDataService,
    setProfileVideoResumeService,
    removeProfileVideoResumeService,
    getProfileVideoResumeService,
    setProfilePdfResumeService,
    //getProfilePdfResumeService,
    removeProfilePdfResumeService,
    interviewAnswerService,
    interviewVideoResumeService,
    postMediaToAWSSignedService,
    postMediaToAWSUploadService, getAllInterviewsStatsService, markInterviewConsentService,
} from "../../services/ProfileService"
import {toast} from "react-toastify"
import {formatError} from "../../services/FormatError"
import {logOutAuthData} from "./AuthActions"

const addNewInterview = (position_url) => {
    return (dispatch) => {
        dispatch(addNewInterviewRequestAction())
        addNewInterviewService(position_url)
            .then((response) => {
                if (response.data) {
                    toast.success("Interview added successfully", {autoClose: 2000})
                    dispatch(addNewInterviewSuccessAction(response.data))
                    const data = JSON.parse(localStorage.getItem("dataSave")) ? JSON.parse(localStorage.getItem("dataSave")) : null
                    const verified = data?.verified ? data?.verified : false
                    if (verified) {
                        dispatch(getAllInterviews())
                    }

                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addNewInterviewFailedAction(errorMessage))
                toast.error(errorMessage, {autoClose: 2000})
            })
    }
}

const getAllInterviews = (data) => {
    return (dispatch) => {
        dispatch(getAllInterviewsRequestAction())
        console.log("Interview Action Called")
        getAllInterviewsService(data)
            .then((response) => {

                dispatch(getAllInterviewsSuccessAction(response?.data?.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    if (error.response.status === 401 || error.response?.data?.error === "Invalid user role") {
                        dispatch(logOutAuthData())
                        return
                    }
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getAllInterviewsFailedAction(errorMessage))
            })
    }
}

export const getAllInterviewsStatsAction = (data) => {
    return (dispatch) => {
        dispatch(getAllInterviewsStatsRequestAction())
        console.log("Interview Action Called")
        getAllInterviewsStatsService(data)
            .then((response) => {

                dispatch(getAllInterviewsStatsSuccessAction(response?.data?.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    if (error.response.status === 401 || error.response?.data?.error === "Invalid user role") {
                        dispatch(logOutAuthData())
                        return
                    }
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getAllInterviewsStatsFailedAction(errorMessage))
            })
    }
}

export const markInterviewConsentAction = (data, props, state) => {
    return (dispatch) => {
        // dispatch(getAllInterviewsStatsRequestAction())
        // console.log("Interview Action Called")
        markInterviewConsentService(data)
            .then((response) => {
                dispatch(markInterviewConsentSuccessAction(response?.data?.data))
                props.history.push({pathname: "/interview/conclusion", state: state}
                )
            })
            .catch((error) => {
                props.history.push({pathname: "/interview/conclusion", state: state})
                let errorMessage
                if (error.response) {
                    if (error.response.status === 401 || error.response?.data?.error === "Invalid user role") {
                        dispatch(logOutAuthData())
                        return
                    }
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(markInterviewConsentFailedAction(errorMessage))
            })
    }
}

const getProfileData = (token, social = null, cb = null) => {
    return (dispatch) => {
        dispatch(getProfileDataRequestAction())
        getProfileDataService()
            .then((response) => {
                if (response.data.data) {
                    if (social) {
                        localStorage.setItem("dataSave", JSON.stringify({
                            token: token,
                            verified: true,
                            company_profile: true
                        }))
                        window.location.href = "/dashboard"
                    }
                    dispatch(getProfileDataSuccessAction(response.data.data))
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    if (error.response.status === 401 || error.response?.data?.error === "Invalid user role") {
                        dispatch(logOutAuthData())
                        return
                    }
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getProfileDataFailedAction(errorMessage))
            })
    }
}

const setChangePassword = (dataForm, cb = null) => {
    return (dispatch) => {
        dispatch(setChangePasswordRequestAction())
        setChangePasswordService(dataForm)
            .then((response) => {
                dispatch(setChangePasswordSuccessAction(""))
                if (cb) {
                    cb({
                        alert: true,
                        type: 'success',
                        variant: 'success',
                        msg: "Password changed successfully"
                    })
                } else {
                    toast.success("Password changed successfully", {autoClose: 2000})
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(setChangePasswordFailedAction(errorMessage))
                if (cb) {
                    cb({
                        alert: true,
                        type: 'error',
                        variant: 'danger',
                        msg: errorMessage
                    })
                } else {
                    toast.error(errorMessage, {autoClose: 2000})
                }
            })
    }
}

const setDeactivateAccount = (reason, cb = null) => {
    return (dispatch) => {
        dispatch(setDeactivateAccountRequestAction())
        setDeactivateAccountService(reason)
            .then((response) => {
                dispatch(setDeactivateAccountSuccessAction(response.data.message))
                if (cb) {
                    cb({
                        alert: true,
                        type: 'success',
                        variant: 'success',
                        msg: response.data.message
                    })
                } else {
                    toast.success(response.data.message, {autoClose: 3000})
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(setDeactivateAccountFailedAction(errorMessage))
                if (cb) {
                    cb({
                        alert: true,
                        type: 'error',
                        variant: 'danger',
                        msg: errorMessage
                    })
                } else {
                    toast.error(errorMessage)
                }
            })
    }
}

const postMediaToAWS = (data, callback, cb = null) => {
    return (dispatch) => {
        dispatch(postMediaToAWSRequestAction())
        postMediaToAWSSignedService(data.key)
            .then((response) => {
                if (response.data.data) {
                    postMediaToAWSUploadService(response.data.data, data.file)
                        .then((response) => {
                            callback(response, data.key)
                            if (response) {
                                dispatch(postMediaToAWSSuccessAction(response))
                                if (data.key.slice(data.key.lastIndexOf(".") + 1) === "webm" && data.VR) {
                                    dispatch(setProfileVideoResume(data.key))
                                } else if (data.key.slice(data.key.lastIndexOf(".") + 1) === "pdf") {
                                    dispatch(setProfilePdfResume(data.key))
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            if (error.response) {
                                callback(error.response, data.key)
                                dispatch(postMediaToAWSFailedAction(error.response))
                                if (cb) {
                                    cb({
                                        alert: true,
                                        type: 'error',
                                        variant: 'danger',
                                        msg: error.response.statusText
                                    })
                                } else {
                                    toast.error(error.response.statusText, {autoClose: 2000})
                                }
                            } else {
                                callback(error, data.key)
                                dispatch(postMediaToAWSFailedAction(error.message))
                                if (cb) {
                                    cb({
                                        alert: true,
                                        type: 'error',
                                        variant: 'danger',
                                        msg: error.message
                                    })
                                } else {
                                    toast.error(error.message, {autoClose: 2000})
                                }
                            }
                        })
                }
            })
            .catch((error) => {
                if (error.response) {
                    callback(error.response, data.key)
                    dispatch(postMediaToAWSFailedAction(error.response))
                    toast.error(error.response.statusText, {autoClose: 2000})
                } else {
                    callback(error, data.key)
                    dispatch(postMediaToAWSFailedAction(error.message))
                    if (cb) {
                        cb({
                            alert: true,
                            type: 'error',
                            variant: 'danger',
                            msg: error.message
                        })
                    } else {
                        toast.error(error.message, {autoClose: 2000})
                    }
                }
            })
    }
}

const updateProfileData = (user_data, cb = null) => {
    return (dispatch) => {
        dispatch(updateProfileDataRequestAction())
        updateProfileDataService(user_data)
            .then((response) => {
                if (response.data.data) {
                    dispatch(updateProfileDataSuccessAction(""))
                    if (cb) {
                        cb({
                            alert: true,
                            type: 'success',
                            variant: 'success',
                            msg: response.data.message
                        })
                    } else {
                        toast.success(response.data.message, {autoClose: 2000})
                    }
                    dispatch(getProfileDataSuccessAction(response.data.data.Profile))
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(updateProfileDataFailedAction(errorMessage))

                if (cb) {
                    cb({
                        alert: true,
                        type: 'error',
                        variant: 'danger',
                        msg: errorMessage
                    })
                } else {
                    toast.error(errorMessage, {autoClose: 2000})
                }
            })
    }
}

const setProfileVideoResume = (url) => {
    return (dispatch) => {
        dispatch(setProfileVideoResumeRequestAction())
        setProfileVideoResumeService(url)
            .then((response) => {
                if (response.data.data.Profile.video_resume) {
                    dispatch(setProfileVideoResumeSuccessAction(response.data.data.Profile.video_resume))
                    toast.success("Video Uploaded Successfully", {autoClose: 2000})
                    window.location.replace("/dashboard")
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(setProfileVideoResumeFailedAction(errorMessage))
                toast.error(errorMessage)
            })
    }
}

const removeProfileVideoResume = (callback) => {
    return (dispatch) => {
        // dispatch(removeProfileVideoResumeRequestAction())
        removeProfileVideoResumeService()
            .then((response) => {
                console.log(response)
                dispatch(getProfileVideoResumeSuccessAction(response.data.data.Profile))
                callback(response.data.data.Profile.video_resume)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                //dispatch(removeProfileVideoResumeFailedAction(error.message))
                toast.error(errorMessage)
            })
    }
}
//Response contains pdf url as well so no need to call same service seperate for pdf url. Commenting it for now.
const getProfileVideoResume = (cb = null) => {
    return (dispatch) => {
        dispatch(getProfileVideoResumeRequestAction())
        getProfileVideoResumeService()
            .then((response) => {
                dispatch(getProfileVideoResumeSuccessAction(response.data.data.Profile))
                dispatch(getProfilePdfResumeSuccessAction(response.data.data.Profile.pdf_resume))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getProfileVideoResumeFailedAction(errorMessage))

                if (cb) {
                    cb({
                        alert: true,
                        type: 'error',
                        variant: 'danger',
                        msg: errorMessage
                    })
                } else {
                    toast.error(errorMessage)
                }
            })
    }
}

const setProfilePdfResume = (url) => {
    return (dispatch) => {
        dispatch(setProfilePdfResumeRequestAction())
        setProfilePdfResumeService(url)
            .then((response) => {
                if (response.data.data.Profile.pdf_resume) {
                    dispatch(setProfilePdfResumeSuccessAction(response.data.data.Profile.pdf_resume))
                    toast.success("Pdf Uploaded Successfully", {autoClose: 2000})
                    dispatch(getProfileVideoResume())
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(setProfilePdfResumeFailedAction(errorMessage))
                toast.error(errorMessage)
            })
    }
}

// const getProfilePdfResume = () => {
//   return (dispatch) => {
//     dispatch(getProfilePdfResumeRequestAction())
//     getProfilePdfResumeService()
//       .then((response) => {
//         dispatch(getProfilePdfResumeSuccessAction(response.data.data.Profile.pdf_resume))
//       })
//       .catch((error) => {
//         let errorMessage
//         if (error.response) {
//           errorMessage = formatError(error.response.data)
//         } else {
//           errorMessage = error.message
//         }
//         dispatch(getProfilePdfResumeFailedAction(errorMessage))
//         toast.error(errorMessage)
//       })
//   }
// }

const removeProfilePdfResume = (callback) => {
    return (dispatch) => {
        // dispatch(removeProfilePdfResumeRequestAction())
        removeProfilePdfResumeService()
            .then((response) => {
                dispatch(getProfilePdfResumeSuccessAction(response.data.data.Profile.pdf_resume))
                callback(response.data.data.Profile.pdf_resume)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                //dispatch(removeProfilePdfResumeFailedAction(error.response.message))
                toast.error(errorMessage)
            })
    }
}

const interviewAnswer = (answers, callback = null) => {
    return (dispatch) => {
        interviewAnswerService(answers)
            .then((response) => {
                toast.success("Answer Submitted", {autoClose: 2000})
                callback({success: true})
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                toast.error(errorMessage)
                callback({success: false})
            })
    }
}

const interviewVideoResume = (postData, callback = null, alert) => {
    return (dispatch) => {
        dispatch(interviewVideoResumeRequestAction())
        interviewVideoResumeService(postData)
            .then((response) => {
                // console.log("200 DATA: ", response.data.data)
                dispatch(interviewVideoResumeSuccessAction(response.data.data.Interview.video_resume))
                // alert({
                //     alert: true,
                //     type: 'success',
                //     variant: 'success',
                //     msg: "Interview VR Submitted"
                // })
                toast.success("Interview VR Submitted", {autoClose: 2000})
                // toast.success("Interview VR Submitted", { autoClose: 2000 })
                callback(true)
            })
            .catch((error) => {
                // console.log("ERROR DATA: ", error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(interviewVideoResumeFailedAction(errorMessage))
                callback(false)
                toast.error(errorMessage, {autoClose: 2000})
                // if (alert) alert({
                //     alert: true,
                //     type: 'error',
                //     variant: 'danger',
                //     msg: errorMessage
                // })
                // toast.error(errorMessage)
            })
    }
}

export const addNewInterviewRequestAction = () => {
    return {
        type: POST_ADD_INTERVIEW_API_REQUEST,
    }
}

export const addNewInterviewSuccessAction = (data) => {
    return {
        type: POST_ADD_INTERVIEW_API_SUCCESS,
        payload: data,
    }
}

export const addNewInterviewFailedAction = (data) => {
    return {
        type: POST_ADD_INTERVIEW_API_FAILURE,
        payload: data,
    }
}
export const getAllInterviewsRequestAction = () => {
    return {
        type: GET_ALL_INTERVIEWS_API_REQUEST,
    }
}

export const getAllInterviewsStatsRequestAction = () => {
    return {
        type: GET_ALL_INTERVIEWS_STATS_API_REQUEST,
    }
}

export const getAllInterviewsSuccessAction = (data) => {
    return {
        type: GET_ALL_INTERVIEWS_API_SUCCESS,
        payload: data,
    }
}

export const getAllInterviewsFailedAction = (data) => {
    return {
        type: GET_ALL_INTERVIEWS_API_FAILURE,
        payload: data,
    }
}
export const getAllInterviewsStatsSuccessAction = (data) => {
    return {
        type: GET_ALL_INTERVIEWS_STATS_API_SUCCESS,
        payload: data,
    }
}

export const getAllInterviewsStatsFailedAction = (data) => {
    return {
        type: GET_ALL_INTERVIEWS_STATS_API_FAILURE,
        payload: data,
    }
}
export const markInterviewConsentSuccessAction = (data) => {
    return {
        type: MARK_INTERVIEW_CONSENT_API_SUCCESS,
        payload: data,
    }
}

export const markInterviewConsentFailedAction = (data) => {
    return {
        type: MARK_INTERVIEW_CONSENT_API_FAILURE,
        payload: data,
    }
}
export const getProfileDataRequestAction = () => {
    return {
        type: GET_PROFILE_DETAILS_API_REQUEST,
    }
}

export const getProfileDataSuccessAction = (data) => {
    return {
        type: GET_PROFILE_DETAILS_API_SUCCESS,
        payload: data,
    }
}

export const getProfileDataFailedAction = (data) => {
    return {
        type: GET_PROFILE_DETAILS_API_FAILURE,
        payload: data,
    }
}

export const setChangePasswordRequestAction = () => {
    return {
        type: SET_PROFILE_PASSWORD_API_REQUEST,
    }
}

export const setChangePasswordSuccessAction = (data) => {
    return {
        type: SET_PROFILE_PASSWORD_API_SUCCESS,
        payload: data,
    }
}

export const setChangePasswordFailedAction = (data) => {
    return {
        type: SET_PROFILE_PASSWORD_API_FAILURE,
        payload: data,
    }
}
export const setDeactivateAccountRequestAction = () => {
    return {
        type: SET_DEACTIVATE_ACCOUNT_API_REQUEST,
    }
}

export const setDeactivateAccountSuccessAction = (data) => {
    return {
        type: SET_DEACTIVATE_ACCOUNT_API_SUCCESS,
        payload: data,
    }
}

export const setDeactivateAccountFailedAction = (data) => {
    return {
        type: SET_DEACTIVATE_ACCOUNT_API_FAILURE,
        payload: data,
    }
}
export const updateProfileDataRequestAction = () => {
    return {
        type: UPDATE_PROFILE_DETAILS_API_REQUEST,
    }
}

export const updateProfileDataSuccessAction = (data) => {
    return {
        type: UPDATE_PROFILE_DETAILS_API_SUCCESS,
        payload: data,
    }
}

export const updateProfileDataFailedAction = (data) => {
    return {
        type: UPDATE_PROFILE_DETAILS_API_FAILURE,
        payload: data,
    }
}

export const setProfileVideoResumeRequestAction = () => {
    return {
        type: SET_PROFILE_VIDEORESUME_API_REQUEST,
    }
}

export const setProfileVideoResumeSuccessAction = (data) => {
    return {
        type: SET_PROFILE_VIDEORESUME_API_SUCCESS,
        payload: data,
    }
}

export const setProfileVideoResumeFailedAction = (data) => {
    return {
        type: SET_PROFILE_VIDEORESUME_API_FAILURE,
        payload: data,
    }
}

export const getProfileVideoResumeRequestAction = () => {
    return {
        type: GET_PROFILE_VIDEORESUME_API_REQUEST,
    }
}

export const getProfileVideoResumeSuccessAction = (data) => {
    return {
        type: GET_PROFILE_VIDEORESUME_API_SUCCESS,
        payload: data,
    }
}

export const getProfileVideoResumeFailedAction = (data) => {
    return {
        type: GET_PROFILE_VIDEORESUME_API_FAILURE,
        payload: data,
    }
}

export const setProfilePdfResumeRequestAction = () => {
    return {
        type: SET_PROFILE_PDFRESUME_API_REQUEST,
    }
}

export const setProfilePdfResumeSuccessAction = (data) => {
    return {
        type: SET_PROFILE_PDFRESUME_API_SUCCESS,
        payload: data,
    }
}

export const setProfilePdfResumeFailedAction = (data) => {
    return {
        type: SET_PROFILE_PDFRESUME_API_FAILURE,
        payload: data,
    }
}

// export const getProfilePdfResumeRequestAction = () => {
//   return {
//     type: GET_PROFILE_PDFRESUME_API_REQUEST,
//   }
// }

export const getProfilePdfResumeSuccessAction = (data) => {
    return {
        type: GET_PROFILE_PDFRESUME_API_SUCCESS,
        payload: data,
    }
}

// export const getProfilePdfResumeFailedAction = (data) => {
//   return {
//     type: GET_PROFILE_PDFRESUME_API_FAILURE,
//     payload: data,
//   }
// }

export const interviewVideoResumeRequestAction = () => {
    return {
        type: SET_INTERVIEW_VIDEO_RESUME_API_REQUEST,
    }
}

export const interviewVideoResumeSuccessAction = (data) => {
    return {
        type: SET_INTERVIEW_VIDEO_RESUME_API_SUCCESS,
        payload: data,
    }
}

export const interviewVideoResumeFailedAction = (data) => {
    return {
        type: SET_INTERVIEW_VIDEO_RESUME_API_FAILURE,
        payload: data,
    }
}
export const postMediaToAWSRequestAction = () => {
    return {
        type: POST_AWS_MEDIA_API_REQUEST,
    }
}

export const postMediaToAWSSuccessAction = (data) => {
    return {
        type: POST_AWS_MEDIA_API_SUCCESS,
        payload: data,
    }
}

export const postMediaToAWSFailedAction = (data) => {
    return {
        type: POST_AWS_MEDIA_API_FAILURE,
        payload: data,
    }
}

export const updateInterviewVideoResume = (data) => {
    return {
        type: UPDATE_INTERVIEW_VIDEO_RESUME,
        payload: data,
    }
}

export {
    addNewInterview,
    getAllInterviews,
    getProfileData,
    setChangePassword,
    setDeactivateAccount,
    postMediaToAWS,
    updateProfileData,
    setProfileVideoResume,
    getProfileVideoResume,
    setProfilePdfResume,
    removeProfileVideoResume,
    // getProfilePdfResume,
    removeProfilePdfResume,
    interviewAnswer,
    interviewVideoResume,
}
