import React, {useCallback, useEffect, useState, useRef} from "react"
import classnames from "classnames"
import {interviewAnswer, interviewVideoResume, updateInterviewVideoResume} from "../../../redux/actions/ProfileActions"
import {connect, useDispatch} from "react-redux"
import {withRouter} from "react-router-dom"
import Button from "../../common/Button/Button"
import TextArea from "../../common/TextArea/TextArea"
import TextField from "../../common/TextField/TextField"
import VideoRecord from "../VideoRecord/Recorder"
import RecordAnswer from "../VideoResume/RecVid"
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import VideoModal from "../../common/Modal/Modal"
import Loader from "../../common/Loader"
import {fancyTimeFormat} from "../../../helpers/dateTime"
import {ReactComponent as ImageIcon} from "../../../assets/images/image.svg"
import {ReactComponent as NodePadIcon} from "../../../assets/images/note_pad.svg"
import {ReactComponent as WebCamIcon} from "../../../assets/images/webcam.svg"
import {ReactComponent as VideoIcon} from "../../../assets/images/video-camera.svg"
import {ReactComponent as StopWatch} from "../../../images/svg/stopwatch.svg"
import {UPDATE_INTERVIEW_VIDEO_RESUME} from "../../../redux/constants/ProfileConstants";

const InterviewQuestion = ({
                               type,
                               time,
                               title,
                               question,
                               subtitle,
                               progress,
                               imgURL,
                               videoURL,
                               onReturn,
                               onSubmit,
                               attempts,
                               isLoading,
                               isUploadFailed,
                               questionToken,
                               companyLogo,
                               isPractice,
                               returnBtnText,
                               progressColor,
                               questionDescription,
                               onSetDeviceInfo,
                               onAddMessageToToaster,
                               setQuestionCounter,
                               question_id,
                               interview_id,
                               video_resume,
                               questionCounter,
                               interviewResume,
                               interviewAnswerAction,
                               interviewVideoResumeAction,
                               history,
                               setVR
                           }) => {
    const [showContent, setShowContent] = useState(false)
    const [recordAns, setRecordAns] = useState(false)
    const [startTyping, setStartTyping] = useState(false)
    const [answer, setAnswer] = useState("")
    const [errorObj, setErrorObj] = useState({})
    const [disableViewImage, setDisableViewImage] = useState(false)
    const [remainingAttempts, setRemainingAttempts] = useState(0)
    const [openModal, setOpenModal] = useState("")
    const [vr, setVr] = useState({recordNew: false, currentResume: false})
    // const [vrUploaded, setVrUploaded] = useState(false)
    const duration = useRef(null)
    const typing = useRef(false)
    const durationCounter = useRef(null)
    let timeLeft = useRef(time * 60)
    let timeTaken = useRef(time * 60)
    let t_answer = useRef("")
    const dispatch = useDispatch()
    const setInterviewVn = (vn_obj) => {
        setVr(vn_obj)
        setVR(true);
        dispatch(updateInterviewVideoResume("true"))
    }

    useEffect(() => {
        if (duration.current) duration.current.innerText = "Time Left  " + fancyTimeFormat(time * 60)

        timeLeft.current = time * 60
        timeTaken.current = time * 60
        //
        // duration.current = null
        // durationCounter.current = null
        console.log("NEW QUESTION", time, timeLeft.current, timeTaken.current)
    }, [vr.currentResume, questionCounter])

    useEffect(() => attempts && setRemainingAttempts(attempts), [attempts])
    useEffect(() => {
        if (questionToken && !videoURL && !imgURL) {
            setRecordAns(true)
        } else {
            setRecordAns(false)
        }
        setAnswer("")
        typing.current = false
        setShowContent(true)
        setDisableViewImage(false)
        window.scrollTo({top: 0, behavior: "smooth"})
        return () => {
            clearInterval(durationCounter?.current)
        }
    }, [question, videoURL, imgURL])

    useEffect(() => answer && setDisableViewImage(true), [answer])

    useEffect(() => answer && setStartTyping(true), [answer])

    const handleRecord = useCallback(() => {
        setRecordAns(true)
        setShowContent(false)
    }, [setShowContent, setRecordAns])

    const handleContent = useCallback(() => {
        if (disableViewImage) return
        setRecordAns(false)
        setShowContent(true)
    }, [disableViewImage, setShowContent, setRecordAns])

    const getImage = () => {
        return videoURL ? (
            <VideoIcon className="mr-2" width="20" height="20"/>
        ) : (
            <ImageIcon className="mr-2" width="20" height="20"/>
        )
    }

    const getRecordImage = () =>
        type === "video" ? (
            <WebCamIcon className="mr-2" width="20" height="20"/>
        ) : (
            <NodePadIcon className="mr-2" width="20" height="20"/>
        )

    const handleValidation = () => {
        const error = {}
        if (!answer) {
            error.answer = "Please type your answer"
        }
        setErrorObj(error)
        const isValid = !!Object.keys(error).length
        return !isValid
    }

    useEffect(() => {
        if (Object.keys(errorObj).length) {
            handleValidation()
        }
    }, [answer])

    const handleSubmit = useCallback(
        (data, responseTime) => {
            if (type !== "video" && handleValidation()) {
                onSubmit({content: answer, responseTime})
                setStartTyping(false)
            } else if (type === "video") {
                onSubmit({content: data, responseTime})
            }
        },
        [answer, type, onSubmit, handleValidation]
    )

    const handleReturn = useCallback(
        (e) => {
            onReturn(e)
            setDisableViewImage(false)
        },
        [setDisableViewImage, onReturn]
    )

    const timerStart = () => {

        durationCounter.current = setInterval(() => {
            // setAnswer(previousValue => previousValue)
            // console.log("Interview after timeout", answer, t_answer)
            if (timeLeft.current <= 0) {


                // clearInterval(durationCounter.current)
                // console.log("Interview after timeout", answer, t_answer)
                //
                // console.log("Interview after timeout 2", answer, t_answer)
                handleSubmitAnswer(() => {
                    setRecordAns(false)
                    clearInterval(durationCounter.current)
                })

                // interviewAnswerAction(
                //     {
                //         question_id,
                //         interview_id,
                //         answer_data: {
                //             content: answer ? answer : "N/A",
                //             response_time: timeTaken.current,
                //         },
                //     },
                //     (res) => {
                //         if (res.success) {
                //             setRecordAns(false)
                //             setQuestionCounter((prevState) => prevState + 1)
                //         } else {
                //             setRecordAns(false)
                //         }
                //     }
                // )
            } else {
                if (duration.current) duration.current.innerText = "Time Left  " + fancyTimeFormat(timeLeft.current)
            }
            timeLeft.current -= 1
        }, 1000)
    }

    const setDefaultResume = () => {
        interviewVideoResumeAction(
            {
                interview_id,
                existing: true,
            },
            (res) => {
                console.log({res});
                if (res) {
                    setVr({
                        ...vr,
                        currentResume: true,
                    })
                    setVR(true);
                    dispatch(updateInterviewVideoResume("true"))
                }
            }
        )
    }

    const getTexFields = (
        <>
            {type === "essay" && (
                <TextArea
                    rows={4}
                    wordLimit={1200}
                    name="interview_answer"
                    value={answer}
                    error={errorObj.answer}
                    className="text-are-resize-none"
                    onChange={(e) => setAnswer(e.target.value)}
                />
            )}
            {type === "shortText" && (
                <TextField
                    name="shortText"
                    value={answer}
                    type="email"
                    error={errorObj.answer}
                    className="w-100"
                    handleChange={(e) => setAnswer(e.target.value)}
                />
            )}
        </>
    )

    const handleSubmitAnswer = (cb) => {

        if (cb) {
            cb()
        }

        clearInterval(durationCounter.current)

        timeTaken.current = timeTaken.current - timeLeft.current
        console.log("Answer: ", answer, t_answer, timeTaken.current, timeLeft.current)
        interviewAnswerAction(
            {
                question_id,
                interview_id,
                answer_data: {
                    content: answer ? answer : t_answer.current,
                    response_time: timeTaken.current,
                },
            },
            (res) => {
                if (res.success) {
                    setQuestionCounter((prevState) => prevState + 1)
                }
            }
        )
    }
    return (
        <VideoResumeCard
            helpCenter
            progressColor={progressColor}
            progress={progress}
            title={title}
            imgClass="rounded-circle"
            imageUrl={companyLogo}
            subtitle={subtitle}
        >
            {questionCounter === 0 && !vr.currentResume && !video_resume && sessionStorage.getItem("vr_uploaded") !== "true" ? (
                <div className="mt-4">
                    <div className="d-flex align-items-center justify-content-center flex-column">
                        <div>
                            <p>Choose Video Resume To Continue</p>
                        </div>
                        <div>
                            <button
                                className="btn btn-primary btn-sm btn-width mb-3 mb-md-0"
                                style={{width: "160px"}}
                                onClick={() => setVr({...vr, recordNew: true})}
                            >
                                Record New
                            </button>
                            <button
                                className="btn btn-outline-dark btn-sm btn-width mb-3 mb-md-0 ml-3"
                                style={{
                                    width: "160px",
                                    backgroundColor: vr.currentResume ? "#3e4347" : "transparent",
                                    color: vr.currentResume ? "#ffffff" : "#3e4347",
                                    display: !interviewResume ? "none" : "inline-block",
                                }}
                                onClick={setDefaultResume}
                            >
                                Use Existing
                            </button>
                        </div>
                    </div>
                    {vr.recordNew && questionCounter === 0 && !vr.currentResume && !video_resume && (
                        <div className="mt-4">
                            <RecordAnswer type="video" interview_id={interview_id} interview_resume={true} setVr={setVr}
                                          vr={vr} setInterviewVn={setInterviewVn}/>
                        </div>
                    )}
                </div>
            ) : (
                <div className="ml-4 mr-4">
                    <h5 className="mt-3 mb-3">{question}</h5>
                    {questionDescription && <p>{questionDescription}</p>}
                    {type !== "video" && (
                        <div class="d-flex justify-content-between mb-3">
                            <div class="d-flex align-items-center ">
                                <StopWatch/>
                                <h5 class="mx-3 mt-2">{time} minute</h5>
                            </div>
                            <div class="mb-2 d-flex align-items-center">
                                <h5 class="mb-0 mr-2 mt-2" ref={duration}></h5>
                            </div>
                        </div>
                    )}
                    {type === "essay" && (
                        <>
                            <TextArea
                                rows={4}
                                wordLimit={1200}
                                name="interview_answer"
                                value={answer}
                                error={errorObj.answer}
                                className="text-are-resize-none"
                                onChange={(e) => {
                                    setAnswer(e.target.value)
                                    t_answer.current = e.target.value
                                    if (answer.length >= 0 && !typing.current) {
                                        timerStart()
                                        typing.current = true
                                    }
                                }}
                            />
                            <button
                                className="btn btn-primary d-flex m-auto"
                                disabled={answer === "" ? true : false}
                                onClick={() => {
                                    handleSubmitAnswer()
                                    setRecordAns(true)
                                }}
                            >
                                {recordAns ? <Loader spinnerClass="text-white"/> : "Submit Answer"}
                            </button>
                        </>
                    )}
                    {type === "shortText" && (
                        <>
                            <TextField
                                name="shortText"
                                value={answer}
                                type="text"
                                error={errorObj.answer}
                                className="w-100"
                                handleChange={(e) => {
                                    setAnswer(e.target.value)
                                    t_answer.current = e.target.value
                                    if (answer.length >= 0 && !typing.current) {
                                        timerStart()
                                        typing.current = true
                                    }
                                }}
                            />
                            <button
                                className="btn btn-primary d-flex m-auto"
                                disabled={answer === "" ? true : false}
                                onClick={() => {
                                    handleSubmitAnswer()
                                    setRecordAns(true)
                                }}
                            >
                                {recordAns ? <Loader spinnerClass="text-white"/> : "Submit Answer"}
                            </button>
                        </>
                    )}
                    {(videoURL || imgURL) && (
                        <div className="d-flex justify-content-center mb-3 mt-4">
                            {
                                (videoURL && videoURL.includes("mp4") ? <video
                                    src={videoURL}
                                    className="w-100"
                                    disablePictureInPicture
                                    playsInline
                                    autoPlay
                                    controls
                                    controlsList="nodownload"
                                /> : <img src={imgURL || videoURL} style={{maxWidth: "300px"}}/>)
                            }

                        </div>
                    )}
                    {type === "video" && (
                        <>
                            <div>
                                <RecordAnswer
                                    type="video"
                                    timeAllowed={time}
                                    attempts={attempts}
                                    question_id={question_id}
                                    interview_id={interview_id}
                                    setQuestionCounter={setQuestionCounter}
                                    questionCounter={questionCounter}
                                    interview_resume={false}
                                />
                            </div>
                        </>
                    )}

                    {isPractice && (
                        <div style={{marginLeft: "-50px"}}>
                            <VideoRecord
                                isPractice={isPractice}
                                time={time}
                                type={type}
                                answer={answer}
                                isLoading={isLoading}
                                isUploadFailed={isUploadFailed}
                                onDisableViewImage={setDisableViewImage}
                                startTimer={startTyping}
                                attempts={remainingAttempts}
                                onSetAnswer={setAnswer}
                                returnBtnText={returnBtnText}
                                progressColor={progressColor}
                                onReturn={handleReturn}
                                oSetRemainingAttempts={setRemainingAttempts}
                                shouldRefresh={questionToken}
                                onSubmit={handleSubmit}
                                onSetDeviceInfo={onSetDeviceInfo}
                                onAddMessageToToaster={onAddMessageToToaster}
                                textFieldChild={getTexFields}
                                recording={false}
                            />
                        </div>
                    )}
                    <VideoModal
                        open={openModal}
                        hideBottomBorder
                        //closeText="close"
                        hideCloseBtn
                        headerClass="pb-0"
                        bodyClass="pt-0"
                        isLarge
                        onCancel={() => setOpenModal(false)}
                    >
                        {(imgURL || videoURL) && (
                            <div className="mb-3 w-100 px-4  text-center mt-4">
                                {videoURL && (
                                    <video
                                        src={videoURL}
                                        className="w-100"
                                        disablePictureInPicture
                                        playsInline
                                        autoplay
                                        controls
                                        controlsList="nodownload"
                                    />
                                )}
                                {imgURL && <img src={imgURL} className="img-fluid" alt="question_guide_img"/>}
                                <Button
                                    name="record_answer"
                                    icon={getRecordImage()}
                                    value={type === "video" ? "Record Answer" : "Type Answer"}
                                    variation="btn btn-primary px-3 btn-sm mx-2 mt-3"
                                    onSubmit={() => setOpenModal(false)}
                                />
                            </div>
                        )}
                    </VideoModal>
                </div>
            )}
        </VideoResumeCard>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        interviewAnswerAction: (data, callback) => dispatch(interviewAnswer(data, callback)),
        interviewVideoResumeAction: (data, callback) => dispatch(interviewVideoResume(data, callback)),
    }
}
export default withRouter(connect(null, mapDispatchToProps)(InterviewQuestion))
