import React, { Fragment, useContext, useState } from "react"
/// React router dom
import { Link } from "react-router-dom"
import { ThemeContext } from "../../../context/ThemeContext"
//import LogoDark from "../../../images/logo-dark.svg"
import LogoLight from "../../../images/logo-light.svg"

const NavHader = () => {
  const [toggle, setToggle] = useState(false)
  const { navigationHader, openMenuToggle, background } = useContext(ThemeContext)
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img src={LogoLight} className="brand-title w-100" />
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </Link>

      {/* <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle)
          openMenuToggle()
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div> */}
      
      <div
        class="nav-control"
        style={{fontSize: '16px'}}
        onClick={() => {
            console.log("Toggle Value: ", toggle)
            setToggle(!toggle);
            openMenuToggle();
        }}
      >
          <i class="fa fa-fw fa-bars"></i>
      </div>
    </div>
  )
}

export default NavHader
