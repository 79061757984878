import React, {useEffect, useRef, useState, useCallback, Fragment} from "react"
import {useHistory} from "react-router-dom"
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import {ToastContainer} from "react-toastify"
import {getAllInterviews, markInterviewConsentAction} from "../../../redux/actions/ProfileActions"
import {useLocation, withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import {getUserDataSelector} from "../../../redux/selectors/ProfileSelector"
import {ReactComponent as PersonIcon} from "../../../assets/images/person.svg"
import {ReactComponent as GrobalIcon} from "../../../assets/images/global.svg"
import {ReactComponent as FacebookIcon} from "../../../assets/images/facebook.svg"
import {ReactComponent as YoutubeIcon} from "../../../assets/images/youtube.svg"
import {ReactComponent as LinkedInIcon} from "../../../assets/images/linkedin.svg"
import {ReactComponent as TwitterIcon} from "../../../assets/images/twitter.svg"
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta.svg"
import {ReactComponent as StopwatchIcon} from "../../../assets/images/stopwatch.svg"
import {ReactComponent as QuestionIcon} from "../../../assets/images/question.svg"
import {ReactComponent as WebCamIcon} from "../../../assets/images/webcam.svg"
import {ReactComponent as FrameIcon} from "../../../assets/images/frame.svg"
import Ad from "../../common/Ad/Ad"
import Button from "../../common/Button/Button"
import {isMobile} from "../../../helpers"
import Modal from "../../common/Modal/Modal"
import {Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle} from "reactstrap";
import LogoDark from "../../../images/logo-dark.svg"
import LogoLight from "../../../images/logo-light.svg"

const AddInterview = (props) => {
    const {userData, markInterviewConsent} = props
    const socialRef = useRef(null)
    const [modal, setModal] = useState(true)
    const history = useHistory();
    const location = useLocation();
    const data = location.state;
    let estTime
    estTime = data?.Position?.Question.reduce((acc, {time_allowed}) => acc + time_allowed, 0)
    console.log({data});
    const handleNavigate = () => {
        history.push("/dashboard")
    }

    useEffect(() => {

        const pos_id = String(props?.location?.pathname).split("/interview/")[1]
        localStorage.setItem("position_id", pos_id)
        console.log("Position Id in add interview:", pos_id)
        history.push("/register")


    }, [])


    return (
        <Fragment>
            <h5>If not automatically redirected <Link to={"/dashboard"}>click here</Link></h5>
        </Fragment>

    )
}

const mapStateToProps = (state) => {
    return {
        userData: getUserDataSelector(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllInterviewsAction: (data) => dispatch(getAllInterviews(data)),
        markInterviewConsent: (data, props, state) => dispatch(markInterviewConsentAction(data, props, state))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddInterview))
