import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
// image
// import logo from "../../images/logo-full.png";
import logo from "../../images/logo-dark.svg"
import {connect} from "react-redux";
import Loader from "../common/Loader"
import {userForgotPasswordAction} from "../../redux/actions/AuthActions";
import {toast, ToastContainer} from "react-toastify";

const ForgotPassword = (props) => {
    const {userForgotPassword} = props

    const [email, setEmail] = useState()
    const [loader, setLoader] = useState()
    const onSubmit = (e) => {
        e.preventDefault();
        setLoader(true)
        const d = {
            email
        }
        userForgotPassword(d, props, (res) => {
            if (res) {
                toast.success("Temporary password dispatched to your email address", {autoClose: 2000})
                setLoader(false)
                setTimeout(function (props) {
                    props.history.push("/")
                }, 2000, props);
                // setTimeout(null, 2000);
                // props.history.push("/")
            }
        })
        // history.push("/dashboard");
    };
    return (

        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <ToastContainer/>
                {" "}
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <Link to="/dashboard">
                                                <img src={logo} style={{maxWidth: "300px"}} alt=""/>
                                            </Link>
                                        </div>
                                        <h4 className="text-center mb-4 ">Forgot Password</h4>
                                        <form onSubmit={(e) => {
                                        }}>
                                            <div className="form-group">
                                                <label className="">
                                                    <strong>Email</strong>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="hello@example.com"
                                                    value={email}
                                                    onChange={(e) => {
                                                        setEmail(e?.target?.value)
                                                    }}
                                                />
                                            </div>
                                            <p style={{
                                                fontSize: "12px",
                                                lineHeight: "1.5",
                                                marginTop: "-7px"
                                            }}>Temporary password email will be dispatched. you can use that password to
                                                login</p>
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    onClick={onSubmit}
                                                >
                                                    {
                                                        loader ? <Loader spinnerClass="text-white"/> : "Submit"
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProp = (dispatch) => {
    return {
        userForgotPassword: (data, prop, cb) => dispatch(userForgotPasswordAction(data, prop.history, cb))
    }
}

export default withRouter(connect(null, mapDispatchToProp)(ForgotPassword));
