import axios from "axios"
import store from "../redux/store/Store"

const axiosInstance = axios.create({
    // baseURL: `http://localhost:4000`
    baseURL: `https://api.digitalhire.com/`,
})

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState()
    const token = localStorage.getItem("dataSave")
        ? JSON.parse(localStorage.getItem("dataSave")).token
        : state.AuthReducers.AuthData.token
    config.params = config.params || {}
    config.headers = {
        Authorization: `Bearer ${token}`,
    }
    return config
})

export default axiosInstance



