import React, {useState, useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {useDispatch, useSelector} from "react-redux"
import {postSignUp} from "../../redux/actions/AuthActions"
import Loader from "../common/Loader"
// image
import LogoDark from "../../images/logo-dark.svg"

import "./Registration.css"
import {addNewInterview} from "../../redux/actions/ProfileActions";

const Register = ({history}) => {
    const dispatch = useDispatch()
    const navigate = useHistory()
    const [phoneNum, setPhoneNum] = useState("")
    const [errors, setErrors] = useState("")
    const signUpData = useSelector((state) => state.AuthReducers.SignUpData)
    const SignUpLoading = useSelector((state) => state.AuthReducers.SignUpLoading)
    const handlePhoneChange = (phone) => {
        setPhoneNum(phone)
    }
    const submitHandler = (e) => {
        let formData = {
            first_name: e.target[0].value,
            last_name: e.target[1].value,
            phone: phoneNum,
            password: e.target[4].value,
            email: e.target[2].value,
            type: "candidate",
        }
        if (!!phoneNum)
            dispatch(
                postSignUp(formData, (res) => {
                    if (res?.error) {
                        setErrors(res.error)
                    } else {
                        const p_id = localStorage.getItem("position_id")
                        // console.log("Position id in dashboard: ", p_id)
                        if (p_id && p_id !== "" && p_id !== " ") {
                            // console.log("called 1")
                            dispatch(addNewInterview(p_id))
                            localStorage.setItem("position_id", "")
                        }
                    }
                })
            )
        e.preventDefault()
        // history.push("/");
    }
    useEffect(() => {
        if (signUpData?.token) {
            sessionStorage.setItem("tempToken", signUpData.token)
            history.push("/verify")
        }
    }, [signUpData])
    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <Link to="/">
                                                <img src={LogoDark} alt=""/>
                                            </Link>
                                        </div>
                                        <h4 className="text-center mb-4 ">Sign up your account</h4>
                                        <form onSubmit={(e) => submitHandler(e)}>
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <strong>First Name</strong>
                                                </label>
                                                <input type="text" className="form-control" placeholder="First Name"
                                                       required/>
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <strong>Last Name</strong>
                                                </label>
                                                <input type="text" className="form-control" placeholder="Last Name"
                                                       required/>
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <strong>Email</strong>
                                                </label>
                                                <input type="email" className="form-control"
                                                       placeholder="user@example.com" required/>
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <strong>Phone</strong>
                                                </label>
                                                <PhoneInput country={"us"} value={phoneNum}
                                                            onChange={(phone) => handlePhoneChange(phone)}/>
                                                {!!phoneNum ? "" : "Phone number is required"}
                                            </div>
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <strong>Password</strong>
                                                </label>
                                                <input type="password" className="form-control" required/>
                                            </div>
                                            {errors && <div
                                                className="animated fadeInUp text-danger fs-15 text-center"> {errors}</div>}
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block"
                                                        disabled={SignUpLoading}>
                                                    {SignUpLoading ? <Loader spinnerClass="text-white"/> : "Sign me up"}
                                                </button>
                                            </div>
                                        </form>
                                        <div className="new-account mt-3">
                                            <p className="">
                                                Already have an account?{" "}
                                                <Link className="text-primary" to="/">
                                                    Sign in
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
