export function formatError(errorResponse) {
  const error = errorResponse?.error
    ? errorResponse.error
    : errorResponse?.errors
    ? errorResponse.errors
    : errorResponse
  if (Array.isArray(error)) {
    for (let i = 0; error.length; i++) {
      return error[i]
    }
  } else {
    return error
  }
}
