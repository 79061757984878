// 3rd party
import React, {useEffect, useRef} from "react"
import classnames from "classnames"
import {Link} from "react-router-dom"

// lib
import Card from "../../common/Card/Card"
import {ReactComponent as DigitalHireIcon} from "../../../images/digital-hire.svg"
import {ReactComponent as Help} from "../../../images/svg/help.svg"
import {ModalTitle} from "react-bootstrap"

const VideoResumeCard = ({
                             title,
                             subtitle,
                             progress = "0%",
                             helpCenter,
                             imageUrl,
                             imgClass,
                             progressColor,
                             companyName,
                             interview_data,
                             children,
                         }) => {
    const progressRef = useRef(null)

    return (
        <div className="video-progress w-100 mt-4">
            <Link className="d-flex justify-content-end mb-2" to={"/dashboard"}>
                <small>Return to Dashboard</small>
            </Link>
            <div
                ref={progressRef}
                className="progress flat-bottom"
                style={{
                    backgroundColor: "rgb(255,255,255)",
                    borderRadius: "none",
                    borderBottomLeftRadius: "3px",
                    borderBottomRightRadius: "3px",
                    height: "10px",
                }}
            >
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{width: progress ? progress : "0%", backgroundColor: progressColor}}
                    aria-valuenow={progress.charAt(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                />
            </div>
            <Card className="w-100 px-3 pt-3">
                <div className="justify-content-between d-flex align-items-center p-2">
                    <div className="d-flex align-items-center">
                        {!imageUrl && <DigitalHireIcon width="90" height="90"/>}
                        {imageUrl && (
                            <img
                                className={classnames("image-responsive", imgClass)}
                                height="90"
                                width="90"
                                alt="company_image"
                                src={imageUrl}
                            />
                        )}
                        <div className="ml-2">
                            <p className="mb-1" style={{fontSize: "15px"}}>
                                {companyName ? `${title} at ${companyName}` : title}
                            </p>
                            {
                                !interview_data?.is_complete ? (
                                    <p className="mb-1 subtitle font-weight-normal">{interview_data?.Position?.intro_message}</p>
                                ) : (
                                    <p className="mb-1 subtitle font-weight-normal">{interview_data?.Position?.outro_message}</p>
                                )
                            }

                        </div>
                    </div>
                    {helpCenter && (
                        <div className="d-md-block d-none">
                            <div className="d-flex align-items-center">
                                <Help width="15" height="15"/>
                                <p className="mb-0 ml-2" style={{color: "#02a54a"}}>
                                    <small>
                                        <a
                                            href="https://digitalhire.com/faqs/"
                                            target="_blank"
                                            rel="noreferrer"
                                            style={{color: "#02a54a"}}
                                        >
                                            Help Center
                                        </a>
                                    </small>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="dropdown-divider divider mx-4"/>
                {children}
            </Card>
            <p className="d-flex justify-content-center my-3 mb-1 powered-by">
                <small>Powered by DigitalHire</small>
            </p>
        </div>
    )
}

export default VideoResumeCard
