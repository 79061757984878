export const SET_PROFILE_PASSWORD_API_REQUEST = "SET_PROFILE_PASSWORD_API_REQUEST"
export const SET_PROFILE_PASSWORD_API_SUCCESS = "SET_PROFILE_PASSWORD_API_SUCCESS"
export const SET_PROFILE_PASSWORD_API_FAILURE = "SET_PROFILE_PASSWORD_API_FAILURE"

export const SET_DEACTIVATE_ACCOUNT_API_REQUEST = "SET_DEACTIVATE_ACCOUNT_API_REQUEST"
export const SET_DEACTIVATE_ACCOUNT_API_SUCCESS = "SET_DEACTIVATE_ACCOUNT_API_SUCCESS"
export const SET_DEACTIVATE_ACCOUNT_API_FAILURE = "SET_DEACTIVATE_ACCOUNT_API_FAILURE"

export const POST_AWS_MEDIA_API_REQUEST = "POST_AWS_MEDIA_API_REQUEST"
export const POST_AWS_MEDIA_API_SUCCESS = "POST_AWS_MEDIA_API_SUCCESS"
export const POST_AWS_MEDIA_API_FAILURE = "POST_AWS_MEDIA_API_FAILURE"

export const GET_PROFILE_DETAILS_API_REQUEST = "GET_PROFILE_DETAILS_API_REQUEST"
export const GET_PROFILE_DETAILS_API_SUCCESS = "GET_PROFILE_DETAILS_API_SUCCESS"
export const GET_PROFILE_DETAILS_API_FAILURE = "GET_PROFILE_DETAILS_API_FAILURE"

export const POST_ADD_INTERVIEW_API_REQUEST = "POST_ADD_INTERVIEW_API_REQUEST"
export const POST_ADD_INTERVIEW_API_SUCCESS = "POST_ADD_INTERVIEW_API_SUCCESS"
export const POST_ADD_INTERVIEW_API_FAILURE = "POST_ADD_INTERVIEW_API_FAILURE"

export const GET_ALL_INTERVIEWS_API_REQUEST = "GET_ALL_INTERVIEWS_API_REQUEST"
export const GET_ALL_INTERVIEWS_API_SUCCESS = "GET_ALL_INTERVIEWS_API_SUCCESS"
export const GET_ALL_INTERVIEWS_API_FAILURE = "GET_ALL_INTERVIEWS_API_FAILURE"

export const UPDATE_PROFILE_DETAILS_API_REQUEST = "UPDATE_PROFILE_DETAILS_API_REQUEST"
export const UPDATE_PROFILE_DETAILS_API_SUCCESS = "UPDATE_PROFILE_DETAILS_API_SUCCESS"
export const UPDATE_PROFILE_DETAILS_API_FAILURE = "UPDATE_PROFILE_DETAILS_API_FAILURE"

export const SET_PROFILE_VIDEORESUME_API_REQUEST = "SET_PROFILE_VIDEORESUME_API_REQUEST"
export const SET_PROFILE_VIDEORESUME_API_SUCCESS = "SET_PROFILE_VIDEORESUME_API_SUCCESS"
export const SET_PROFILE_VIDEORESUME_API_FAILURE = "SET_PROFILE_VIDEORESUME_API_FAILURE"

export const GET_PROFILE_VIDEORESUME_API_REQUEST = "GET_PROFILE_VIDEORESUME_API_REQUEST"
export const GET_PROFILE_VIDEORESUME_API_SUCCESS = "GET_PROFILE_VIDEORESUME_API_SUCCESS"
export const GET_PROFILE_VIDEORESUME_API_FAILURE = "GET_PROFILE_VIDEORESUME_API_FAILURE"

export const SET_PROFILE_PDFRESUME_API_FAILURE = " SET_PROFILE_PDFRESUME_API_FAILURE"
export const SET_PROFILE_PDFRESUME_API_REQUEST = "SET_PROFILE_PDFRESUME_API_REQUEST"
export const SET_PROFILE_PDFRESUME_API_SUCCESS = "SET_PROFILE_PDFRESUME_API_SUCCESS"

export const GET_PROFILE_PDFRESUME_API_REQUEST = "GET_PROFILE_PDFRESUME_API_REQUEST"
export const GET_PROFILE_PDFRESUME_API_SUCCESS = "GET_PROFILE_PDFRESUME_API_SUCCESS"
export const GET_PROFILE_PDFRESUME_API_FAILURE = "GET_PROFILE_PDFRESUME_API_FAILURE"

export const SET_INTERVIEW_VIDEO_RESUME_API_REQUEST = "SET_INTERVIEW_VIDEO_RESUME_API_REQUEST"
export const SET_INTERVIEW_VIDEO_RESUME_API_SUCCESS = "SET_INTERVIEW_VIDEO_RESUME_API_SUCCESS"
export const SET_INTERVIEW_VIDEO_RESUME_API_FAILURE = "SET_INTERVIEW_VIDEO_RESUME_API_FAILURE"

export const LOCAL_PROFILE_DP_REQUEST = "LOCAL_PROFILE_DP_REQUEST"
export const GET_ALL_INTERVIEWS_STATS_API_REQUEST = "GET_ALL_INTERVIEWS_STATS_API_REQUEST"
export const GET_ALL_INTERVIEWS_STATS_API_SUCCESS = "GET_ALL_INTERVIEWS_STATS_API_SUCCESS"
export const GET_ALL_INTERVIEWS_STATS_API_FAILURE = "GET_ALL_INTERVIEWS_STATS_API_FAILURE"

export const MARK_INTERVIEW_CONSENT_API_SUCCESS = "MARK_INTERVIEW_CONSENT_API_SUCCESS"
export const MARK_INTERVIEW_CONSENT_API_FAILURE = "MARK_INTERVIEW_CONSENT_API_FAILURE"
export const NEW_PROFILE_IMAGE_UPLOAD = "NEW_PROFILE_IMAGE_UPLOAD"

export const UPDATE_INTERVIEW_VIDEO_RESUME = "UPDATE_INTERVIEW_VIDEO_RESUME"

