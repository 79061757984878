import React, {useState, useCallback} from "react"
import {Link} from "react-router-dom"
import {ReactComponent as Tick} from "../../../images/tick.svg"
import {ReactComponent as GlobalIcon} from "../../../assets/images/global.svg"
import {ReactComponent as FacebookIcon} from "../../../assets/images/facebook.svg"
import {ReactComponent as YoutubeIcon} from "../../../assets/images/youtube.svg"
import {ReactComponent as LinkedInIcon} from "../../../assets/images/linkedin.svg"
import {ReactComponent as TwitterIcon} from "../../../assets/images/twitter.svg"
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta.svg"
import avatar from "../../../images/svg/default.svg"
import {Dropdown} from "react-bootstrap"

const renderStatus = (status) => {
    if (status === 'completed') return 'Completed'
    if (status === 'reviewed') return 'Evaluated'
    if (status === 'startedInterview' || status === 'started_interview') return 'Started Interview'
    if (status === 'invited') return 'Invited'
    if (status === 'clickedInvite' || status === 'clicked_invite') return 'Clicked Invite'
    if (status === 'hired') return 'Hired'
    return status
}

function Interview(props) {
    let estTime
    estTime = props?.data?.Position?.Question.reduce((acc, {time_allowed}) => acc + time_allowed, 0)
    let eT =
        !!props.data.Position && estTime >= 1.0
            ? `${estTime} Minutes`
            : estTime <= 1.0
                ? `${(estTime * 60).toFixed(2)} Seconds`
                : "Attempted"
    let totalQuestions = props?.data?.Position?.Question?.length
    const alreadyAttempted = props?.data?.Position?.Question?.map((value) => value.Answer.length > 0)
    const isComplete = alreadyAttempted?.filter(Boolean)
    const progressBar = (isComplete?.length * 100) / totalQuestions
    const companyLogo = props?.data?.Company?.logo || avatar
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-5 col-12 d-flex w-100 mb-3 pr-2 align-items-center">
                            <img alt="company_logo" src={companyLogo}
                                 className="img-fluid rounded-circle company-logo"/>
                            <div className="mw-0 mx-3 mt-1 w-100">
                                <div className="d-sm-flex align-items-center">
                                    <p className="mb-0 body-title text-secondary text-truncate">{props?.data?.Position?.title}</p>
                                    {props?.data?.progress === props?.data?.Position?.Question?.length || progressBar === 100 ? (
                                        <p className="mb-0 mini badge ml-md-3 badge-primary">{renderStatus(props?.data?.status)}</p>
                                    ) : props?.data?.is_reviewed ? (
                                        <p className="mb-0 mini badge ml-md-3 badge-info">{renderStatus(props?.data?.status)}</p>
                                    ) : (
                                        <p className=" mb-0 mini ml-md-3 badge badge-danger">{renderStatus(props?.data?.status)}</p>
                                    )}
                                </div>
                                <p className="mb-0 subtitle">
                                    <small>{props?.data?.Company?.name}</small>
                                </p>
                                <p className="mb-1 subtitle">
                                    <small>{props?.data?.Position?.location}</small>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12 d-flex w-100 justify-content-between">
                            <div className="d-flex d-lg-flex-border flex-column flex-even py-2 pr-2 pl-md-3">
                                <p className="font-weight-500 text-secondary">Questions</p>
                                <div className="w-100 d-flex align-items-center">
                                    <div className="progress mh-100 w-50">
                                        <div
                                            className="progress-bar"
                                            style={{
                                                width: `${progressBar}%`,
                                            }}
                                        />
                                    </div>
                                    <p className="ml-2 mb-0">{`${isComplete?.length}/${totalQuestions}`}</p>
                                </div>
                            </div>
                            <div className="d-flex d-flex-border flex-column flex-even p-2 pl-3">
                                <p className="font-weight-500 text-secondary">Estimated Time</p>
                                <p>{eT}</p>
                            </div>
                            <div className="d-flex d-flex-border flex-column flex-even p-2 pl-3">
                                <p className="font-weight-500 text-secondary text-truncate">Location</p>
                                <p className="small body-title-color font-weight-normal">{props?.data?.Position?.location}</p>
                            </div>
                        </div>
                        <div
                            className="col-lg-2 col-12 justify-content-center align-items-center d-flex flex-column w-auto">
                            {props?.data?.progress === props?.data?.Position?.Question?.length || progressBar === 100 ? (
                                <Tick className="text-primary"/>
                            ) : (
                                <Link
                                    to={{
                                        pathname: "/interview/start",
                                        state: {...props.data, interviewResume: props.video_resume},
                                    }}
                                >
                                    {props?.data?.progress > 0 && props?.data?.progress < props?.data?.Position?.Question?.length ? (
                                        <button className="btn btn-primary btn-sm mini mb-2">Resume</button>
                                    ) : (
                                        <button className="btn btn-primary btn-sm mini mb-2">Start Interview</button>
                                    )}
                                </Link>
                            )}
                            <Dropdown className="dropdown">
                                <Dropdown.Toggle variant="btn btn-link dark-grey-color mini mb-0" id="dropdown-basic">
                                    View Detail
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu" style={{width: "300px", height: "auto"}}>
                                    <div style={{padding: "10px"}}>
                                        <div>
                                            <p className="body-title font-weight-500 mb-1">{props?.data?.Position?.title}</p>
                                            <p className="small">{props?.data?.Company?.name}</p>
                                        </div>
                                        <div className="d-flex mb-3 justify-content-between">
                                            {props?.data?.Company?.social_profiles && (
                                                <>
                                                    <GlobalIcon/>
                                                    <TwitterIcon/>
                                                    <FacebookIcon/>
                                                    <LinkedInIcon/>
                                                    <YoutubeIcon/>
                                                    <InstagramIcon/>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            <p className="font-weight-500 mb-1">Details</p>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between">
                                            <p>Status</p>
                                            <p>
                                                {props?.data?.progress === props?.data?.Position?.Question?.length || progressBar === 100
                                                    ? "Completed"
                                                    : props?.data?.is_reviewed
                                                        ? "Under Review"
                                                        : "Pending"}
                                            </p>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between">
                                            <p>Location</p>
                                            <p>{props?.data?.Position?.location}</p>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between">
                                            <p>Questions</p>
                                            <p>{props?.data?.Position?.Question?.length}</p>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between">
                                            <p>Invite Date</p>
                                            <p>{props?.data?.start_date?.substr(0, props?.data?.start_date?.indexOf("T"))}</p>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between">
                                            <p>Completion Date</p>
                                            <p>{props?.data?.completion_date?.substr(0, props?.data?.completion_date?.indexOf("T"))}</p>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                        <div className="d-flex justify-content-between">
                                            <p>Estimated Time</p>
                                            <p>{eT}</p>
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Interview
