// 3rd party
import React, { memo } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

const TextField = ({
  label,
  name,
  value,
  type,
  error,
  hidden,
  disabled,
  required,
  className,
  placeholder,
  autoCompleteOff,
  labelClassName,
  handleChange,
}) => {
  return (
    <div className={classnames("form-group", { "d-none": hidden })}>
      {(label || required) && (
        <label className={labelClassName} htmlFor={name}>
          {label}
          {required && <span className="text-muted ml-2">Required</span>}
        </label>
      )}
      <input
        className={classnames("form-control", className, {
          "is-invalid": error,
          disabled,
        })}
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        autoComplete={autoCompleteOff ? "off" : "on"}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  )
}

TextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
  error: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  autoCompleteOff: PropTypes.bool,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
}

TextField.defaultProps = {
  required: false,
  error: "",
  className: "",
  labelClassName: "",
  placeholder: "",
  hidden: false,
  disabled: false,
  autoCompleteOff: false,
  handleChange: () => undefined,
}

export default memo(TextField)
