import {
    SET_AUTH_API_REQUEST,
    SET_AUTH_API_SUCCESS,
    SET_AUTH_API_FAILURE,
    SET_SIGNUP_API_REQUEST,
    SET_SIGNUP_API_SUCCESS,
    SET_SIGNUP_API_FAILURE,
    SET_SIGNUP_VERIFY_API_REQUEST,
    SET_SIGNUP_VERIFY_API_SUCCESS,
    SET_SIGNUP_VERIFY_API_FAILURE,
    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_COMPLETED,
    USER_FORGOT_PASSWORD_FAILED, USER_RESEND_PIN_COMPLETED, USER_RESEND_PIN_FAILED, USER_RESEND_PIN_REQUEST,
} from "../constants/AuthConstants"

import {
    loginService,
    signUpService,
    signUpVerifyService,
    userForgotPasswordService,
    userResendPinService
} from "../../services/AuthService"
import {formatError} from "../../services/FormatError"
import {toast} from "react-toastify"

const setAuthData = (dataForm, callback, history) => {
    return (dispatch) => {
        dispatch(loginRequestAction())
        loginService(dataForm)
            .then((response) => {
                dispatch(loginSuccessAction(response.data))
                if (response.data.token) {
                    if (response.data.expiresIn) {
                        setTimeout(() => {
                            dispatch(logOutAuthData())
                        }, response.data.expiresIn * 1000)
                    }
                    history.push("/dashboard")
                }
                localStorage.setItem("dataSave", JSON.stringify(response.data))
                callback(response.data)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                callback({
                    error: error.response.status === 500 ? "Error, Please try again later!" : errorMessage,
                })
                dispatch(loginFailedAction(errorMessage))
            })
    }
}

const postSignUp = (dataForm, callback) => {
    return (dispatch) => {
        dispatch(signUpRequestAction())
        signUpService(dataForm)
            .then((response) => {
                dispatch(signUpSuccessAction(response.data))
                localStorage.setItem("dataSave", JSON.stringify(response.data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                callback({
                    error: error.response.status === 500 ? "Error, Please try again later!" : errorMessage,
                })
                dispatch(signUpFailedAction(errorMessage))
                toast.error(errorMessage, {autoClose: 2000})
            })
    }
}

const postVerify = (dataForm) => {
    return (dispatch) => {
        dispatch(signUpVerifyRequestAction())
        signUpVerifyService(dataForm)
            .then((response) => {
                dispatch(signUpVerifySuccessAction(response.data.message))
                toast.success(response.data.message, {autoClose: 2000})
                window.location.href = "/"
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(signUpVerifyFailedAction(errorMessage))
                toast.error(errorMessage, {autoClose: 2000})
            })
    }
}

const logOutAuthData = () => async (dispatch) => {
    dispatch({
        type: SET_AUTH_API_REQUEST,
    })
    try {
        localStorage.removeItem("dataSave")
        window.location.href = "/"
        dispatch({type: SET_AUTH_API_SUCCESS, payload: {}})
    } catch (error) {
        dispatch({type: SET_AUTH_API_FAILURE, payload: error.message})
    }
}


export function userForgotPasswordAction(data, history, cb) {
    return (dispatch) => {
        dispatch(userForgotPasswordRequestAction())
        userForgotPasswordService(data)
            .then((response) => {
                dispatch(userForgotPasswordCompletedAction())

                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userForgotPasswordFailedAction())
                if (cb) cb(false)
            })
    }
}

export function userResendPinAction(cb) {
    return (dispatch) => {
        dispatch(userResendPinRequestAction())
        userResendPinService()
            .then((response) => {
                dispatch(userResendPinCompletedAction())
                toast.success("Verification pin sent to your email", {autoClose: 20000})
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(userResendPinFailedAction())
                if (cb) cb(false)
            })
    }
}

export function userForgotPasswordRequestAction() {
    return {
        type: USER_FORGOT_PASSWORD_REQUEST
    }
}

export function userForgotPasswordCompletedAction() {
    return {
        type: USER_FORGOT_PASSWORD_COMPLETED
    }
}

export function userForgotPasswordFailedAction(data) {
    return {
        type: USER_FORGOT_PASSWORD_FAILED,
        payload: data
    }
}

export function userResendPinRequestAction() {
    return {
        type: USER_RESEND_PIN_REQUEST
    }
}

export function userResendPinCompletedAction() {
    return {
        type: USER_RESEND_PIN_COMPLETED
    }
}

export function userResendPinFailedAction(data) {
    return {
        type: USER_RESEND_PIN_FAILED,
        payload: data
    }
}


export const loginRequestAction = () => {
    return {
        type: SET_AUTH_API_REQUEST,
    }
}

export const loginSuccessAction = (data) => {
    return {
        type: SET_AUTH_API_SUCCESS,
        payload: data,
    }
}

export const loginFailedAction = (data) => {
    return {
        type: SET_AUTH_API_FAILURE,
        payload: data,
    }
}
export const signUpRequestAction = () => {
    return {
        type: SET_SIGNUP_API_REQUEST,
    }
}

export const signUpSuccessAction = (data) => {
    return {
        type: SET_SIGNUP_API_SUCCESS,
        payload: data,
    }
}

export const signUpFailedAction = (data) => {
    return {
        type: SET_SIGNUP_API_FAILURE,
        payload: data,
    }
}
export const signUpVerifyRequestAction = () => {
    return {
        type: SET_SIGNUP_VERIFY_API_REQUEST,
    }
}

export const signUpVerifySuccessAction = (data) => {
    return {
        type: SET_SIGNUP_VERIFY_API_SUCCESS,
        payload: data,
    }
}

export const signUpVerifyFailedAction = (data) => {
    return {
        type: SET_SIGNUP_VERIFY_API_FAILURE,
        payload: data,
    }
}
export {setAuthData, postSignUp, postVerify, logOutAuthData}
