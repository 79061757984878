// 3rd party
import React from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
// lib
import "./style.scss"
import Button from "../../common/Button/Button"
import { ReactComponent as GoogleIcon } from "../../../assets/images/google-icon.svg"
import { useDispatch } from "react-redux"
import { getProfileData } from "../../../redux/actions/ProfileActions"

const GoogleButton = ({ positionId }) => {
  let { token } = useParams()
  const dispatch = useDispatch()
  if (!!token) {
    const social = true
    dispatch(getProfileData(token, social))
    return null
  }
  const handleSubmit = () => {
    window.location.href = `${process.env.REACT_APP_BASE_API}/candidate/google`
  }
  // positionId
  //   ? (window.location.href = `${process.env.REACT_APP_BASE_API}/candidates/google?positionId=${positionId}`)
  // : (window.location.href = `${process.env.REACT_APP_BASE_API}/candidates/google`)

  return (
    <div className="d-flex align-items-center google-icon mb-2">
      <GoogleIcon width="20" height="20" className="mx-2" />
      <Button value="Continue with Google" variation="primary btn-sm btn-block btn-google" onSubmit={handleSubmit} />
    </div>
  )
}

GoogleButton.propTypes = {
  positionId: PropTypes.string,
}

GoogleButton.defaultProps = {
  positionId: "",
}

export default React.memo(GoogleButton)
