// 3rd party
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Card = ({ bodyClass, cardClass, children }) => {
  return (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-center",
        cardClass
      )}
    >
      <div className="card shadow-card w-100">
        <div className={classnames("card-body px-3 pt-3", bodyClass)}>
          {children}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  cardClass: PropTypes.string,
  bodyClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  cardClass: "",
  bodyClass: "",
};

export default Card;
