// 3rd party
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

// lib
import "./style.scss"

const Loader = ({ overlay, loadingText, loaderClass, spinnerClass, disableFullscreen, centerScreen }) => {
  return (
    <div
      className={classnames(loaderClass, "center-content", {
        "fullscreen-loader": centerScreen,
        overlay,
      })}
    >
      <div>
        <div className={classnames("spinner-border loader-priority", spinnerClass)} role="status">
          <span className="sr-only">Loading...</span>
        </div>
        {loadingText && <p>{loadingText}</p>}
      </div>
      {disableFullscreen && <div className="fullscreen-loader" />}
    </div>
  )
}

Loader.propTypes = {
  loadingText: PropTypes.string,
  loaderClass: PropTypes.string,
  spinnerClass: PropTypes.string,
  overlay: PropTypes.bool,
  centerScreen: PropTypes.bool,
  disableFullscreen: PropTypes.bool,
}

Loader.defaultProps = {
  overlay: false,
  loaderClass: "",
  spinnerClass: "",
  loadingText: "",
  centerScreen: false,
  disableFullscreen: false,
}

export default Loader
