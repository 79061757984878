import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Media, ModalBody, ModalFooter, Row } from "react-bootstrap"

//Image Cropper
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"

const ImageCropModal = ({ toggle, img, handleCropSubmit }) => {
  const cropperRef = useRef(null)

  function dataURItoBlob(dataURI) {
    var arr = dataURI.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return { blob: new Blob([u8arr], { type: mime }), type: mime }
  }

  const onSubmit = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const { blob, type } = dataURItoBlob(cropper.getCroppedCanvas().toDataURL())
    const resultFile = new File([blob], "newfile", { type: type })
    handleCropSubmit(resultFile)
  }

  return (
    <ModalBody>
      <Row className="justify-content-end">
        <Col lg={3}>
          <button className="float-right btn btn-link-secondary" onClick={() => toggle()}>
            close
          </button>
        </Col>
      </Row>
      {/* <h4 className="card-title mb-4">Example</h4> */}
      <Row>
        <Col xl="12">
          <div className="img-container mb-2">
            <Cropper
              src={img ? img : "No Image Found!"}
              style={{ height: 400, width: "100%" }}
              initialAspectRatio={1 / 1}
              aspectRatio={1 / 1}
              guides={false}
              ref={cropperRef}
            />
          </div>
        </Col>
      </Row>
      <ModalFooter>
        <button className="btn btn-primary" onClick={() => onSubmit()}>
          Save
        </button>
      </ModalFooter>
    </ModalBody>
  )
}

export default ImageCropModal
