import React, {useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {postVerify, userResendPinAction} from "../../redux/actions/AuthActions"
import Loader from "../common/Loader"
// image
import LogoDark from "../../images/logo-dark.svg"
import {ToastContainer} from "react-toastify";
import {addNewInterview} from "../../redux/actions/ProfileActions";

const Verify = ({history}) => {
    const navigate = useHistory()
    const dispatch = useDispatch()
    const pinVerify = useSelector((state) => state.AuthReducers.VerifyData)
    const signUpData = useSelector((state) => state.AuthReducers.SignUpData)
    const VerifyLoading = useSelector((state) => state.AuthReducers.VerifyLoading)

    const submitHandler = (e) => {
        let pinCode = e?.target[0]?.value
        dispatch(postVerify(pinCode))
        e.preventDefault()
    }
    const submitResendPin = (e) => {
        e.preventDefault()
        dispatch(userResendPinAction())

    }
    useEffect(() => {
        const p_id = localStorage.getItem("position_id")
        console.log("Position id in dashboard: ", p_id)
        if (p_id && p_id !== "" && p_id !== " ") {
            // console.log("called 1")
            dispatch(addNewInterview(p_id))
            localStorage.setItem("position_id", "")
        }
    }, [])
    useEffect(() => {
        if (pinVerify?.message == "Accepted") {
            let verified = JSON.parse(localStorage.getItem("dataSave"))
            verified.verified = true
            localStorage.setItem("dataSave", JSON.stringify(verified))
            navigate.push("/dashboard")
        }
    }, [pinVerify])
    return (
        <div className="authincation h-100 p-meddle">
            <div className="container h-100">
                <ToastContainer/>
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <Link to="/">
                                                <img src={LogoDark} alt=""/>
                                            </Link>
                                        </div>
                                        <h4 className="text-center mb-4 ">Verify your account</h4>
                                        <form onSubmit={(e) => submitHandler(e)}>
                                            <div className="form-group">
                                                <label className="mb-1 ">
                                                    <strong>Pin Code</strong>
                                                </label>
                                                <input type="number" className="form-control" placeholder="------"/>
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block"
                                                        disabled={VerifyLoading}>
                                                    {VerifyLoading ?
                                                        <Loader spinnerClass="text-white"/> : "Verify Code"}
                                                </button>
                                            </div>
                                        </form>
                                        <form onSubmit={(e) => submitResendPin(e)}>
                                            <div className="text-center mt-4">
                                                <button type="submit"
                                                        className="btn btn-secondary btn-block waves-effect waves-light"
                                                        disabled={VerifyLoading}>
                                                    {"Resend Pin"}
                                                </button>
                                            </div>
                                        </form>
                                        <div className="new-account mt-3">
                                            <p className="">
                                                Already have an account?{" "}
                                                <Link className="text-primary" to="/">
                                                    Sign in
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Verify
