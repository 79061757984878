import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"

export default function OpenRoute({ component: Component, ...rest }) {
  const data = JSON.parse(localStorage.getItem("dataSave")) ? JSON.parse(localStorage.getItem("dataSave")) : null
  const verified = data?.verified ? data?.verified : false

  return (
    <div id={""} className={"mh100vh"}>
      <div className={""} style={{ minHeight: window.screen.height - 60 }}>
        <Route
          {...rest}
          render={(props) => {
            return !verified ? <Component {...props} /> : <Redirect to="/dashboard" />
          }}
        ></Route>
      </div>
    </div>
  )
}
