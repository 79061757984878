import React, {useState, useEffect, Fragment, useRef} from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {Modal} from "react-bootstrap"
import ImageCropModal from "./ImageCropper"
import avatar from "../../../../images/svg/default.svg"
import Loader from "../../../common/Loader"
import {updateProfileData, postMediaToAWS, getProfileData} from "../../../../redux/actions/ProfileActions"
import {getUserDataSelector, getProfileLoadingSelector} from "../../../../redux/selectors/ProfileSelector"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {toast} from "react-toastify"
import Alert from "../../../common/Alert";
import {NEW_PROFILE_IMAGE_UPLOAD} from "../../../../redux/constants/ProfileConstants";

const AppProfile = (props) => {
    const {
        userData,
        getProfileDataAction,
        postMediaToAWSAction,
        updateProfileDataAction,
        profileLoading,
        updateProfileImageAction
    } = props
    let errorsObj = {fname: "", lname: "", nEmail: "", nPhone: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [fname, setFName] = useState(userData?.first_name)
    const [lname, setLName] = useState(userData?.last_name)
    const [nEmail, setEmail] = useState(userData?.email)
    const [nPhone, setPhone] = useState(userData?.phone)
    const [img, setImg] = useState(undefined)
    let image = userData.image || ""
    const [uploadFile, setUploadFile] = useState(undefined)
    const [preImg, setPreImg] = useState(undefined)
    const [cropModal, setCropModal] = useState(false)
    const [alert, setAlert] = useState({
        alert: false,
        type: '', // 'success', // error
        variant: '', // 'success', // danger
        msg: ''
    });
    const hiddenFileInput = useRef(null)
    const toastId = useRef(null)
    useEffect(() => {
        if (Object.keys(userData).length === 0) {
            getProfileDataAction((val) => setAlert(val))
        }
        setFName(userData.first_name)
        setLName(userData.last_name)
        setEmail(userData.email)
        setPhone(userData.phone)
    }, [userData])

    const handleChange = (event) => {
        if (event.target.files[0]) {
            const fileUploaded = event.target.files[0]
            setImg(URL.createObjectURL(fileUploaded))
            setCropModal(true)
        }
    }

    const handleCropSubmit = (file) => {
        const media_key = "user/" + file.lastModified + "." + file.type.split("/")[1]
        setImg(media_key)
        setUploadFile(file)
        setPreImg(URL.createObjectURL(file))
        setCropModal(false)
    }

    function onSubmit(e) {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (fname === "") {
            errorObj.fname = "First Name is Required"
            error = true
        }
        if (lname === "") {
            errorObj.lname = "Last Name is Required"
            error = true
        }
        if (nEmail === "") {
            errorObj.nEmail = "Email is Required"
            error = true
        }
        if (nPhone === "") {
            errorObj.nPhone = "Phone Number is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        const data = {
            user_data: {
                first_name: fname,
                last_name: lname,
                // email: nEmail,
                phone: nPhone,
                image: img,
            },
        }
        if (!!img) {
            postMediaToAWSAction({key: img, file: uploadFile}, (res) => {
                if (res.config.url) {
                    toast.dismiss(toastId.current)
                    // updateProfileImageAction(uploadFile)
                    setImg(undefined)
                }

            }, (val) => setAlert(val))
            toastId.current = toast.success("Uploading ...", {autoClose: false})
        }
        updateProfileDataAction(data, (val) => setAlert(val))
        getProfileDataAction()
    }

    return (
        <Fragment>
            <Alert
                alert={alert.alert}
                type={alert.type}
                variant={alert.variant}
                msg={alert.msg}
                dismiss={() => setAlert({
                    alert: false,
                    type: 'success', // error
                    variant: 'success', // danger
                    msg: ''
                })}
            />
            <form onSubmit={onSubmit} className=" col-12 ">
                <div className="row align-items-center">
                    <div className={"col-2"} style={{maxWidth: "fit-content"}}>
                        <img src={preImg || image || avatar} className="rounded-circle avatar-mb" width={"130"}/>
                    </div>
                    <div className={"col-8"}>
                        <h3>Upload your Profile Picture</h3>
                        <small className="d-block">Maximum Size Allowed 2MB | JPEG, JPG or PNG</small>
                        <div className="custom-file my-2">
                            <input
                                type="file"
                                className="custom-file-input"
                                accept=".jpg, .jpeg, .png"
                                max-file-size="2134016"
                                ref={hiddenFileInput}
                                onChange={(e) => handleChange(e)}
                            />
                            <label className="btn btn-primary" onClick={() => hiddenFileInput.current.click()}>
                                {"Browse"}
                            </label>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className="text-label">
                                First Name{" "}
                                <span className="text-muted">
                  <small>Required</small>
                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="val-fname1"
                                placeholder="Enter a first name.."
                                onChange={(e) => setFName(e.target.value)}
                                value={fname}
                            />
                            {errors.fname && <div className="animated fadeInUp text-danger fs-12">{errors.fname}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className="text-label">
                                Last Name{" "}
                                <span className="text-muted">
                  <small>Required</small>
                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="val-lname1"
                                placeholder="Enter a last name.."
                                onChange={(e) => setLName(e.target.value)}
                                value={lname}
                            />
                            {errors.lname && <div className="animated fadeInUp text-danger fs-12">{errors.lname}</div>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className="text-label">
                                Email{" "}
                                <span className="text-muted">
                  <small>Required</small>
                </span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="val-email1"
                                placeholder="Enter a Email Address"
                                // onChange={(e) => setEmail(e.target.value)}
                                value={nEmail}
                                disabled
                            />
                            {errors.nEmail &&
                                <div className="animated fadeInUp text-danger fs-12">{errors.nEmail}</div>}
                        </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                        <div className={`form-group`}>
                            <label className="text-label">
                                Phone Number{" "}
                                <span className="text-muted">
                  <small>Required</small>
                </span>
                            </label>
                            <PhoneInput
                                country={"us"}
                                value={nPhone}
                                onChange={(phone) => setPhone(phone)}
                                placeholder="541-754-3010"
                                inputStyle={{width: "99%", height: "-webkit-fill-available", border: "hidden"}}
                                containerClass={"form-control"}
                                containerStyle={{padding: 0}}
                                buttonStyle={{borderRadius: " 10px 0 0 10px", border: "0"}}
                            />
                            {errors.nPhone &&
                                <div className="animated fadeInUp text-danger fs-12">{errors.nPhone}</div>}
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn mr-2 btn-primary">
                    {profileLoading ? <Loader spinnerClass="text-white"/> : "Save Changes"}
                </button>
            </form>
            <Modal
                centered={true}
                size="lg"
                show={cropModal}
                onHide={() => {
                    setCropModal(!cropModal)
                    setImg(undefined)
                }}
            >
                <ImageCropModal
                    handleCropSubmit={handleCropSubmit}
                    img={img}
                    toggle={() => {
                        setCropModal(!cropModal)
                        setImg(undefined)
                    }}
                />
            </Modal>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: getUserDataSelector(state),
        profileLoading: getProfileLoadingSelector(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getProfileDataAction: (cb) => dispatch(getProfileData(cb)),
        postMediaToAWSAction: (data, callback, cb) => dispatch(postMediaToAWS(data, callback, cb)),
        updateProfileDataAction: (data, cb) => dispatch(updateProfileData(data, cb)),
        updateProfileImageAction: (data) => dispatch({
            type: NEW_PROFILE_IMAGE_UPLOAD,
            payload: data
        }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppProfile))
