import React, { useState } from "react"
import { Col, Row, Modal, Button } from "react-bootstrap"
import { setDeactivateAccount } from "../../../../redux/actions/ProfileActions"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Alert from "../../../common/Alert";

const DeactivateSection = (props) => {
  const [reason, setReason] = useState("")
  const [show, setShow] = useState(false)
  const [alert, setAlert] = useState({
    alert: false,
    type: '', // 'success', // error
    variant:'', // 'success', // danger
    msg:''
  });

  const onSubmit = () => {
    props.setDeactivateAccountAction({ reason }, (val)=> setAlert(val))
  }

  return (
    <React.Fragment>
      <Alert
        alert={alert.alert}
        type={alert.type}
        variant={alert.variant}
        msg={alert.msg}
        dismiss={()=> setAlert({
          alert: false,
          type:'', // error
          variant:'', // danger
          msg:''
        })}
      />
      <Row>
        <Col lg={2}>
          <i className="fa fa-exclamation-triangle display-1 red ml-4" style={{ color: "red" }}></i>
        </Col>
        <Col lg={10}>
          <p className="mb-2">
            <b>Warning:</b> You will lose your data after 30 days and ALL team member accounts associated with this
            company will be deactivated at the end of this billing period.
            <br />
            <br />
            If this is temporary, please contact{" "}
            <a href="mailto:support@digitalhire.com" className="text-primary">
              support@digitalhire.com
            </a>
            .
          </p>
          <h6>
            Reason for Deactivation <span className="text-danger">*</span>
          </h6>
          <div className="border">
            <textarea
              style={{ width: "100%", border: "none" }}
              onChange={(e) => setReason(e.target.value.trim())}
            ></textarea>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={2}> </Col>
        <Col lg={3}>
          <button
            onClick={() => setShow(true)}
            className="btn btn-primary bg-primary btn-block"
            disabled={!reason}
            style={{ cursor: !reason ? "not-allowed" : "pointer" }}
          >
            Deactivate{" "}
          </button>
        </Col>
      </Row>
      <Modal
        centered={true}
        show={show}
        onHide={() => {
          setShow(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Account Deactivate</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            We’re sorry to see you go! If this was a mistake, please contact us at
            <span style={{ color: "green" }}> support@digitalhire.com</span>.<br></br>
            <br></br>
            Please leave us your feedback so that we can keep improving. From everyone at DigitalHire, we thank you for
            your support and partnership, and look forward to serving you again!
            <br></br>
          </p>{" "}
          <a
            rel="noopener noreferrer"
            className="btn btn-primary"
            target={"_blank"}
            href={"https://share.hsforms.com/1CYY418MATC2vCT_LsUtvEwc9og8"}
          >
            Leave Feedback
          </a>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onSubmit()
              setShow(false)
            }}
          >
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDeactivateAccountAction: (reason, cb) => dispatch(setDeactivateAccount(reason, cb)),
  }
}
export default withRouter(connect(null, mapDispatchToProps)(DeactivateSection))
