import React, { Fragment, useState } from "react"
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap"
import InfoSection from "./InfoSection"
import PasswordSection from "./PasswordSection"
import DeactivateSection from "./DeactivateSection"
import { ToastContainer } from "react-toastify"

const AppProfile = (props) => {
  const tabData = [
    {
      name: "Personal Information",
      icon: "user",
      content: <InfoSection profile={props.profile} showAlert={props.showAlert} />,
    },
    {
      name: "Change Password",
      icon: "lock",
      content: <PasswordSection showAlert={props.showAlert} />,
    },

    {
      name: "Deactivate Account",
      icon: "power-off",
      content: <DeactivateSection showAlert={props.showAlert} />,
    },
  ]
  return (
    <Fragment>
      <ToastContainer />
      <Col sm={12}>
        <Card>
          {/* <Card.Header>
          <Card.Title>Account Setting</Card.Title>
        </Card.Header> */}
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="nav-tabs justify-content-around">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        <i className={`la la-${data.icon} mr-2`} />
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content className="pt-4">
                  {tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                      {data.content}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  )
}

export default AppProfile
