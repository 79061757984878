import React from "react"
import { Provider } from "react-redux"
import store from "./redux/store/Store"

/// Components
import Markup from "./jsx"

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
import "./css/main.css"

import { withResizeDetector } from "react-resize-detector"

import ThemeContextProvider from "./context/ThemeContext"

const App = ({ width }) => {
  const body = document.querySelector("body")

  return (
    <Provider store={store}>
      <ThemeContextProvider>
        <Markup />
      </ThemeContextProvider>
    </Provider>
  )
}

export default withResizeDetector(App)
