/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
// 3rd party
import React, {useCallback, useEffect, useRef, useState} from "react"
import classnames from "classnames"
import {RecordRTCPromisesHandler, getSeekableBlob} from "recordrtc"

// lib
import "./style.scss"
import Button from "../../common/Button/Button"
import Loader from "../../common/Loader"
import {consoleLog, consoleError, windowNavigator} from "../../../helpers"
import {ReactComponent as StopWatch} from "../../../assets/images/stopwatch.svg"
import {ReactComponent as VideoGuide} from "../../../assets/images/video-guide.svg"
import {ReactComponent as VideoIcon} from "../../../assets/images/video-camera.svg"

const Recorder = ({
                      time,
                      timeLeft,
                      recording,
                      attempts,
                      isResume,
                      setUpDevice,
                      onReturn,
                      isLoading,
                      isPractice,
                      shouldRefresh,
                      returnBtnText,
                      isUploadFailed,
                      onSubmitAnswer,
                      onSetLeftTime,
                      onSetRemainingAttempts,
                      onGetCamera,
                      onSetRecording,
                      onStopRecording,
                      onSetDeviceInfo,
                      onAddMessageToToaster,
                  }) => {
    const videoRef = useRef({})
    const [stream, setStream] = useState({})
    const [recorder, setRecorder] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState("")
    const [errorLog, setErrorLog] = useState([])
    const [showGuide, setShowGuide] = useState(false)
    const [finalBlob, setFinalBlob] = useState({})
    const [camDetails, setCamDetails] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [userMediaMute, setUserMediaMute] = useState(false)
    const [stopRecordingEnabled, setStopRecordingEnabled] = useState(false)
    const [userVideoDevice, setUserVideoDevice] = useState([])
    const [userAudioDevice, setUserAudioDevice] = useState([])
    const [recording1, setRecording] = useState(recording)

    const handleReleaseResources = (mediaStream) => {
        if (mediaStream && mediaStream.id) {
            mediaStream.stop()
        }
        setStream({})
        setRecorder({})
    }

    const handleSuccess = (srObject) => {
        try {
            if (!srObject) return
            videoRef.current.srcObject = srObject
        } catch (e) {
            handleReleaseResources(srObject)
            consoleLog(e)
        }
    }

    const stopRecording = useCallback(async () => {
        try {
            setUserMediaMute(false)
            setStopRecordingEnabled(false)
            await recorder.stopRecording()
            const blob = await recorder.getBlob()
            if (!navigator.platform.toLocaleLowerCase().includes("mac")) {
                console.log("It's not mac")
                getSeekableBlob(blob, (seekableBlob) => {
                    const video = videoRef.current
                    video.src = null
                    video.controls = true
                    video.srcObject = null
                    video.src = URL.createObjectURL(seekableBlob)
                    video.onloadeddata = (event) => consoleLog(`Duration: ${event.srcElement.duration} - Seconds`)
                    onSetRecording(false)
                    setFinalBlob(seekableBlob)
                    setShowGuide(false)
                    onStopRecording(seekableBlob)
                    handleReleaseResources(stream)
                })
            } else {
                console.log("it's mac")
                const video = videoRef.current
                video.src = null
                video.controls = true
                video.srcObject = null
                video.src = URL.createObjectURL(blob)
                video.onloadeddata = (event) => consoleLog(`Duration: ${event.srcElement.duration} - Seconds`)
                onSetRecording(false)
                setFinalBlob(blob)
                setShowGuide(false)
                onStopRecording(blob)
                handleReleaseResources(stream)
            }
        } catch (e) {
            setErrorLog([...errorLog, `Stop Recording Failed: ${e.message}`])
            consoleLog(`Stop Recording Failed: ${e.message}`, e)
        }
    }, [
        stream,
        videoRef,
        recorder,
        setUserMediaMute,
        getSeekableBlob,
        setFinalBlob,
        setShowGuide,
        handleReleaseResources,
    ])

    useEffect(() => Object.keys(recorder).length && timeLeft === 0 && stopRecording(), [timeLeft])

    const startRecording = async () => {
        try {
            setUserMediaMute(true)
            setTimeout(() => {
                setStopRecordingEnabled(true)
            }, 5000)
            let options = "video/webm"

            if (MediaRecorder.isTypeSupported("video/mp4;codecs=vp8")) {
                options = "video/mp4; codecs=vp8"
                console.log("mp4 Supported")
            } else {
                console.log("not supported mp4")
            }
            const recordObj = new RecordRTCPromisesHandler(stream, {
                type: "video",
                mimeType: options,
            })
            recordObj.startRecording()
            setRecorder(recordObj)
            setRecording(true)
        } catch (e) {
            setErrorLog([...errorLog, `Start Recoding Failed: ${e}`])

            consoleLog(`Start Recoding Failed: ${e.message}`)
        }
    }

    const captureDevice = () => {
        navigator.mediaDevices
            .getUserMedia({
                // audio: {
                //     echoCancellation: false,
                //     noiseSuppression: false,
                //     autoGainControl: false,
                //     googEchoCancellation: false,
                //     googAutoGainControl: false,
                //     googExperimentalAutoGainControl: false,
                //     googNoiseSuppression: false,
                //     googExperimentalNoiseSuppression: false,
                //     googHighpassFilter: false,
                //     googTypingNoiseDetection: false,
                //     googBeamforming: false,
                //     googArrayGeometry: false,
                //     googAudioMirroring: false,
                //     googNoiseReduction: false,
                //     mozNoiseSuppression: false,
                //     mozAutoGainControl: false,
                // },
                video: true,
                audio: true,
            })
            .then(function (camera) {
                // camera.getTracks().forEach(x => x.stop());
                onGetCamera({camera: true, audio: true})
            })
            .catch(function (error) {
                console.log("Unable to capture your camera. Please check console logs.")
                console.error(error)
            })
        // windowNavigator()
        //   .mediaDevices.enumerateDevices()
        //   .then((devices) => {
        //     const videoInputArray = []
        //     const audioInputArray = []
        //     // check for video audio devices
        //     devices.forEach((device) => {
        //       if (device.kind === "videoinput" && device.deviceId && device.deviceId !== "default") {
        //         const videoInput = device.label
        //         setUserVideoDevice([...userVideoDevice, videoInput])
        //         videoInputArray.push(videoInput)
        //       }
        //       if (device.kind === "audioinput" && device.deviceId && device.deviceId !== "default") {
        //         const audioInput = device.label
        //         setUserAudioDevice([...userAudioDevice, audioInput])
        //         audioInputArray.push(audioInput)
        //       }
        //     })
        //     onGetCamera({ camera: videoInputArray, audio: audioInputArray })
        //   })
    }

    const init = useCallback(async (callback) => {
        let mediaDevices = {}
        try {
            setUserMediaMute(true)
            if (windowNavigator().mediaDevices) {
                windowNavigator()
                    .mediaDevices.getUserMedia({
                    audio: false,
                    video: {
                        width: {min: 640, ideal: 640, max: 1280},
                        height: {min: 400, ideal: 480, max: 720},
                        frameRate: {max: 30},
                    },
                })
                    .then((devices) => {
                        mediaDevices = devices
                        captureDevice()

                        setCamDetails({
                            width: mediaDevices.getVideoTracks()[0].getSettings().width,
                            height: mediaDevices.getVideoTracks()[0].getSettings().height,
                            fps: mediaDevices.getVideoTracks()[0].getSettings().frameRate,
                        })

                        setStream(mediaDevices)

                        consoleLog(
                            `Camera Resolution: ${camDetails.width} x ${camDetails.height} | FPS: ${camDetails.fps}`,
                            mediaDevices
                        )

                        handleSuccess(mediaDevices)
                        setShowGuide(true)
                        if (typeof callback === "function") {
                            callback(mediaDevices)
                        }
                    })
                    .catch((e) => {
                        handleReleaseResources(mediaDevices)
                        consoleError("navigator.getUserMedia error:", e)
                        consoleLog(`Camera and Audio Device Required: ${e.message}`)

                        setErrorLog([...errorLog, `Camera and Audio Device Required: ${e.message}`])
                        setError("CAMERA OR AUDIO PERMISSIONS MISSING! PLEASE MAKE SURE TO ALLOW CAMERA AND MICROPHONE.")
                        onAddMessageToToaster({
                            message: "Please allow permission and make sure camera and microphone is not in use",
                            type: "error",
                        })

                        consoleLog("CAMERA OR AUDIO PERMISSIONS MISSING! PLEASE MAKE SURE TO ALLOW CAMERA AND MICROPHONE.")
                    })
            } else {
                onAddMessageToToaster({
                    message: "Your Browser is not supported. Please use latest version of Chrome, Firefox or Edge.",
                    type: "error",
                })
                setError("Your Browser is not supported. Please use latest version of Chrome, Firefox or Edge.")
            }
        } catch (e) {
            handleReleaseResources(mediaDevices)
            consoleError("navigator.getUserMedia error:", e.message)
            consoleLog(`Camera and Audio Device Required: ${e.message}`)

            setErrorLog([...errorLog, `Camera and Audio Device Required: ${e.message}`])
            onAddMessageToToaster({
                message: "Please allow permission and make sure camera and microphone is not in use",
                type: "error",
            })
            setError("CAMERA OR AUDIO PERMISSIONS MISSING! PLEASE MAKE SURE TO ALLOW CAMERA AND MICROPHONE.")

            consoleLog("CAMERA OR AUDIO PERMISSIONS MISSING! PLEASE MAKE SURE TO ALLOW CAMERA AND MICROPHONE.")
        }
    }, [])

    useEffect(() => {
        let interval = ""
        if (setUpDevice && recording) {
            interval = setTimeout(stopRecording, 7000)
        }
        return () => clearInterval(interval)
    }, [recording, setUpDevice])

    useEffect(() => {
        if (!recording && setUpDevice && stream.id) {
            startRecording()
        }
    }, [stream])

    useEffect(() => {
        let mediaStream = {}
        init((camera) => {
            mediaStream = camera
        })
        setFinalBlob({})
        return () => handleReleaseResources(mediaStream)
    }, [shouldRefresh])

    const handleAttemptAgain = useCallback(
        (data) => {
            if (!recording) {
                init()
                setFinalBlob({})
                onSetRemainingAttempts(attempts - 1)
                onReturn(data)
            }
        },
        [recording, attempts, onSetRemainingAttempts, onReturn]
    )

    const handleSubmit = () => {
        if (videoRef && videoRef.current) {
            videoRef.current.pause()
        }
        setUserMediaMute(true)
        onSubmitAnswer()
    }

    return (
        <>
            <div className="mb-3 center-content position-relative">
                <video
                    ref={videoRef}
                    disablePictureInPicture
                    playsInline
                    autoPlay
                    controls={!userMediaMute}
                    controlsList="nodownload"
                    width="70%"
                    className={"flipped-camera"}
                    style={{position: "relative", marginLeft: "120px"}}
                />
                {showGuide && (
                    <VideoGuide style={{position: "absolute", top: "15%", left: "180px", width: "70%", height: "70%"}}/>
                )}
            </div>
            {!setUpDevice && (
                <div className="text-center">
                    <div className="d-flex justify-content-center mb-3">
                        {stream.id && !finalBlob.size && (
                            <Button
                                name="recording_status"
                                icon={<VideoIcon className="mr-2" width="20" height="20"/>}
                                disabled={recording && !stopRecordingEnabled}
                                value={recording1 ? "Stop Recording" : "Start Recording"}
                                variation={`${isPractice ? "primary" : "danger"} font-weight-400 px-3 btn-sm mx-2`}
                                onSubmit={() => (recording1 ? stopRecording() : startRecording())}
                            />
                        )}
                        {!!finalBlob.size && !!attempts && !recording1 && (
                            <>
                                <Button
                                    name="submit"
                                    value={isUploadFailed ? "Try Again" : "Submit Answer"}
                                    disabled={isLoading}
                                    variation={classnames("primary px-5 btn-sm mx-2", {
                                        disabled: isLoading,
                                    })}
                                    onSubmit={handleSubmit}
                                />
                                {!!attempts && (
                                    <Button
                                        name="attempt_again"
                                        value="Attempt Again"
                                        disabled={!(attempts - 1) || isLoading}
                                        variation={classnames("secondary px-5 btn-sm mx-2", {
                                            disabled: !(attempts - 1) || isLoading,
                                        })}
                                        onSubmit={handleAttemptAgain}
                                    />
                                )}
                            </>
                        )}
                        {returnBtnText && (
                            <Button
                                name="stop_recording"
                                value={returnBtnText}
                                variation={classnames("outline-dark px-5 btn-sm mx-2", {
                                    disabled: recording,
                                })}
                                onSubmit={handleAttemptAgain}
                            />
                        )}
                    </div>
                    {isLoading && <Loader loaderClass="mr-2"/>}
                    {isPractice && (
                        <div className="mt-4">
                            <p className="mb-0">Your response will not be uploaded or shown to the</p>
                            <p>employer </p>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default Recorder
