import React from "react";
import { ReactComponent as AdImage } from "../../../assets/images/ad2.svg";
import { ReactComponent as AppStoreIcon } from "../../../assets/images/app-store-button.svg";
import { ReactComponent as PlayStoreIcon } from "../../../assets/images/google-play-button.svg";

const Ad = () => {
  return (
    <div className="text-center">
      <AdImage />
      <div
        className="d-flex justify-content-around m-auto"
        style={{ background: "#00BA52", maxWidth: "375px" }}
      >
        <a
          href="https://apps.apple.com/us/app/digital-hire/id1498098898"
          target="_blank"
          rel="noreferrer"
        >
          <AppStoreIcon width="150px" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.digitalhire"
          target="_blank"
          rel="noreferrer"
        >
          <PlayStoreIcon width="150px" />
        </a>
      </div>
    </div>
  );
};

export default Ad;
