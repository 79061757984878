import React, {Fragment, useCallback, useEffect, useRef, useState,} from "react";
import {
    addNewInterview,
    getAllInterviews,
    getAllInterviewsStatsAction,
    getProfileData,
    getProfileVideoResume,
    postMediaToAWS,
    removeProfilePdfResume,
    removeProfileVideoResume,
} from "../../../redux/actions/ProfileActions";
import {
    getAllInterviewsCountSelector,
    getAllInterviewsCurrentPageSelector,
    getAllInterviewsLoadingSelector,
    getAllInterviewsPagesSelector,
    getAllInterviewsSelector,
    getAllInterviewsStatsLoadingSelector,
    getInterviewCompletedSelector,
    getInterviewLoadingSelector,
    getInterviewPendingSelector,
    getPdfUrlSelector,
    getUserDataSelector,
    getVideoThumbnailUrlSelector,
    getVideoUrlSelector,
} from "../../../redux/selectors/ProfileSelector";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
// import { getDate } from "../../../helpers/dateTime"
// import avatar from "../../../images/svg/default.svg"
import {ReactComponent as ResumeIcon} from "../../../images/svg/documents-new.svg";
import {ReactComponent as VideoIcon} from "../../../images/svg/certified-person-new.svg";
import {ReactComponent as NoInterviews} from "../../../images/svg/no-interviews-new.svg";
import Loader from "../../common/Loader";
import "./styles.css";
import Interview from "./Interview";
import WarningModal from "../../common/WarningModal";
import {toast, ToastContainer} from "react-toastify";

const Home = (props) => {
    const {
        addNewInterviewAction,
        getAllInterviewsAction,
        //getProfilePdfResumeAction,
        getProfileVideoResumeAction,
        removeProfileVideoResumeAction,
        removeProfilePdfResumeAction,
        getProfileDataAction,
        postMediaToAWSAction,
        userData,
        allInterviews,
        videoUrl,
        pdfUrl,
        videoThumbnailUrl,
        allInterviewsLoading,
        //profileLoading,
        interviewLoading,
        interviewPending,
        interviewCompleted,
        getAllInterviewsStatsAction,
        getAllInterviewsPages,
        getAllInterviewsCurrentPage,
        allInterviewsStatsLoading,
        getAllInterviewsCount
    } = props;
    // console.log(allInterviews);
    const [interviewUrl, setInterviewUrl] = useState("");
    const hiddenFileInput = useRef(null);
    const toastId = useRef(null);
    const interviewError = useRef("");
    const [openModal, setOpenModal] = useState("");
    const [page, setPage] = useState(1);
    const handleAddInterview = () => {
        if (interviewUrl !== "" && interviewUrl !== " ") {
            addNewInterviewAction(
                interviewUrl.substr(interviewUrl.lastIndexOf("/") + 1)
            );
        } else {
            toast.error("Please enter a valid link", {autoClose: 2000});
        }
    };
    const handleMoveToProfile = () => {
        props.history.push("/profile");
    };

    useEffect(() => {

        const p_id = localStorage.getItem("position_id")
        // console.log("Position id in dashboard: ", p_id)
        if (p_id && p_id !== "" && p_id !== " ") {
            // console.log("called 1")
            addNewInterviewAction(p_id)
            localStorage.setItem("position_id", "")
        }
        // props.history.push("/dashboard")
    }, [])

    useEffect(() => {

        const data = {
            "pagination": {
                "page": page,
                "size": 5
            }
        }
        console.log("Calling Interview Action Use Effect", allInterviews)
        getAllInterviewsAction(data)
        getAllInterviewsStatsAction()
        if (allInterviews.length === 0) getAllInterviewsAction(data);
        if (videoUrl === undefined && pdfUrl === undefined)
            getProfileVideoResumeAction();
        //getProfilePdfResumeAction()
        if (Object.keys(userData).length === 0) {
            getProfileDataAction();
        }
    }, []);

    const loadMoreData = () => {
        const data = {
            "pagination": {
                "page": getAllInterviewsCurrentPage + 1,
                "size": 5
            }
        }
        console.log("API CALL: ", data)
        getAllInterviewsAction(data)
        // if (allInterviews.length === 0) getAllInterviewsAction(data);
        // if (videoUrl === undefined && pdfUrl === undefined)
        //     getProfileVideoResumeAction();
        // //getProfilePdfResumeAction()
        // if (Object.keys(userData).length === 0) {
        //     getProfileDataAction();
        // }
    };

    const handleChange = (event) => {
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const media_key =
                "user/" + file.lastModified + "." + file.type.split("/")[1];
            postMediaToAWSAction({key: media_key, file: file}, (res) => {
                if (res.config.url) {
                    toast.dismiss(toastId.current);
                }
            });
            toastId.current = toast.success("Uploading ...", {autoClose: false});
        }
    };

    const handleDeleteResume = useCallback(() => {
        if (openModal === "video_resume") {
            removeProfileVideoResumeAction((res) => {
                if (res === "") {
                    toast.success("Resume Removed", {autoClose: 2000});
                }
            });
        } else if (openModal === "resume") {
            removeProfilePdfResumeAction((res) => {
                if (res === "") {
                    toast.success("Resume Removed", {autoClose: 2000});
                }
            });
        }
        setOpenModal("");
    }, [openModal]);

    return (
        <Fragment>
            <ToastContainer/>
            <div className="dashboard">
                <div className="row mb-4">
                    <div class="col-lg-3 col-md-6">
                        <div class="card bg-secondary h-100">
                            <div class="card-body">
                                <div class="media">
                                    {/* <span class="mr-3">
                  <i class="flaticon-381-diamond"></i>
                </span> */}
                                    <div class="media-body text-white">
                                        {allInterviewsStatsLoading && (
                                            <Loader
                                                spinnerClass="text-primary my-3"
                                                loaderClass="d-flex justify-content-center"
                                            />
                                        )}
                                        <h3 className="text-white">
                                            {interviewPending === false && !allInterviewsStatsLoading
                                                ? 0
                                                : interviewPending}
                                        </h3>
                                        <h3 class="text-white">
                                            Interviews <br/> Pending
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="card bg-primary h-100">
                            <div class="card-body">
                                <div class="media">
                                    {/* <span class="mr-3">
                  <i class="flaticon-381-diamond"></i>
                </span> */}
                                    <div class="media-body text-white">
                                        {allInterviewsStatsLoading && (
                                            <Loader
                                                spinnerClass="text-primary my-3"
                                                loaderClass="d-flex justify-content-center"
                                            />
                                        )}
                                        <h3 className="text-white">
                                            {interviewCompleted === false && !allInterviewsStatsLoading
                                                ? 0
                                                : interviewCompleted}
                                        </h3>
                                        <h3 class="text-white">Interviews Completed</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="card h-100">
                            {/* <div className="card-header">
                <h5 className="card-title">Add Interview</h5>
              </div> */}
                            <div className="card-body">
                                <h3 className="card-title">Add Interview</h3>
                                <p className="text-dark">
                                    Add interview link provided to you by the employer
                                    {/* <br/> Example: https://app.digitalhire.com/interview/602e699bd3b153d0d5972adc */}
                                </p>
                                <div className="row">
                                    <div className="col-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="val-fname1"
                                            placeholder="Enter Url"
                                            onChange={(e) => setInterviewUrl(e.target.value)}
                                            value={interviewUrl}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <button
                                            className="btn btn-primary w-100"
                                            onClick={handleAddInterview}
                                        >
                                            {interviewLoading ? (
                                                <Loader spinnerClass="text-white"/>
                                            ) : (
                                                "Add Interview"
                                            )}
                                        </button>
                                    </div>
                                    <div className="text-danger ml-3" ref={interviewError}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* VR */}
                    <div className="col-lg-6 col-md-6">
                        <div className="card h-100">
                            <div
                                className="card-body d-flex w-100 min-height-144 align-items-center justify-content-around"
                                style={{
                                    borderTop: "4px solid #00ba52",
                                    minWidth: "260px",
                                    backgroundColor: "#fff",
                                }}
                            >
                                {!!videoUrl ? (
                                    <>
                                        {/* <div className="flex-column align-items-center">
                      <div className="d-flex justify-content-between">
                        <div>
                          <h2 className="mb-1 body-title dark-color">
                            Video Resume
                          </h2>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-link text-danger btn-sm mini d-flex align-items-center mb-3"
                            name="Video Resume-resume"
                            onClick={() => setOpenModal("video_resume")}

                          >
                            Remove <span className="body-title ml-1 ">×</span>
                          </button>
                        </div>
                      </div>
                      <video controls width="300px" height="100%" >
                        <source src={videoUrl} type="video/webm" />
                        Your browser does not support the video tag.
                      </video>
                    </div> */}
                                        {/*<VideoIcon height={110}/>*/}

                                        <div className="flex-column align-items-center ml-2 ">
                                            <h3 className="title-color mb-2">Video Resume</h3>
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger text-danger btn-sm mini d-flex align-items-center mb-3"
                                                name="Video Resume-resume"
                                                onClick={() => setOpenModal("video_resume")}
                                            >
                                                Remove <span className="body-title ml-1 ">×</span>
                                            </button>
                                        </div>
                                        <video controls width="250px" height="100%"
                                               poster={videoThumbnailUrl}>
                                            <source src={videoUrl} type="video/webm"/>
                                            Your browser does not support the video tag.
                                        </video>
                                    </>
                                ) : (
                                    <>
                                        <VideoIcon height={110}/>

                                        <div className="flex-column align-items-center ml-2 ">
                                            <h3 className="title-color mb-2">Video Resume</h3>
                                            <div className="extra-small-font mb-2">
                                                Record a 90 seconds video resume
                                            </div>
                                        </div>
                                        <div>
                                            <Link to="/videoresume">
                                                <button className="btn btn-outline-secondary btn-md">
                                                    Add Video Resume
                                                </button>
                                            </Link>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* PDF */}
                    <div className="col-lg-6 col-md-6">
                        <div className="card h-100">
                            <div
                                className="card-body d-flex w-100 min-height-144 align-items-center justify-content-around"
                                style={{
                                    borderTop: "4px solid #5743d9",
                                    minWidth: "260px",
                                    backgroundColor: "#fff",
                                }}
                            >
                                {!!pdfUrl ? (
                                    <>
                                        <ResumeIcon height={110}/>
                                        <div className="flex-column align-items-center ml-3 ">
                                            <h3 className="title-color mb-2">PDF Resume</h3>
                                            <div className="extra-small-font mb-2">
                                                View or delete your resume
                                            </div>
                                            <a
                                                href={pdfUrl}
                                                target="_blank"
                                                type="button"
                                                className="btn btn-outline-dark btn-sm mini mb-md-3 text-center"
                                                name="PDF Resume-resume"
                                            >
                                                Preview
                                            </a>
                                        </div>
                                        <div>
                                            <div className="d-md-flex">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger text-danger btn-sm mini d-flex align-items-center mb-3"
                                                    name="PDF Resume-resume"
                                                    onClick={() => setOpenModal("resume")}
                                                >
                                                    Remove <span className="body-title ml-1">×</span>
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <ResumeIcon height={110}/>
                                        <div className="flex-column align-items-center ml-3 ">
                                            <h3 className="title-color mb-2">PDF Resume</h3>
                                            <div className="extra-small-font mb-2">
                                                Upload your Resume in PDF format
                                            </div>
                                        </div>
                                        <div>
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                accept="application/pdf"
                                                ref={hiddenFileInput}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <button
                                                className="btn btn-outline-secondary btn-md"
                                                onClick={() => hiddenFileInput.current.click()}
                                            >
                                                Upload Resume
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4 dashboard">
                <div className="col">
                    {allInterviewsLoading && (
                        <Loader
                            spinnerClass="text-primary my-3"
                            loaderClass="d-flex justify-content-center"
                        />
                    )}

                    {allInterviews &&
                        allInterviews.map((interview, index) => (
                            <Interview key={index} data={interview} video_resume={videoUrl}/>
                        ))}

                    {allInterviews &&
                        allInterviews.length === 0 &&
                        !allInterviewsLoading && (
                            <div class="d-flex center-content flex-column mt-4 align-items-center">
                                <div style={{height: "200px", maxWidth: "500px", minWidth: "400px"}}>

                                    <NoInterviews/>
                                </div>
                                <h5 class="font-weight-500 ml-5 mt-3">Getting Started</h5>
                                <p class="ml-5 mb-0">
                                    Add an interview by using the add interview button above.
                                </p>
                                <p class="ml-5">
                                    or request the Employer to send an Invitation on your Email
                                    Address
                                </p>
                            </div>
                        )}
                </div>
            </div>
            {
                getAllInterviewsPages > getAllInterviewsCurrentPage ?
                    <button className={"btn btn-primary float-right"} onClick={() => {
                        // setPage(getAllInterviewsCurrentPage + 1)
                        loadMoreData()
                    }
                    }>
                        Load More Data
                    </button> : ""
            }

            <WarningModal
                open={!!openModal}
                title={`Are you sure you want to delete your ${
                    openModal === "video_resume" ? "video " : ""
                }resume?`}
                onSubmit={handleDeleteResume}
                onCancel={useCallback(() => setOpenModal(""), [])}
            />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        userData: getUserDataSelector(state),
        allInterviews: getAllInterviewsSelector(state),
        allInterviewsLoading: getAllInterviewsLoadingSelector(state),
        allInterviewsStatsLoading: getAllInterviewsStatsLoadingSelector(state),
        interviewLoading: getInterviewLoadingSelector(state),
        interviewPending: getInterviewPendingSelector(state),
        interviewCompleted: getInterviewCompletedSelector(state),
        videoUrl: getVideoUrlSelector(state),
        videoThumbnailUrl: getVideoThumbnailUrlSelector(state),
        pdfUrl: getPdfUrlSelector(state),
        getAllInterviewsCount: getAllInterviewsCountSelector(state),
        getAllInterviewsPages: getAllInterviewsPagesSelector(state),
        getAllInterviewsCurrentPage: getAllInterviewsCurrentPageSelector(state),
        //profileLoading: getProfileLoadingSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addNewInterviewAction: (position_url) =>
            dispatch(addNewInterview(position_url)),
        postMediaToAWSAction: (data, callback) =>
            dispatch(postMediaToAWS(data, callback)),
        getAllInterviewsAction: (data) => dispatch(getAllInterviews(data)),
        getAllInterviewsStatsAction: (data) => dispatch(getAllInterviewsStatsAction(data)),
        //getProfilePdfResumeAction: () => dispatch(getProfilePdfResume()),
        getProfileVideoResumeAction: () => dispatch(getProfileVideoResume()),
        getProfileDataAction: () => dispatch(getProfileData()),
        removeProfileVideoResumeAction: (callback) =>
            dispatch(removeProfileVideoResume(callback)),
        removeProfilePdfResumeAction: (callback) =>
            dispatch(removeProfilePdfResume(callback)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
