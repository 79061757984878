import React, {useEffect, useRef, useState, useCallback, Fragment} from "react"
import {useHistory} from "react-router-dom"
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import {ToastContainer} from "react-toastify"
import {getAllInterviews, markInterviewConsentAction} from "../../../redux/actions/ProfileActions"
import {useLocation, withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import {getUserDataSelector} from "../../../redux/selectors/ProfileSelector"
import {ReactComponent as PersonIcon} from "../../../assets/images/person.svg"
import {ReactComponent as GrobalIcon} from "../../../assets/images/global.svg"
import {ReactComponent as FacebookIcon} from "../../../assets/images/facebook.svg"
import {ReactComponent as YoutubeIcon} from "../../../assets/images/youtube.svg"
import {ReactComponent as LinkedInIcon} from "../../../assets/images/linkedin.svg"
import {ReactComponent as TwitterIcon} from "../../../assets/images/twitter.svg"
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta.svg"
import {ReactComponent as StopwatchIcon} from "../../../assets/images/stopwatch.svg"
import {ReactComponent as QuestionIcon} from "../../../assets/images/question.svg"
import {ReactComponent as WebCamIcon} from "../../../assets/images/webcam.svg"
import {ReactComponent as FrameIcon} from "../../../assets/images/frame.svg"
import Ad from "../../common/Ad/Ad"
import Button from "../../common/Button/Button"
import {isMobile} from "../../../helpers"
import Modal from "../../common/Modal/Modal"
import {Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle} from "reactstrap";
import LogoDark from "../../../images/logo-dark.svg"
import LogoLight from "../../../images/logo-light.svg"

const InterviewConsent = (props) => {
    const {userData, markInterviewConsent} = props
    const socialRef = useRef(null)
    const [modal, setModal] = useState(true)
    const history = useHistory();
    const location = useLocation();
    console.log("L", location)
    const data = location.state;
    let estTime
    estTime = data?.Position?.Question.reduce((acc, {time_allowed}) => acc + time_allowed, 0)
    console.log({data});
    const handleNavigate = () => {
        history.push("/dashboard")
    }

    // useEffect(() => {
    //     const data = {
    //
    //         "pagination": {
    //             "page": 1,
    //             "size": 10
    //         }
    //
    //     }
    //     props.getAllInterviewsAction(data)
    //     window.scrollTo({top: 0, behavior: "smooth"})
    // }, [])

    const submitConsent = (e) => {
        const d = {
            interview_id: data.id,
            consent: e
        };
        markInterviewConsent(d, props, data);

    }

    return (
        <Fragment>
            <div className={"row justify-content-center"}>
                <Card className={"col-md-8"}>
                    {/*<Card.Img variant="top" src={LogoLight}/>*/}
                    <CardBody><img src={LogoDark} style={{
                        width: "50%", display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}/>
                        <CardTitle tag="h5">

                        </CardTitle>
                        {/*<CardSubtitle*/}
                        {/*    className="mb-2 text-muted"*/}
                        {/*    tag="h6"*/}
                        {/*>*/}
                        {/*    Card subtitle*/}
                        {/*</CardSubtitle>*/}
                        <CardText className={"text-center text-justify"}>
                            <span>Thank you for taking the time to conduct the interview. DigitalHire is
                            committed to helping you find the right career path. Please check below if
                            you allow DigitalHire to share your digital interview with other potential
                                employers.</span>
                            <div className={"row justify-content-around"} style={{margin: "25px"}}>
                                {/*<Link to={{pathname: "/interview/conclusion", state: data}}>*/}
                                <button onClick={() => {
                                    submitConsent(true)
                                }} className={"btn btn-primary"}>Agree
                                </button>
                                {/*</Link>*/}
                                {/*<Link to={{pathname: "/interview/conclusion", state: data}}>*/}
                                <button onClick={() => {
                                    submitConsent(false)
                                }} className={"btn btn-primary"}>Cancel
                                </button>
                                {/*</Link>*/}
                            </div>
                            <h4>Recruitment Candidate Disclaimer</h4>
                            <span style={{textAlign: "left"}}>
                            <p>
                We appreciate you taking the time to record and submit the interview. We at DigitalHire are committed to assisting you in selecting the right career path. If you allow DigitalHire to share your digital interview with further prospective employers, please click the Agree button.
<br/><br/>
We are dedicated to helping jobseekers and strive to find the most qualified applicants for open positions. Please note that we are not the employer. We provide the company with the candidate's details in order to expedite the hiring process. The potential employer conducts an independent evaluation of job applicants. We neither offer advice on hiring nor do we provide it. It is in your best interest and your responsibility to verify the terms of any employment opportunity. We do not evaluate employers or their job openings, and neither do we provide employers with any business advice.
<br/><br/>
This disclaimer is subject to all conditions within the User Agreement and is incorporated by reference.
                  </p>
                               </span>
                        </CardText>
                        <CardFooter className={"row justify-content-around"}
                                    style={{marginTop: "42px", marginBottom: "-37px"}}>
                            <a href={"https://digitalhire.com/faqs/"} target={"_blank"} rel={"noreferrer"}>Help
                                Center</a>
                            <p>Powered by DigitalHire</p>

                        </CardFooter>
                    </CardBody>
                </Card>
            </div>
        </Fragment>

    )
}

const mapStateToProps = (state) => {
    return {
        userData: getUserDataSelector(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllInterviewsAction: (data) => dispatch(getAllInterviews(data)),
        markInterviewConsent: (data, props, state) => dispatch(markInterviewConsentAction(data, props, state))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewConsent))
