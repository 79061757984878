// 3rd party
import React, { memo } from "react";
import PropTypes from "prop-types";

const Button = ({ icon, name, value, disabled, variation, onSubmit }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className={`btn btn-${variation}`}
      name={name}
      onClick={onSubmit}
    >
      {icon}
      {value}
    </button>
  );
};

Button.defaultProps = {
  icon: <></>,
  disabled: false,
  variation: "primary",
};

Button.propTypes = {
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  variation: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(Button);
