import React, {useEffect, useRef, useState, useCallback} from "react"
import {useHistory} from "react-router-dom"
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import {ToastContainer} from "react-toastify"
import {getAllInterviews, updateInterviewVideoResume} from "../../../redux/actions/ProfileActions"
import {useLocation, withRouter, Link} from "react-router-dom"
import {connect, useDispatch} from "react-redux"
import {getUserDataSelector} from "../../../redux/selectors/ProfileSelector"
import {ReactComponent as PersonIcon} from "../../../assets/images/person.svg"
import {ReactComponent as GrobalIcon} from "../../../assets/images/global.svg"
import {ReactComponent as FacebookIcon} from "../../../assets/images/facebook.svg"
import {ReactComponent as YoutubeIcon} from "../../../assets/images/youtube.svg"
import {ReactComponent as LinkedInIcon} from "../../../assets/images/linkedin.svg"
import {ReactComponent as TwitterIcon} from "../../../assets/images/twitter.svg"
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta.svg"
import {ReactComponent as StopwatchIcon} from "../../../assets/images/stopwatch.svg"
import {ReactComponent as QuestionIcon} from "../../../assets/images/question.svg"
import {ReactComponent as WebCamIcon} from "../../../assets/images/webcam.svg"
import {ReactComponent as FrameIcon} from "../../../assets/images/frame.svg"
import Ad from "../../common/Ad/Ad"
import Button from "../../common/Button/Button"
import {isMobile} from "../../../helpers"
import Modal from "../../common/Modal/Modal"

const InterviewFinish = (props) => {
    const {userData} = props
    const dispatch = useDispatch()
    const socialRef = useRef(null)
    const [modal, setModal] = useState(true)
    const history = useHistory();
    const location = useLocation();
    const data = location.state;
    let estTime
    estTime = data?.Position?.Question.reduce((acc, {time_allowed}) => acc + time_allowed, 0)
    console.log({data});
    const handleNavigate = () => {
        history.push("/dashboard")
    }

    useEffect(() => {
        const data = {

            "pagination": {
                "page": 1,
                "size": 10
            }

        }
        props.getAllInterviewsAction(data)
        window.scrollTo({top: 0, behavior: "smooth"})
    }, [])

    const handleModalCancel = useCallback(() => {
        setModal(false)
    }, [])

    return (
        <div className="d-flex justify-content-center col-md-10 m-auto ">
            <ToastContainer/>

            <VideoResumeCard
                helpCenter
                interview_data={data}
                title={data?.Position?.title || ''}
                companyName={data?.Company?.name || ''}
                progressColor={"rgb(0,186,82)"}
                imgClass="rounded-circle"
                imageUrl={data?.Company?.logo}
                subtitle={"Complete"}
            >
                <div className="row grid-divider mt-3">
                    <div className="col-sm-12 col-md-6 col-lg-6 my-1 mt-3 mb-4">
                        <div
                            className="d-flex pr-3 mb-3 align-items-md-center flex-md-row flex-column justify-content-center">
                            <div className="ml-3">
                                {!userData?.image && <PersonIcon height="150" width="110"/>}
                                {userData?.image && (
                                    <img
                                        className="rounded-circle profile_img"
                                        height="90"
                                        width="90"
                                        src={userData?.image}
                                        alt={userData?.first_name}
                                    />
                                )}
                            </div>
                            <div className="ml-3 pt-4 pt-md-2">
                                {!data.is_complete && (
                                    <>
                                        <h4 className="mb-1 font-weight-600">Thank you {userData?.first_name}!</h4>
                                        <p className="mb-2 dark-color">
                                            {data?.Company?.name} has received your submission
                                            for {data?.Position?.title}
                                        </p>
                                    </>
                                )}
                                {data?.is_complete && (
                                    <>
                                        <h4 className="mb-1 font-weight-600">Thank you {userData?.first_name}!</h4>
                                        <p className="mb-2 dark-color">
                                            {data?.Company?.name} has received your submission
                                            for {data?.Position?.title}
                                        </p>
                                    </>
                                )}
                                <div className="d-flex align-items-center" ref={socialRef}>
                                    {data?.Company?.website && (
                                        <a rel="noopener noreferrer" href={"www.google.com"} target="_blank"
                                           className="mr-2 social-icons">
                                            <GrobalIcon height="20" width="20" className="mr-2"/>
                                        </a>
                                    )}
                                    <div className="text-muted">
                                        {data?.Company?.twitter && (
                                            <a
                                                rel="noopener noreferrer"
                                                href={data?.Company?.twitter}
                                                target="_blank"
                                                className="mr-2 social-icons"
                                            >
                                                <TwitterIcon className="mr-2"/>
                                            </a>
                                        )}
                                        {data?.Company?.facebook && (
                                            <a
                                                rel="noopener noreferrer"
                                                href={data?.Company?.facebook}
                                                target="_blank"
                                                className="mr-2 social-icons"
                                            >
                                                <FacebookIcon className="mr-2"/>
                                            </a>
                                        )}
                                        {data?.Company?.linkedin && (
                                            <a
                                                rel="noopener noreferrer"
                                                href={data?.Company?.linkedin}
                                                target="_blank"
                                                className="mr-2 social-icons"
                                            >
                                                <LinkedInIcon className="mr-2"/>
                                            </a>
                                        )}
                                        {data?.Company?.youtube && (
                                            <a
                                                rel="noopener noreferrer"
                                                href={data?.Company?.youtube}
                                                target="_blank"
                                                className="mr-2 social-icons"
                                            >
                                                <YoutubeIcon className="mr-2"/>
                                            </a>
                                        )}
                                        {data?.Company?.instagram && (
                                            <a
                                                rel="noopener noreferrer"
                                                href={data?.Company?.instagram}
                                                target="_blank"
                                                className="mr-2 social-icons"
                                            >
                                                <InstagramIcon className="mr-2"/>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ml-3 mr-3 mt-4">
                            <p className="body-title dark-grey-color font-weight-600">{data?.Position?.title}</p>
                            <p className="dark-grey-color font-weight-normal mb-5">{data?.Position?.description}</p>
                            {data?.is_complete && <p className="my-4 body-title font-weight-600 ">Report</p>}
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex ml-2 align-items-center">
                                    <QuestionIcon className="text-primary" height="28" width="23"/>{" "}
                                    <p className="ml-4 mb-0 dark-grey-color font-weight-normal">Questions {data?.is_complete && "Taken"}</p>
                                </div>
                                <p className="mb-0 dark-grey-color font-weight-600">{data?.Position?.Question?.length}</p>
                            </div>
                            <br/>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <StopwatchIcon className="text-primary" height="30" width="30"/>{" "}
                                    {!data?.is_complete &&
                                        <p className="ml-4 mb-0 dark-grey-color font-weight-normal">Estimated Time</p>}
                                    {data?.is_complete && (
                                        <p className="ml-4 mb-0 dark-grey-color font-weight-normal">Avg. Response
                                            Time</p>
                                    )}
                                </div>
                                <p className="mb-0 dark-grey-color font-weight-600">
                                    {estTime >= 1.0 ? `${estTime} Minutes` : `${(estTime * 60).toFixed(2)} Seconds`}
                                </p>
                            </div>
                            <br/>
                            {!data?.is_complete && (
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex ml-2 align-items-center">
                                        <WebCamIcon className="text-primary" height="30" width="24"/>{" "}
                                        <p className="ml-4 mb-0 dark-grey-color font-weight-normal">Equipment</p>
                                    </div>
                                    <p className="mb-0 dark-grey-color font-weight-600">Webcam, Microphone</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 my-1">
                        <div className="center-content rounded-lg col-12 mt-4 ml-1 mr-2">
                            {!data?.Position?.outro_video && <FrameIcon className="img-fluid d-flex m-auto"/>}
                            {data?.Position?.outro_video && (
                                // eslint-disable-next-line jsx-a11y/media-has-caption
                                (data.Position?.outro_video && data.Position?.outro_video.includes("mp4") ? <video
                                    className="w-100"
                                    src={data.Position?.outro_video}
                                    disablePictureInPicture
                                    playsInline
                                    autoPlay="autoPlay"
                                    controls
                                    controlsList="nodownload"
                                /> : <img style={{maxHeight: "400px"}} src={data.Position?.outro_video}/>)

                                // <video
                                //     className="w-100"
                                //     src={data.Position?.outro_video}
                                //     disablePictureInPicture
                                //     playsInline
                                //     autoPlay="autoPlay"
                                //     controls
                                //     controlsList="nodownload"
                                // />
                            )}
                        </div>
                        {data?.is_complete && (
                            <>
                                <div className="center-content mt-4 mb-2">
                                    <Button variation="primary px-5 btn-sm ml-2" name="finish" value="Finish"/>
                                    {/* onSubmit={} */}
                                </div>
                                <div className="d-flex justify-content-center mr-6 ml-20 text-center ">
                                    <p className="my-0 ">
                                        <small>Thank you for using DigitalHire</small>
                                    </p>
                                </div>
                            </>
                        )}
                        {!data?.is_complete && (
                            <>
                                <div className="d-md-flex justify-content-md-center text-center my-4">
                                    <Link to={{pathname: "/interview/consent", state: data}}>
                                        <button
                                            className="btn btn-primary btn-block btn-sm btn-width"
                                            // onClick=>{
                                            //
                                            //     dispatch(updateInterviewVideoResume("false"))
                                            //
                                            // }
                                            onClick={() => {
                                                // console.log("Finish Dta: ", data)
                                                dispatch(updateInterviewVideoResume("false"))
                                            }}
                                        >
                                            Finish
                                        </button>
                                    </Link>
                                </div>
                                <div className="text-center ml-4 mr-4"/>
                            </>
                        )}
                    </div>
                </div>
                {isMobile() && (
                    <Modal
                        open={modal}
                        hideBottomBorder
                        closeText="close"
                        headerClass="pb-0"
                        bodyClass="pt-0"
                        onCancel={handleModalCancel}
                    >
                        <Ad/>
                    </Modal>
                )}
            </VideoResumeCard>
            {/* <div>
        <VideoResumeCard>
          <h1 className="d-flex mt-3 justify-content-center pb-10">You've submitted this interview</h1>
          <div className="dropdown-divider divider w-100" />
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary btn-sm btn-width mb-3 mb-md-0" onClick={handleNavigate}>
              Back to Dashboard
            </button>
          </div>
        </VideoResumeCard>
      </div> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userData: getUserDataSelector(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllInterviewsAction: (data) => dispatch(getAllInterviews(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewFinish))
