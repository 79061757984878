import axiosInstance from "./AxiosInstance"
import Axios from "axios"

export const addNewInterviewService = (position_url) => {
    return axiosInstance.post(`/interview/`, {position_url})
}

export const getAllInterviewsService = (data) => {
    console.log("Interview Service Called")
    return axiosInstance.post(`/candidate/dashboard`, data)
}

export const markInterviewConsentService = (data) => {
    console.log("Interview Consent Service Called")
    return axiosInstance.post(`/interview/consent`, data)
}

export const getAllInterviewsStatsService = () => {
    return axiosInstance.get(`/candidate/dashboard/stats`)
}

export const getProfileDataService = () => {
    return axiosInstance.get(`/accounts/`)
}

export const setChangePasswordService = (dataForm) => {
    return axiosInstance.post(`/accounts/password/change`, dataForm)
}

export const setDeactivateAccountService = (reason) => {
    return axiosInstance.post(`/accounts/deactivate/request`, reason)
}

export const updateProfileDataService = (user_data) => {
    return axiosInstance.put(`/accounts/`, user_data)
}

export const setProfileVideoResumeService = (url) => {
    return axiosInstance.post(`/candidate/`, {video_resume: url})
}

export const removeProfileVideoResumeService = () => {
    return axiosInstance.post(`/candidate/`, {video_resume: ""})
}

export const getProfileVideoResumeService = () => {
    return axiosInstance.get(`/candidate/`)
}

export const setProfilePdfResumeService = (url) => {
    return axiosInstance.post(`/candidate/`, {pdf_resume: url})
}

// export const getProfilePdfResumeService = () => {
//   return axiosInstance.get(`/candidate/`)
// }

export const removeProfilePdfResumeService = () => {
    return axiosInstance.post(`/candidate/`, {pdf_resume: ""})
}

export const interviewAnswerService = (answers) => {
    answers.answer_data.device = "Web"
    return axiosInstance.post(`/interview/answer`, answers)
}

export const interviewVideoResumeService = (postData) => {
    return axiosInstance.put(`/interview/`, postData)
}

export const postMediaToAWSSignedService = (data) => {
    return axiosInstance.post(`/media/put-signing-url`, {
        media_key: data,
    })
}

export const postMediaToAWSUploadService = (submitURL, file) => {
    return Axios.put(`${submitURL}`, file)
}
