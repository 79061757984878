import axiosInstance from "./AxiosInstance"

export const loginService = (dataForm) => {
  const postData = {
    email: dataForm.email,
    password: dataForm.password,
    type: "candidate",
  }
  return axiosInstance.post(`/accounts/login`, postData)
}

export const signUpService = (dataForm) => {
  return axiosInstance.post(`/accounts/sign-up`, dataForm)
}

export const signUpVerifyService = (dataForm) => {
  return axiosInstance.post(`/accounts/verify`, { pin: dataForm })
}

export function userForgotPasswordService(data) {
  const postData = {
    ...data
  }
  return axiosInstance.post(
      `/accounts/forget/password/`, postData
  )
}

export function userResendPinService() {

  return axiosInstance.post(
      `/accounts/resend/verify/code`
  )
}
