import Button from "../../common/Button/Button"
import {isIOS} from "react-device-detect"
import {ReactComponent as TickIcon} from "../../../images/svg/tick.svg"
import {ReactComponent as WebCamIcon} from "../../../images/svg/webcam.svg"
import {ReactComponent as StopwatchIcon} from "../../../images/svg/stopwatch.svg"
import {ReactComponent as CalendarIcon} from "../../../assets/images/calendar.svg"
import {ReactComponent as DigitalHireIcon} from "../../../images/digital-hire.svg"
import {useEffect, useState, useCallback} from "react"
import {getProfileVideoResume, removeProfileVideoResume} from "../../../redux/actions/ProfileActions"
import {getVideoUrlSelector, getTimeStampSelector} from "../../../redux/selectors/ProfileSelector"
import {connect} from "react-redux"
import Alert from "../../common/Alert";
import {withRouter} from "react-router-dom"
import WarningModal from "../../common/WarningModal"
import Modal from "../../common/Modal/Modal"
import Ad from "../../common/Ad/Ad"
import {isMobile} from "../../../helpers"
import {ToastContainer, toast} from "react-toastify"
import moment from "moment";

function Intro(props) {
    const [openModal, setOpenModal] = useState("")
    const [modal, setModal] = useState(true)
    const [alert, setAlert] = useState({
        alert: false,
        type: 'success', // error
        variant: 'success', // danger
        msg: ''
    });
    const [profile, setProfile] = useState({
        alert: false,
        type: 'success', // error
        variant: 'success', // danger
        msg: ''
    });
    const handleModalCancel = useCallback(() => {
        setModal(false)
    }, [])
    const sectionDesc = [
        "Introduce yourself & briefly share your professional journey",
        "Speak about your most recent work experience",
        "What sets you apart?",
    ]
    useEffect(() => {
        props.getProfileVideoResumeAction((val) => setAlert(val))
    }, [])

    const handleDeleteResume = useCallback(() => {
        if (openModal === "video_resume") {
            props.removeProfileVideoResumeAction((res) => {
                if (res === "") {
                    setProfile({
                        alert: false,
                        type: 'error', // error
                        variant: 'danger', // danger
                        msg: "Resume Removed"
                    })
                }
            })
        }
        setOpenModal("")
    }, [openModal])
    return (
        <div className="m-auto">
            <ToastContainer/>
            <Alert
                alert={profile.alert}
                type={profile.type}
                variant={profile.variant}
                msg={profile.msg}
                dismiss={() => setProfile({
                    alert: false,
                    type: 'success', // error
                    variant: 'success', // danger
                    msg: ''
                })}
            />
            <Alert
                alert={alert.alert}
                type={alert.type}
                variant={alert.variant}
                msg={alert.msg}
                dismiss={() => setAlert({
                    alert: false,
                    type: 'success', // error
                    variant: 'success', // danger
                    msg: ''
                })}
            />
            {!!props.videoUrl ? (
                <>
                    <div className="">
                        <div className="pt-4">
                            <div className=" d-flex justify-content-center align-items-center">
                                <DigitalHireIcon width={200}/>
                            </div>
                            <h5 className="text-center dark-color font-weight-normal mt-3"
                                style={{fontSize: "1.25rem"}}>
                                Enhance your profile by recording a
                            </h5>
                            <h5 className="text-center dark-color font-weight-normal " style={{fontSize: "1.25rem"}}>
                                Video Resume
                            </h5>
                            <div className="dropdown-divider w-100"></div>
                            <div class="d-flex justify-content-center align-items-center my-2">
                                <div class="d-flex align-items-center">
                                    <StopwatchIcon width={25} height={25} className="text-primary"/>
                                    <p class="ml-1 mb-0">Time Taken:</p>
                                    <p class="ml-1 mb-0 text-muted">5 Seconds</p>
                                </div>
                                <div class="d-flex align-items-center ml-5">
                                    <CalendarIcon width={20} height={20} className="text-primary"/>
                                    <p class="ml-1 mb-0">Uploaded on:</p>
                                    <p class="ml-1 mb-0">{moment(props.timeStamp).format("MMM DD, YYYY")} </p>
                                </div>
                            </div>
                        </div>
                        <video controls width="100%" height="100%">
                            <source src={props.videoUrl} type="video/webm" autoplay={true}/>
                            Your browser does not support the video tag.
                        </video>
                        <div className="d-flex justify-content-center mt-1 mt-4">
                            <button
                                className="btn btn-danger"
                                onClick={() => setOpenModal("video_resume")}
                                style={{cursor: "pointer"}}
                            >
                                Remove Video Resume
                            </button>
                        </div>
                        <div className=" mt-4" style={{fontSize: "0.8222rem"}}>
                            <p className="my-0 mr-2 text-center text-primary">
                                Your Video Resume will be visible to the employer. You can always redo this video later!
                            </p>
                            <p className="my-0 mr-2 text-center">
                                Clicking 'Remove Video Resume’ will delete your current video resume
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {isMobile() && (
                        <Modal
                            open={modal}
                            hideBottomBorder
                            closeText="close"
                            headerClass="pb-0"
                            bodyClass="pt-0"
                            onCancel={handleModalCancel}
                        >
                            <Ad/>
                        </Modal>
                    )}
                    <div className=" d-flex justify-content-center align-items-center">
                        <DigitalHireIcon width={200}/>
                    </div>
                    <div className=" d-flex justify-content-center align-items-center">
                        <h5
                            class="mx-2 mt-3 mb-4 dark-color text-center font-weight-normal"
                            style={{maxWidth: "380px", fontSize: "1.25rem"}}
                        >
                            Stand out and enhance your application by recording a 90 second Video Resume
                        </h5>
                    </div>
                    <div className="row question-image pb-3">
                        <div className="col-md-12 col-sm-12 pr-0">
                            <p className="font-weight-500 black pb-1">
                                <u>You will get 30 seconds for each of the following sections:</u>
                            </p>
                            {sectionDesc.map((desc, index) => (
                                <div key={index} className="d-flex align-items-center pb-1 mb-3">
                                    <TickIcon className="text-primary icon-mw" height="25" width="25"/>
                                    <p className="ml-2 font-weight-500 dark-color mb-0">{desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="dropdown-divider"/>
                    <div className="d-md-flex justify-content-between my-3">
                        <div className="d-flex mb-md-0 mb-3 align-items-center">
                            <WebCamIcon className="text-primary" height="25" width="25"/>
                            <p className="ml-3 mt-1 mb-1 font-weight-normal dark-color">Required Equipment: Webcam,
                                Microphone</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <StopwatchIcon className="text-primary" height="25" width="25"/>
                            <p className="ml-3 mt-1 mb-1 font-weight-normal dark-color">Total Time: 90 Seconds</p>
                        </div>
                    </div>
                    <div className="dropdown-divider mb-3"/>
                    {isIOS ? (
                        <p className="text-center text-danger">IOS does not support WebRTC Webcam Recording!</p>
                    ) : (
                        <div className="d-md-flex justify-content-md-center text-center pt-4">
                            <Button
                                variation="primary btn-sm ml-2 btn-width mb-3 mb-md-0"
                                name="get_started"
                                value="Get Started"
                                onSubmit={() => {
                                    props.nextStep()
                                }}
                            />

                            <Button
                                variation="btn btn-outline-dark btn-sm ml-2 btn-width"
                                name="learn_more"
                                value="Learn More"
                                onSubmit={() => {
                                }}
                            />
                        </div>
                    )}
                </>
            )}
            <WarningModal
                open={!!openModal}
                title={`Are you sure you want to delete your ${openModal === "video_resume" ? "video " : ""}?`}
                onSubmit={handleDeleteResume}
                onCancel={useCallback(() => setOpenModal(""), [])}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        videoUrl: getVideoUrlSelector(state),
        timeStamp: getTimeStampSelector(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getProfileVideoResumeAction: (cb) => dispatch(getProfileVideoResume(cb)),
        removeProfileVideoResumeAction: (callback) => dispatch(removeProfileVideoResume(callback)),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro))
