import React, {useCallback, useEffect, useRef, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import RecordRTC from "recordrtc";
import {ReactComponent as DigitalHireIcon} from "../../../images/digital-hire.svg";
import {ReactComponent as StopwatchIcon} from "../../../images/svg/stopwatch.svg";
import {ReactComponent as VideoGuide} from "../../../images/svg/video-guide.svg";
import {ReactComponent as VideoIcon} from "../../../assets/images/video-camera.svg";
import {interviewAnswer, interviewVideoResume, postMediaToAWS} from "../../../redux/actions/ProfileActions";
import WarningModal from "../../common/WarningModal";
import {toast, ToastContainer} from "react-toastify";
import {fancyTimeFormat} from "../../../helpers/dateTime";
import Loader from "../../common/Loader";
import Alert from "../../common/Alert";
import "../../../css/style.css"


function RecVid(props) {
    const video = useRef(null)
    const [blob, setBlob] = useState(null)
    const start = useRef(null)
    const stop = useRef(null)
    const save = useRef(null)
    const duration = useRef(null)
    const reset = useRef(null)
    const progressFirst = useRef(null);
    const progressSec = useRef(null);
    const progressThird = useRef(null);
    // console.log(progressFirst,progressSec, progressThird )
    const toastId = React.useRef(null)
    const [openModal, setOpenModal] = useState("")
    const [defaultCamera, setDefaultCamera] = useState(true)
    const [isVideoQuestion, setIsVideoQuestion] = useState(true)
    const [alert, setAlert] = useState({
        alert: false,
        type: 'success', // error
        variant: 'success', // danger
        msg: ''
    });

    let recorder, durationCounter
    let timeleft = props.type === "video" && !props?.interview_resume ? props.timeAllowed * 60 : 90
    let progress = 0
    let attempts = JSON.parse(localStorage.getItem("attemptsLeft"))?.number || props.attempts
    let recordAnswer = props.type === "video" ? false : true
    let playerWidth = props.type === "video" ? "500px" : "650px"
    let playerHeight = props.type === "video" ? "400px" : "490px"
    let guideHeight = props.type === "video" ? "300px" : "380px"
    let guideMarginTop = props.type === "video" ? props?.interview_resume ? "0px" : "-120px" : "145px"

    useEffect(() => {
        stop.current.style.display = "none"
        save.current.style.display = "none"
        if (reset.current) reset.current.style.display = "none"
        video.current.style.backgroundColor = "black"
        if (defaultCamera) {
            captureCamera((camera) => {
                console.log("Turning on camera")
                video.current.muted = false
                video.current.volume = 0
                video.current.srcObject = camera
                // recorder = RecordRTC(camera, {
                //     type: "video",
                // })
                // recorder.camera = camera
                setDefaultCamera(false)
            })
        }

    }, [])
    //
    // useEffect(() => {
    //     captureCamera((camera) => {
    //         console.log("Turning on camera")
    //         video.current.muted = false
    //         video.current.volume = 0
    //         video.current.srcObject = camera
    //         recorder = RecordRTC(camera, {
    //             type: "video",
    //         })
    //         recorder.camera = camera
    //         setDefaultCamera(false)
    //     })
    // }, [defaultCamera])

    const handleRecording = async () => {
        // toast.success("Started Recording !", { autoClose: 2000 })
        captureCamera((camera) => {
            if (recorder) {
                video.current.srcObject = null
                video.current.muted = false
                video.current.volume = 1
                recorder.camera.stop()
                recorder.destroy()
                recorder = null
            }
            video.current.muted = false
            video.current.volume = 0
            video.current.srcObject = camera
            recorder = RecordRTC(camera, {
                type: "video",
            })
            recorder.setRecordingDuration(props.timeAllowed * 60 * 1000 || 120000).onRecordingStopped(stopRecordingCallback)
            recorder.startRecording()
            recorder.camera = camera
        })
        setTimeout(
            () => {
                stop?.current?.removeAttribute("disabled")
            },
            recordAnswer || props?.interview_resume ? 7000 : 0
        )
        durationCounter = setInterval(() => {
            if (timeleft <= 0) {
                clearInterval(durationCounter)
                if (duration.current) duration.current.innerText = "Time Over"
            } else {
                if (duration.current) duration.current.innerText = fancyTimeFormat(timeleft)
                // if(props?.vr?.recordNew){
                if (progress <= 30 && progressFirst.current) {
                    progressFirst.current.style.width = `${progress * 3.333333333333333}%`;
                } else if (progress <= 60 && progressSec.current) {
                    progressSec.current.style.width = `${(progress - 30) * 3.333333333333333}%`;
                } else if (progress <= 90 && progressThird.current) {
                    progressThird.current.style.width = `${(progress - 60) * 3.44}%`;
                }
                // }
            }
            timeleft -= 1
            progress += 1
        }, 1000)

        stop.current.style.display = "block"
        stop.current.setAttribute("disabled", "")
        start.current.style.display = "none"
    }
    const captureCamera = (callback) => {
        navigator.mediaDevices
            .getUserMedia({
                // audio: {
                //     echoCancellation: false,
                //     noiseSuppression: false,
                //     autoGainControl: false,
                //     googEchoCancellation: false,
                //     googAutoGainControl: false,
                //     googExperimentalAutoGainControl: false,
                //     googNoiseSuppression: false,
                //     googExperimentalNoiseSuppression: false,
                //     googHighpassFilter: false,
                //     googTypingNoiseDetection: false,
                //     googBeamforming: false,
                //     googArrayGeometry: false,
                //     googAudioMirroring: false,
                //     googNoiseReduction: false,
                //     mozNoiseSuppression: false,
                //     mozAutoGainControl: false,
                // },
                video: true,
                audio: true,
            })
            .then(function (camera) {
                // camera.getTracks().forEach(x => x.stop());
                callback(camera)
            })
            .catch(function (error) {
                console.log("Unable to capture your camera. Please check console logs.")
                console.error(error)
            })
    }
    const turnOffCamera = () => {
        video.current.srcObject = null
        video.current.muted = false
        video.current.volume = 1
        // video.current = null
        recorder.camera.stop()
        recorder.destroy()
        recorder = null
    }

    const stopRecordingCallback = () => {
        video.current.srcObject = null
        video.current.muted = false
        video.current.volume = 1
        video.current.src = URL.createObjectURL(recorder.getBlob())
        console.log(recorder.getBlob().size, "blob size")
        setBlob(recorder.getBlob())
        recorder.camera.stop()
        recorder.destroy()
        recorder = null
    }

    const handleStop = () => {
        video.current.setAttribute("controls", "true")
        clearInterval(durationCounter)
        // toast.success("Recording Stopped", {autoClose: 2000})
        recorder?.stopRecording(() => stopRecordingCallback())
        save.current.style.display = "block"
        stop.current.style.display = "none"
        if (reset.current) reset.current.style.display = "block"
    }
    const handleStopAfterSave = () => {
        video.current.setAttribute("controls", "true")
        clearInterval(durationCounter)
        // toast.success("Recording Stopped", {autoClose: 2000})
        recorder?.stopRecording(() => stopRecordingCallback())
    }

    let initialDuration = fancyTimeFormat(timeleft)
    const [interviewVN, setInterviewVN] = useState(false)
    const handleSave = () => {
        const media_key = "user" + video.current.src.slice(video.current.src.lastIndexOf("/")) + "/" + uuidv4() + ".webm"
        let fileObject = new File([blob], media_key, {
            type: "video/webm",
        })

        props.postMediaToAWSAction({key: media_key, file: fileObject, VR: recordAnswer}, (res) => {
            if (res.config.url) {
                toast.dismiss(toastId.current)
                if (!recordAnswer && !props?.interview_resume) {
                    video.current.src = ""
                    let response_time = duration.current.innerText.split(':');
                    response_time = (+response_time[0]) * 60 + (+response_time[1]);

                    props.interviewAnswerAction(
                        {
                            question_id: props.question_id,
                            interview_id: props.interview_id,
                            answer_data: {
                                content: media_key,
                                response_time: isNaN(response_time) ? props.timeAllowed * 60 : props.timeAllowed * 60 - response_time,
                            },
                        },
                        (res) => {
                            if (res.success) {
                                // handleStop()
                                handleStopAfterSave()
                                props.setQuestionCounter((prev) => prev + 1)
                                // setDefaultCamera(true)
                            }
                        }
                    )
                } else if (props?.interview_resume) {
                    props.interviewVideoResumeAction(
                        {
                            interview_id: props.interview_id,
                            video_resume: media_key,
                            existing: false,
                        },
                        (res) => {
                            if (res) {
                                // turnOffCamera()
                                // handleStop()
                                handleStopAfterSave()
                                props.setInterviewVn({...props.vr, currentResume: true})

                                // props.setVr({...props.vr, currentResume: true})
                            }
                        },
                        (val) => setAlert(val)
                    )
                }
            }
        }, (val) => setAlert(val));
        toastId.current = toast.success("Uploading ...", {autoClose: false})
        stop.current.style.display = "none"
        save.current.style.display = "none"
        if (reset.current) reset.current.style.display = "none"
        start.current.style.display = "block"
        localStorage.removeItem("attemptsLeft");
    }

    const handleReset = () => {
        if (recordAnswer) {
            // window.location.reload()
            video.current.removeAttribute("controls")
            video.current.srcObject = null
            video.current.src = null
            setOpenModal("")
            save.current.style.display = "none"
            stop.current.style.display = "none"
            reset.current.style.display = "none"
            start.current.style.display = "block"
            duration.current.innerText = fancyTimeFormat(timeleft)
            if (progressFirst.current) {
                progressFirst.current.style.width = `0%`;
            }
            if (progressSec.current) {
                progressSec.current.style.width = `0%`;
            }
            if (progressThird.current) {
                progressThird.current.style.width = `0%`;
            }
        } else {
            localStorage.setItem("attemptsLeft", JSON.stringify({number: Number(attempts - 1), qId: props.question_id}))
            if (
                JSON.parse(localStorage.getItem("attemptsLeft"))?.number === 0 &&
                JSON.parse(localStorage.getItem("attemptsLeft"))?.qId === props.question_id
            ) {
                handleSave()
            } else window.location.reload()
        }
    }
    console.log('recordAnswer: ', recordAnswer);
    return (
        <div>
            <ToastContainer/>
            <Alert
                alert={alert.alert}
                type={alert.type}
                variant={alert.variant}
                msg={alert.msg}
                dismiss={() => setAlert({
                    alert: false,
                    type: 'success', // error
                    variant: 'success', // danger
                    msg: ''
                })}
            />
            <div className="d-flex flex-column justify-content-center align-items-center">
                {/* <div className="col-md-12 col-sm-12 pr-0"> */}
                {(recordAnswer || props?.interview_resume) ? (
                    <>
                        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                            <>
                                {(recordAnswer || props?.interview_resume) ? (
                                    <>
                                        {recordAnswer &&
                                            <div className="justify-content-center align-items-center"
                                                 style={{width: "650px"}}>
                                                <div className=" d-flex justify-content-center align-items-center">
                                                    <DigitalHireIcon width={200}/>
                                                </div>
                                                <h5 className="text-center dark-color font-weight-normal mt-3">
                                                    Enhance your profile by recording a
                                                </h5>
                                                <h5 className="text-center dark-color font-weight-normal">Video
                                                    Resume</h5>
                                                <h6 className="text-center"
                                                    style={{fontSize: ".82222rem", color: "#646464"}}>
                                                    You have unlimited attempts
                                                </h6>
                                                <div className="dropdown-divider w-100 mb-4"></div>
                                                <p className="mb-2 dark-color font-weight-normal">Take 90 seconds
                                                    to:</p>
                                                <p className="body-title dark-color font-weight-500">
                                                    Introduce yourself &amp; briefly share your professional journey
                                                </p>
                                            </div>
                                        }
                                        <div className="row" style={{width: recordAnswer ? "650px" : '500px'}}>
                                            <div className="d-flex" style={{gap: "70px"}}>
                                                <div>
                                                    <div
                                                        className="progress mh-100 w-100"
                                                        style={{
                                                            borderRadius: "0px",
                                                            backgroundColor: "rgba(0,185,82,25%)"
                                                        }}
                                                    >
                                                        <div
                                                            className="progress-bar"
                                                            ref={progressFirst}
                                                            style={{
                                                                width: `0%`,
                                                            }}
                                                        />
                                                    </div>
                                                    <p
                                                        className="col text-center mt-2 font-weight-500"
                                                        style={{fontSize: ".82222rem"}}
                                                    >
                                                        Introduce yourself
                                                    </p>
                                                </div>
                                                <div>
                                                    <div
                                                        className="progress mh-100 w-100"
                                                        style={{
                                                            backgroundColor: "rgba(0,185,82,25%)",
                                                            borderRadius: "0px"
                                                        }}
                                                    >
                                                        <div
                                                            className="progress-bar"
                                                            ref={progressSec}
                                                            style={{
                                                                width: `0%`,
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="col text-center mt-2 font-weight-500"
                                                       style={{fontSize: ".82222rem"}}>
                                                        Recent work experience
                                                    </p>
                                                </div>
                                                <div>
                                                    <div
                                                        className="progress mh-100 w-100"
                                                        style={{
                                                            borderRadius: "0px",
                                                            backgroundColor: "rgba(0,185,82,25%)"
                                                        }}
                                                    >
                                                        <div
                                                            className="progress-bar"
                                                            ref={progressThird}
                                                            style={{
                                                                width: `0%`,
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="col text-center mt-2 font-weight-500"
                                                       style={{fontSize: ".82222rem"}}>
                                                        What sets you apart?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <h6 className="text-center">You have {attempts} attempts</h6>
                                )}
                            </>
                        </div>
                    </>
                ) : (
                    ""
                )}
                <video
                    width={playerWidth}
                    height={playerHeight}
                    ref={video}
                    controls={false}
                    disablepictureinpicture
                    autoPlay={true}
                    controlslist="nodownload"
                    playsInline={true}
                    className={"flipped-camera"}
                    style={{position: "relative"}}
                ></video>
                {save.current && save.current.style.display !== "block" &&
                    (<VideoGuide
                        style={{
                            position: "absolute",
                            width: "500px",
                            minWidth: "500px",
                            height: `${guideHeight}`,
                            minHeight: `${guideHeight}`,
                            marginTop: `${guideMarginTop}`,
                        }}
                    />)
                }
                <div className="d-flex justify-content-between align-items-center mt-4"
                     style={{width: `${playerWidth}`}}>
                    <div className="d-flex align-items-center">
                        <StopwatchIcon className="text-primary" width="25" height="25"/>
                        <p className="mx-2 mb-0 font-weight-normal">
                            {recordAnswer || props?.interview_resume ? (
                                <small>You have {1.5 * 60} seconds</small>
                            ) : (
                                <small>You have {props.timeAllowed * 60} seconds</small>
                            )}
                        </p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="mb-0 mr-2">
                            <small className="font-weight-500">Duration</small>
                        </p>
                        <p className="mb-0 font-weight-normal">
                            <small ref={duration}>{initialDuration}</small>
                        </p>
                    </div>
                </div>
                {/* <div className="dropdown-divider w-100 mb-4" /> */}
                {recordAnswer || props?.interview_resume ? (
                    ""
                ) : (
                    <p className="large dark-color">Attempts Remaining: {attempts}</p>
                )}
                {props?.isLoading && (
                    <Loader spinnerClass="text-primary my-3 mt-3" loaderClass="d-flex justify-content-center"/>
                )}
                <div className="d-flex mt-3 justify-content-center pb-10">
                    <button
                        type="button"
                        class="btn btn-primary font-weight-400 px-3 btn-sm mx-2"
                        ref={start}
                        onClick={handleRecording}
                        disabled={props?.isLoading}
                    >
                        <VideoIcon/> Start Recording
                    </button>
                    <button
                        type="button"
                        class="btn btn-danger font-weight-400 px-3 btn-sm mx-2"
                        ref={stop}
                        disabled={props?.isLoading}
                        onClick={handleStop}
                    >
                        <VideoIcon/> Stop Recording
                    </button>
                    <button
                        className="btn btn-primary btn-sm btn-width mb-3 mb-md-0"
                        style={{width: "160px"}}
                        ref={save}
                        onClick={handleSave}
                        disabled={props?.isLoading}
                    >
                        {recordAnswer || props?.interview_resume ? "Finish" : "Submit Answer"}
                    </button>
                    {attempts === 1 ? "" :
                        <button
                            className="btn btn-outline-dark btn-sm btn-width mb-3 mb-md-0 ml-3"
                            style={{width: "160px"}}
                            ref={reset}
                            disabled={props?.isLoading}
                            onClick={() => setOpenModal("video_resume")}
                        >
                            Reset
                        </button>
                    }
                </div>
                {recordAnswer ? (
                    <>
                        <div className=" mt-4" style={{fontSize: "0.8222rem"}}>
                            <p className="my-0 mr-2 text-center">
                                Your Video Resume will be visible to the employer. You can always redo this video later!
                            </p>
                            <p className="my-0 mr-2 text-center">
                                Clicking 'Remove Video Resume’ will delete your current video resume
                            </p>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
            <WarningModal
                open={!!openModal}
                title={`Are you sure you want to remove your video?`}
                onSubmit={handleReset}
                onCancel={useCallback(() => setOpenModal(""), [])}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.ProfileReducers.mediaUploadLoading ? true : false,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        postMediaToAWSAction: (data, callback, cb) => dispatch(postMediaToAWS(data, callback, cb)),
        interviewAnswerAction: (data, callback) => dispatch(interviewAnswer(data, callback)),
        interviewVideoResumeAction: (data, callback, alert) => dispatch(interviewVideoResume(data, callback, alert)),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecVid))
