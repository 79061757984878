import React,{useContext} from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ThemeContext } from "../context/ThemeContext";
import { useSelector } from 'react-redux';
import Nav from "./layouts/nav";

export default function PrivateRoute({ component: Component, ...rest }) {
    const { menuToggle } = useContext(ThemeContext);
    const authData = useSelector((state) => state.AuthReducers);
    const data= JSON.parse(localStorage.getItem('dataSave'))
    const verified = data?.verified ? data.verified  : false
    const loggedIn = data?.token ? true : false
    return (
        <div
            id={"main-wrapper"}
            className={`show ${menuToggle? 'menu-toggle':''}`}
        >
            <Nav />

            <div className={"content-body"}>
                <div
                    className={"container-fluid"}
                    style={{ minHeight: window.screen.height - 60 }}
                >
                <Route
                    {...rest}
                    render={props => {
                        return loggedIn ? (verified ? <Component {...props} /> : <Redirect to="/verify" />) : <Redirect to="/" />
                    }}>

                </Route>
                </div>
            </div>
        </div>
    )
}
