import {
    GET_ALL_INTERVIEWS_API_FAILURE,
    GET_ALL_INTERVIEWS_API_REQUEST,
    GET_ALL_INTERVIEWS_API_SUCCESS,
    GET_ALL_INTERVIEWS_STATS_API_FAILURE,
    GET_ALL_INTERVIEWS_STATS_API_REQUEST,
    GET_ALL_INTERVIEWS_STATS_API_SUCCESS,
    GET_PROFILE_DETAILS_API_FAILURE,
    GET_PROFILE_DETAILS_API_REQUEST,
    GET_PROFILE_DETAILS_API_SUCCESS,
    GET_PROFILE_PDFRESUME_API_FAILURE,
    GET_PROFILE_PDFRESUME_API_REQUEST,
    GET_PROFILE_PDFRESUME_API_SUCCESS,
    GET_PROFILE_VIDEORESUME_API_FAILURE,
    GET_PROFILE_VIDEORESUME_API_REQUEST,
    GET_PROFILE_VIDEORESUME_API_SUCCESS,
    LOCAL_PROFILE_DP_REQUEST,
    POST_ADD_INTERVIEW_API_FAILURE,
    POST_ADD_INTERVIEW_API_REQUEST,
    POST_ADD_INTERVIEW_API_SUCCESS,
    POST_AWS_MEDIA_API_FAILURE,
    POST_AWS_MEDIA_API_REQUEST,
    POST_AWS_MEDIA_API_SUCCESS,
    SET_DEACTIVATE_ACCOUNT_API_FAILURE,
    SET_DEACTIVATE_ACCOUNT_API_REQUEST,
    SET_DEACTIVATE_ACCOUNT_API_SUCCESS,
    SET_INTERVIEW_VIDEO_RESUME_API_SUCCESS,
    SET_PROFILE_PASSWORD_API_FAILURE,
    SET_PROFILE_PASSWORD_API_REQUEST,
    SET_PROFILE_PASSWORD_API_SUCCESS,
    SET_PROFILE_PDFRESUME_API_FAILURE,
    SET_PROFILE_PDFRESUME_API_REQUEST,
    SET_PROFILE_PDFRESUME_API_SUCCESS,
    SET_PROFILE_VIDEORESUME_API_FAILURE,
    SET_PROFILE_VIDEORESUME_API_REQUEST,
    SET_PROFILE_VIDEORESUME_API_SUCCESS,
    UPDATE_INTERVIEW_VIDEO_RESUME,
    UPDATE_PROFILE_DETAILS_API_FAILURE,
    UPDATE_PROFILE_DETAILS_API_REQUEST,
    UPDATE_PROFILE_DETAILS_API_SUCCESS,
} from "../constants/ProfileConstants"

const initalState = {
    deactivateAccount: {},
    deactivateLoading: {},
    deactivateFail: {},

    mediaUpload: {},
    mediaUploadFail: {},
    mediaUploadLoading: false,

    profileData: {},
    profileDataFail: {},
    profileDataLoading: {},

    addInterview: {},
    interviewFail: {},
    interviewLoading: false,

    allInterviews: {},
    allInterviewsFail: {},
    allInterviewsLoading: {},
    allInterviewsStatsFail: {},
    allInterviewsStatsLoading: {},

    videoUp: "",
    videoUrl: undefined,
    videoThumbnailUrl: undefined,
    pdfUp: "",
    pdfUrl: undefined,
    video_uploaded_at: "",
    isLoading: false,
    success: false,
    error: false,
    interview_resume: "",
    interviewPending: 0,
    interviewCompleted: 0,
    localDp: "",
}

const ProfileReducers = (state = initalState, action) => {
    switch (action.type) {
        // case NEW_PROFILE_IMAGE_UPLOAD:
        //     console.log("New Image: ", action)
        //     return {...state, profileData: {...state.profileData, image: action.payload}}
        case SET_PROFILE_PASSWORD_API_SUCCESS:
            return {...state, profileLoading: false}
        case SET_PROFILE_PASSWORD_API_REQUEST:
            return {...state, profileLoading: true}
        case SET_PROFILE_PASSWORD_API_FAILURE:
            return {...state, profileLoading: false}
        case SET_DEACTIVATE_ACCOUNT_API_SUCCESS:
            return {...state, deactivateAccount: action.payload, success: true, deactivateLoading: false}
        case SET_DEACTIVATE_ACCOUNT_API_REQUEST:
            return {...state, deactivateLoading: true}
        case SET_DEACTIVATE_ACCOUNT_API_FAILURE:
            return {...state, deactivateFail: true, error: true, deactivateLoading: false}
        case POST_AWS_MEDIA_API_REQUEST:
            return {...state, mediaUploadLoading: true}
        case POST_AWS_MEDIA_API_SUCCESS:
            return {...state, mediaUploadLoading: false, mediaUpload: action.payload}
        case POST_AWS_MEDIA_API_FAILURE:
            return {...state, mediaUploadLoading: false, mediaUploadFail: action.payload}
        case GET_PROFILE_DETAILS_API_REQUEST:
            return {...state, profileLoading: true}
        case GET_PROFILE_DETAILS_API_SUCCESS:
            return {...state, profileData: action.payload, profileLoading: false, profileFail: false}
        case GET_PROFILE_DETAILS_API_FAILURE:
            return {...state, profileFail: true, profileLoading: false}
        case POST_ADD_INTERVIEW_API_REQUEST:
            return {...state, interviewLoading: true}
        case POST_ADD_INTERVIEW_API_SUCCESS:
            return {...state, addInterview: action.payload, interviewLoading: false, interviewFail: false}
        case POST_ADD_INTERVIEW_API_FAILURE:
            return {...state, interviewFail: true, interviewLoading: false}
        case GET_ALL_INTERVIEWS_API_REQUEST:
            return {...state, allInterviewsLoading: true}
        case GET_ALL_INTERVIEWS_STATS_API_REQUEST:
            return {...state, allInterviewsStatsLoading: true}
        case UPDATE_INTERVIEW_VIDEO_RESUME:
            sessionStorage.setItem("vr_uploaded", action.payload)
            // let interviews = state.allInterviews;
            // const objIndex = interviews.data.findIndex((obj => obj.id === action.payload));
            // console.log("All interviews: ", interviews);
            // console.log("Interviews number: ", objIndex);
            // interviews.data[objIndex].video_resume = state.interview_resume;
            // state.allInterviews.data = interviews
            return {...state}
        case GET_ALL_INTERVIEWS_API_SUCCESS:
            let c = state.allInterviews;
            if (c !== []) {
                c.totalItems = action.payload.Interviews.totalItems
                c.totalPages = action.payload.Interviews.totalPages
                c.currentPage = action.payload.Interviews.currentPage
                if (c.data !== undefined && c.data.length > 0) {
                    // console.log("Concat", c.data, action.payload.Interviews.data)
                    var ids = new Set(action.payload.Interviews.data.map(d => d.id));
                    c.data = [...action.payload.Interviews.data, ...c.data.filter(d => !ids.has(d.id))];
                    // c.data.push(...action.payload.Interviews.data)
                } else {
                    // console.log("No Concat", c.data, action.payload.Interviews.data)
                    c.data = action.payload.Interviews.data
                }
            } else {
                c = action.payload.Interviews
            }
            // if (c.data) {
            //
            //     c.data.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0))
            // }
            console.log("REducer: ", c)


            return {
                ...state,
                allInterviews: c,
                allInterviewsLoading: false,
                allInterviewsFail: false,
            }
        case GET_ALL_INTERVIEWS_API_FAILURE:
            return {...state, allInterviewsFail: true, allInterviewsLoading: false}
        case GET_ALL_INTERVIEWS_STATS_API_SUCCESS:
            return {
                ...state,
                interviewPending: action.payload.total_interview_pending,
                interviewCompleted: action.payload.total_interview_completed,
                allInterviewsStatsFail: false,
                allInterviewsStatsLoading: false

            }
        case GET_ALL_INTERVIEWS_STATS_API_FAILURE:
            return {...state, allInterviewsStatsFail: true, allInterviewsStatsLoading: false}
        case UPDATE_PROFILE_DETAILS_API_REQUEST:
            return {...state, profileLoading: true}
        case UPDATE_PROFILE_DETAILS_API_SUCCESS:
            return {...state, profileLoading: false}
        case UPDATE_PROFILE_DETAILS_API_FAILURE:
            return {...state, profileLoading: false}
        case SET_PROFILE_VIDEORESUME_API_REQUEST:
            return {...state, isLoading: true}
        case SET_PROFILE_VIDEORESUME_API_SUCCESS:
            return {...state, isLoading: false, success: true, videoUp: action.payload}
        case SET_PROFILE_VIDEORESUME_API_FAILURE:
            return {...state, isLoading: false, error: action.payload}
        case GET_PROFILE_VIDEORESUME_API_REQUEST:
            return {...state, isLoading: true}
        case GET_PROFILE_VIDEORESUME_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                videoThumbnailUrl: action.payload.video_resume_thumbnail,
                videoUrl: action.payload.video_resume,
                video_uploaded_at: action.payload.video_uploaded_at,
            }
        case GET_PROFILE_VIDEORESUME_API_FAILURE:
            return {...state, isLoading: false, error: action.payload}
        case SET_PROFILE_PDFRESUME_API_REQUEST:
            return {...state, isLoading: true}
        case SET_PROFILE_PDFRESUME_API_SUCCESS:
            return {...state, isLoading: false, success: true, pdfUp: action.payload}
        case SET_PROFILE_PDFRESUME_API_FAILURE:
            return {...state, isLoading: false, error: action.payload}
        case GET_PROFILE_PDFRESUME_API_REQUEST:
            return {...state, isLoading: true}
        case GET_PROFILE_PDFRESUME_API_SUCCESS:
            return {...state, isLoading: false, success: true, pdfUrl: action.payload}
        case GET_PROFILE_PDFRESUME_API_FAILURE:
            return {...state, isLoading: false, error: action.payload}
        case LOCAL_PROFILE_DP_REQUEST:
            return {...state, localDp: action.payload}
        case SET_INTERVIEW_VIDEO_RESUME_API_SUCCESS:
            return {...state, interview_resume: action.payload}
        default:
            return state
    }
}

export default ProfileReducers
