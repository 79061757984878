import React, {useState} from "react"
import {Link, useHistory, withRouter} from "react-router-dom"
import {connect, useDispatch, useSelector} from "react-redux"
import {setAuthData} from "../../redux/actions/AuthActions"
import Loader from "../common/Loader"
// image
import "./login.css"

import LogoDark from "../../images/logo-dark.svg"
import GoogleButton from "../components/GoogleButton"
//import LinkedInButton from "../components/LinkedInButton"
import {ReactComponent as EmailIcon} from "../../assets/images/email-icon.svg"
import Button from "../common/Button/Button"
import "../components/LinkedInButton/style.scss"
import login from "../../images/login-left.svg"


const Login = (props) => {
    const [email, setEmail] = useState("")
    let errorsObj = {email: "", password: ""}
    const [errors, setErrors] = useState(errorsObj)
    const [password, setPassword] = useState("")
    const navigate = useHistory()
    const dispatch = useDispatch()
    const AuthDataLoading = useSelector((state) => state.AuthReducers.AuthDataLoading)
    const [error, setError] = useState("")

    const onLogin = (e) => {
        e.preventDefault()
        let error = false
        const errorObj = {...errorsObj}
        if (email === "") {
            errorObj.email = "Email is Required"
            error = true
        }
        if (password === "") {
            errorObj.password = "Password is Required"
            error = true
        }
        setErrors(errorObj)
        if (error) {
            return
        }
        dispatch(
            setAuthData(
                {
                    email,
                    password,
                },
                (res) => {
                    if (res?.token && res?.verified == false) {
                        navigate.push("/verify")
                    }
                    if (res?.token && res?.verified == true) {
                        navigate.push("/dashboard")
                    }
                    if (res?.error) {
                        setError(res.error)
                    }
                },
                navigate
            )
        )
    }

    return (
        <div className="login-main-page">
            <div className="login-wrapper">
                <div className="login-aside-left sidenav bg-img">
                    <Link to="/dashboard" className="login-logo">
                        <img src={LogoDark} alt="" className=""/>
                    </Link>
                    <div className="login-description">
                        <h2 className="text-black  mb-3">Welcome</h2>
                        <p className="fs-12 text-dark">
                            Resumes are great for listing experiences but not telling your story. With DigitalHire, you
                            can show the
                            real you, and put your best foot forward.
                        </p>
                        <ul className="social-icons mt-3">
                            <li>
                                <a href="https://www.facebook.com/DigitalHireInc/" target="_blank">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/digitalhireinc" target="_blank">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/digital-hire-inc/" target="_blank">
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/digitalhireinc/" target="_blank">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                        <div className="mt-3">
                            <a href="https://digitalhire.com/privacy-policy/" className="text-black mr-4"
                                target="_blank">
                                Privacy Policy
                            </a>
                            <a href="https://digitalhire.com/terms-of-service/" className="text-black mr-4"
                                target="_blank">
                                Terms & Conditions
                            </a>
                            <Link to={"#"} className="text-black">
                                © {new Date().getFullYear()} DigitalHire
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                        <div className="col-xl-7 col-xxl-7">
                            <div className="authincation-content">
                                <div className="row no-gutters">
                                    <div className="col-xl-12">
                                        <div className="auth-form-1">
                                            <div className="mb-4">
                                                <h3 className="text-white mb-1">Jobseeker's Dashboard</h3>
                                                <p className="">Sign in by entering information below</p>
                                            </div>
                                            {error && <div
                                                className="animated fadeInUp text-danger fs-15 text-center"> {error}</div>}
                                            <form onSubmit={onLogin}>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ">
                                                        <strong>Email</strong>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={email}
                                                        placeholder="Enter Email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    {errors.email &&
                                                        <div className="text-danger fs-12">{errors.email}</div>}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="mb-2 ">
                                                        <strong>Password</strong>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        value={password}
                                                        placeholder="Enter Password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    {errors.password &&
                                                        <div className="text-danger fs-12">{errors.password}</div>}
                                                </div>
                                                <p
                                                    onClick={() => {
                                                        props.history.push("/forgot-password")
                                                    }}
                                                    style={{
                                                        color: "#b62b2b",
                                                        marginTop: "-12px",
                                                        marginBottom: "-11px",
                                                        cursor: "pointer"
                                                    }}>forgot password?</p>
                                                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                    <div className="form-group">
                                                        <div className="custom-control custom-checkbox ml-1 ">
                                                            <input type="checkbox" className="custom-control-input mr-2"
                                                                   id="basic_checkbox_1"/>
                                                            <label className="custom-control-label"
                                                                   htmlFor="basic_checkbox_1">
                                                                Remember my preference
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button disabled={AuthDataLoading} type="submit"
                                                            className="btn btn-primary btn-block">
                                                        {AuthDataLoading ?
                                                            <Loader spinnerClass="text-white"/> : "Sign In"}
                                                    </button>
                                                </div>
                                            </form>
                                            <div className="mt-4">
                                                <GoogleButton/>
                                                {/* <LinkedInButton /> */}
                                                <div className="d-flex align-items-center linked-in-icon my-2">
                                                    <EmailIcon width="20" height="20" className="mx-2"/>
                                                    <Button
                                                        value="Email Sign Up"
                                                        variation="primary btn-sm btn-block btn-linked-in"
                                                        style={{backgroundColor: "#3c79e6", color: "white"}}
                                                        onSubmit={() => navigate.push("/register")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="new-account mt-2">
                                                <p className="">
                                                    Don't have an account?{" "}
                                                    <Link className="text-primary"
                                                          to={{pathname: "./register", account: "new"}}>
                                                        Sign up
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(null, null)(Login));
