//Set AUTH DATA Constants
export const SET_AUTH_API_REQUEST = 'SET_AUTH_API_REQUEST';
export const SET_AUTH_API_SUCCESS = 'SET_AUTH_API_SUCCESS';
export const SET_AUTH_API_FAILURE = 'SET_AUTH_API_FAILURE';

export const SET_SIGNUP_API_REQUEST = 'SET_SIGNUP_API_REQUEST';
export const SET_SIGNUP_API_SUCCESS = 'SET_SIGNUP_API_SUCCESS';
export const SET_SIGNUP_API_FAILURE = 'SET_SIGNUP_API_FAILURE';

export const SET_SIGNUP_VERIFY_API_REQUEST = 'SET_SIGNUP_VERIFY_API_REQUEST';
export const SET_SIGNUP_VERIFY_API_SUCCESS = 'SET_SIGNUP_VERIFY_API_SUCCESS';
export const SET_SIGNUP_VERIFY_API_FAILURE = 'SET_SIGNUP_VERIFY_API_FAILURE';


export const USER_FORGOT_PASSWORD_COMPLETED = "USER_FORGOT_PASSWORD_COMPLETED"
export const USER_FORGOT_PASSWORD_FAILED = "USER_FORGOT_PASSWORD_FAILED"
export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST"


export const USER_RESEND_PIN_COMPLETED = "USER_RESEND_PIN_COMPLETED"
export const USER_RESEND_PIN_FAILED = "USER_RESEND_PIN_FAILED"
export const USER_RESEND_PIN_REQUEST = "USER_RESEND_PIN_REQUEST"



