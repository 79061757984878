// 3rd party
import React from "react"
import PropTypes from "prop-types"

// lib
import Modal from "../Modal"
import Button from "../Button/Button"
import { ReactComponent as WarningIcon } from "../../../assets/images/warning.svg"

const WarningModal = ({ open, title, cancelBtnText, onCancel, onSubmit }) => {
  return (
    <Modal open={open} headerClass="d-none">
      <div className="w-100 mt-4 ">
        <div className="mx-3 text-center">
          <WarningIcon />
          <h6 className="d-block pt-4 black font-weight-600">WARNING</h6>
          <div className="d-flex justify-content-center">
            <p className="w-75 d-block black body-title mb-0 font-weight-500">{title}</p>
          </div>
        </div>
        <div className="d-flex justify-content-center py-3">
          <Button variation="outline-dark mr-2 px-4" name="cancel" value="Cancel" onSubmit={onCancel} />
          {onSubmit && <Button variation="danger ml-2 px-4" name="delete" value={cancelBtnText} onSubmit={onSubmit} />}
        </div>
      </div>
    </Modal>
  )
}

WarningModal.propTypes = {
  cancelBtnText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

WarningModal.defaultProps = {
  cancelBtnText: "Delete",
}

export default WarningModal
