import React, {useEffect, useRef, useState, useCallback, Fragment} from "react"
import {useHistory} from "react-router-dom"
import VideoResumeCard from "../VideoResumeCard/VideoResumeCard"
import {ToastContainer} from "react-toastify"
import {getAllInterviews} from "../../../redux/actions/ProfileActions"
import {useLocation, withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import {getUserDataSelector} from "../../../redux/selectors/ProfileSelector"
import {ReactComponent as PersonIcon} from "../../../assets/images/person.svg"
import {ReactComponent as GrobalIcon} from "../../../assets/images/global.svg"
import {ReactComponent as FacebookIcon} from "../../../assets/images/facebook.svg"
import {ReactComponent as YoutubeIcon} from "../../../assets/images/youtube.svg"
import {ReactComponent as LinkedInIcon} from "../../../assets/images/linkedin.svg"
import {ReactComponent as TwitterIcon} from "../../../assets/images/twitter.svg"
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta.svg"
import {ReactComponent as StopwatchIcon} from "../../../assets/images/stopwatch.svg"
import {ReactComponent as QuestionIcon} from "../../../assets/images/question.svg"
import {ReactComponent as WebCamIcon} from "../../../assets/images/webcam.svg"
import {ReactComponent as FrameIcon} from "../../../assets/images/frame.svg"
import Ad from "../../common/Ad/Ad"
import Button from "../../common/Button/Button"
import {isMobile} from "../../../helpers"
import Modal from "../../common/Modal/Modal"
import {Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle} from "reactstrap";
import LogoDark from "../../../images/logo-dark.svg"
import LogoLight from "../../../images/logo-light.svg"

const InterviewConclusion = (props) => {
    const {userData} = props
    const socialRef = useRef(null)
    const [modal, setModal] = useState(true)
    const history = useHistory();
    const location = useLocation();
    const data = location.state;
    let estTime
    estTime = data?.Position?.Question.reduce((acc, {time_allowed}) => acc + time_allowed, 0)
    console.log({data});
    const handleNavigate = () => {
        history.push("/dashboard")
    }

    // useEffect(() => {
    //     const data = {
    //
    //         "pagination": {
    //             "page": 1,
    //             "size": 10
    //         }
    //
    //     }
    //     props.getAllInterviewsAction(data)
    //     window.scrollTo({top: 0, behavior: "smooth"})
    // }, [])

    const redirectToDashboard = () => {
        history.push("/dashboard")
    }

    return (
        <Fragment>
            <div className={"row justify-content-center"}>
                <Card className={"col-md-8"}>
                    {/*<Card.Img variant="top" src={LogoLight}/>*/}
                    <CardBody><img src={data?.Company?.logo} style={{
                        maxWidth: "100px", height: "auto",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}/>
                        <CardTitle tag="h5">

                        </CardTitle>
                        {/*<CardSubtitle*/}
                        {/*    className="mb-2 text-muted"*/}
                        {/*    tag="h6"*/}
                        {/*>*/}
                        {/*    Card subtitle*/}
                        {/*</CardSubtitle>*/}
                        <CardText className={"text-center text-justify"}>
                            <span>{data?.Company?.name} has recieved your submission for {data?.Position?.title}.
Next Steps
You will be contacted by {data?.Company?.name} after they have reviewed
your submission. If you have any questions, contact the HR department at
                                {data?.Company?.name}.</span>
                            <div className={"row justify-content-around"} style={{margin: "15px"}}>
                                <button onClick={() => redirectToDashboard()} className={"btn btn-primary"}>Next
                                </button>
                            </div>

                        </CardText>
                        <CardFooter className={"row justify-content-around"}
                                    style={{marginBottom: "-37px"}}>
                            <p>Help Center</p>
                            <p>Powered by Digital Hire</p>
                            <p>Settings</p>
                        </CardFooter>
                    </CardBody>
                </Card>
            </div>
        </Fragment>

    )
}

const mapStateToProps = (state) => {
    return {
        userData: getUserDataSelector(state),
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllInterviewsAction: (data) => dispatch(getAllInterviews(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewConclusion))
