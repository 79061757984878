import React, { Fragment, useState } from "react"
import Loader from "../../../common/Loader"
import { setChangePassword } from "../../../../redux/actions/ProfileActions"
import { getProfileLoadingSelector } from "../../../../redux/selectors/ProfileSelector"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Alert from "../../../common/Alert";

const PasswordSection = (props) => {
  const { profileLoading, setChangePasswordAction } = props
  let errorsObj = { curPassword: "", newPassword: "", conPassword: "" }
  const [errors, setErrors] = useState(errorsObj)
  const [curPassword, setCurPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [conPassword, setConPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [alert, setAlert] = useState({
    alert: false,
    type: '', // 'success', // error
    variant:'', // 'success', // danger
    msg:''
  });

  function onSubmit(e) {
    e.preventDefault()
    let error = false
    const errorObj = { ...errorsObj }
    if (curPassword === "") {
      errorObj.curPassword = "Current Password is Required"
      error = true
    }
    if (newPassword === "") {
      errorObj.newPassword = "New Password is Required"
      error = true
    }
    if (conPassword === "") {
      errorObj.conPassword = "Confirm Password is Required"
      error = true
    }
    if (conPassword !== newPassword) {
      errorObj.conPassword = "Password match failed"
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }

    const data = {
      old_password: curPassword,
      new_password: newPassword,
    }
    setChangePasswordAction(data, (val)=> setAlert(val))
    setCurPassword("")
    setNewPassword("")
    setConPassword("")
  }
  return (
    <Fragment>
      <Alert
        alert={alert.alert}
        type={alert.type}
        variant={alert.variant}
        msg={alert.msg}
        dismiss={()=> setAlert({
          alert: false,
          type:'success', // error
          variant:'success', // danger
          msg:''
        })}
      />
      <form onSubmit={onSubmit}>
        <div className="col-lg-8 mb-2">
          <div className={`form-group `}>
            <label className="text-label">
              Current Password{" "}
              <span className="text-muted">
                <small>Required</small>
              </span>
            </label>
            <div className="input-group transparent-appen">
              <input
                type={`${showPassword ? "text" : "password"}`}
                className="form-control"
                onChange={(e) => setCurPassword(e.target.value)}
                value={curPassword}
                placeholder="Enter Current Password"
              />

              <div className="input-group-append show-pass" onClick={() => setShowPassword(!showPassword)}>
                <span className="input-group-text">
                  {" "}
                  <i className="fa fa-eye-slash" />
                </span>
              </div>
            </div>
            {errors.curPassword && <div className="animated fadeInUp text-danger fs-12">{errors.curPassword}</div>}
          </div>
        </div>
        <div className="col-lg-8 mb-2">
          <div className={`form-group`}>
            <label className="text-label">
              New Password{" "}
              <span className="text-muted">
                <small>Required</small>
              </span>
            </label>
            <div className="input-group transparent-appen">
              <input
                type={`${showPassword ? "text" : "password"}`}
                className="form-control"
                id="val-password1"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                placeholder="Choose a safe one.."
              />

              <div className="input-group-append show-pass" onClick={() => setShowPassword(!showPassword)}>
                <span className="input-group-text">
                  {" "}
                  <i className="fa fa-eye-slash" />
                </span>
              </div>
            </div>
            {errors.newPassword && <div className="animated fadeInUp text-danger fs-12">{errors.newPassword}</div>}
          </div>
        </div>
        <div className="col-lg-8 mb-2">
          <div className={`form-group `}>
            <label className="text-label">
              Confirm New Password{" "}
              <span className="text-muted">
                <small>Required</small>
              </span>
            </label>
            <div className="input-group transparent-appen">
              <input
                type={`${showPassword ? "text" : "password"}`}
                className="form-control"
                onChange={(e) => setConPassword(e.target.value)}
                value={conPassword}
                placeholder="Enter Password Again"
              />

              <div className="input-group-append show-pass" onClick={() => setShowPassword(!showPassword)}>
                <span className="input-group-text">
                  {" "}
                  <i className="fa fa-eye-slash" />
                </span>
              </div>
            </div>
            {errors.conPassword && <div className="animated fadeInUp text-danger fs-12">{errors.conPassword}</div>}
          </div>
        </div>
        <div className="col-lg-8 mb-2">
          <button
            type="submit"
            className="btn mr-2 btn-primary"
            // disabled={isSubmitting}
          >
            {profileLoading ? <Loader spinnerClass="text-white" /> : "Update Password"}
          </button>
        </div>
      </form>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    profileLoading: getProfileLoadingSelector(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setChangePasswordAction: (data, cb) => dispatch(setChangePassword(data, cb)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordSection))
